<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Reserva consulta privada</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Reserva consulta privada</li>
            </ul>
        </div>
    </div>
</div>

<section class="book-private-session-area pt-100 pb-70">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="text-lines">
                Desde esta sección, podrás reservar una sesión privada con tu maestro espiritual favorito. El
                proceso te guiará por los pasos que debes seguir para programar tu consulta.
            </div>

            <div class="step-container" *ngIf="stepSelected == 0">
                <div class="step-title">
                    Paso 1. Elige el maestro
                </div>

                <section class="team-area team-area-two team-area-three pb-70">
                    <div class="row">
                        <ng-container *ngFor="let seer of seers">
                            <div class="col-sm-6 col-lg-3">
                                <div class="team-item" [ngClass]="{'team-selected': selectedSeer == seer}"
                                    (click)="selectSeerForPrivateSession(seer)">
                                    <div class="team-top">
                                        <div class="tarot-people-images"
                                            [ngStyle]="{'background-image': 'url(' + storageAccountImagesPath + seer.photoPath + ')'}"
                                            alt="{{seer.name}}">
                                        </div>
                                    </div>
                                    <div class="team-bottom">
                                        <h3>{{seer.name}}</h3>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </section>
            </div>

            <div class="step-container" *ngIf="stepSelected == 1">
                <div class="data-private-session">
                    <div class="data-private-session-title">
                        Datos de la reserva
                    </div>
                    <div class="data-session">Nombre de maestro elegido: {{privateSessionData.seerName}}</div>
                </div>

                <div class="step-title">
                    Paso 2. Elige la duración y si quieres servicio de traducción
                </div>

                <div class="form-group duration-container">
                    <label class="form-title">Duración</label>
                    <select class="form-control" [(ngModel)]='bookDurationAndCost'>
                        <option [ngValue]="undefined">Selecciona una duración</option>
                        <ng-container
                            *ngFor="let seerSchedulePrivateSessionDurationAndCost of seerSchedulePrivateSession.durationAndCost">
                            <option [ngValue]="seerSchedulePrivateSessionDurationAndCost">
                                {{seerSchedulePrivateSessionDurationAndCost.duration}} (minutos)</option>
                        </ng-container>
                    </select>

                    <div class="form-group mt20">
                        <ng-switchery [(ngModel)]="toggleAllowTraductionPrivateSession"
                            onLabel="{{labelForToggleAllowTraduction}}" class="toogle-allow-traduction"></ng-switchery>
                    </div>
                </div>
            </div>

            <div class="step-container" *ngIf="stepSelected == 2">
                <div class="data-private-session">
                    <div class="data-private-session-title">
                        Datos de la reserva
                    </div>
                    <div class="data-session">Nombre de maestro elegido: {{privateSessionData.seerName}}</div>
                    <div class="data-session">Duración: {{bookDurationAndCost.duration}} minutos</div>
                </div>

                <div class="step-title">
                    Paso 3. Elige el día
                </div>

                <ejs-calendar #calendarObj id=calendar [value]='dateValue' [min]='minDate' [max]='maxDate'
                    [firstDayOfWeek]='1' locale='es' (change)="getDayIndex($event)" > </ejs-calendar>

            </div>

            <div class="step-container" *ngIf="stepSelected == 3">
                <div class="data-private-session">
                    <div class="data-private-session-title">
                        Datos de la reserva
                    </div>
                    <div class="data-session">Nombre de maestro elegido: {{privateSessionData.seerName}}</div>
                    <div class="data-session">Duración: {{bookDurationAndCost.duration}} minutos</div>
                    <div class="data-session">Fecha: {{privateSessionData.bookDate}}</div>
                </div>

                <div class="step-title">
                    Paso 4. Elige la hora
                </div>

                <div class="hour-btns-container">
                    <div class="hour-btn" *ngFor="let hour of allTimesToBookSession; let indexOfelement = index;">
                        <a class="template-btn btn-black mt20" [ngClass]="{'hour-selected': selectedHour.indexOf(hour) != -1, 
                                        'hour-disabled': scheduleByDay.hours.indexOf(hour) == -1,
                                        'hour-bussy': privateSessionHoursBussy.indexOf(hour) != -1}"
                            (click)="selectHourForPrivateSession(hour, indexOfelement)"
                            style="width: 100px;">{{hour}}</a>
                        <ng-template *ngIf="indexOfelement % 6 == 0"><br></ng-template>
                    </div>
                </div>
            </div>

            <div class="step-container" *ngIf="stepSelected == 4">
                <div class="data-private-session">
                    <div class="data-private-session-title">
                        Datos de la reserva
                    </div>
                    <div class="data-session">Nombre de maestro elegido: {{privateSessionData.seerName}}</div>
                    <div class="data-session">Duración: {{bookDurationAndCost.duration}} minutos</div>
                    <div class="data-session">Fecha: {{privateSessionData.bookDate}}</div>
                    <div class="data-session">Hora: {{privateSessionData.bookHour}}</div>
                </div>

                <div class="step-title">
                    Paso 5. Introduce tu número de teléfono
                </div>

                <div class="phone-container">
                    <div class="form-group">
                        <label>Número de Teléfono</label>
                        <form #f="ngForm" [formGroup]="phoneForm">
                            <ngx-intl-tel-input [cssClass]="'prefixPhoneInput'"
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Spain" [maxLength]="15"
                                [phoneValidation]="true" name="userPhone" formControlName="userPhone">
                            </ngx-intl-tel-input>
                        </form>
                    </div>
                    <div class="conditions-text">
                        *El número de teléfono se solicita para contactar en caso de cambio de fecha/hora y para dar más
                        instrucciones sobre la sesión privada en el caso de que sea necesario.
                    </div>
                </div>
            </div>


            <div class="step-container" *ngIf="stepSelected == 5">
                <div class="step-title">
                    Paso 6. Pago de la sesión privada
                </div>
                <div class="pay-container">
                    <div class="text-lines">Reserva sala privada TOT Online</div>
                    <div class="text-lines">Importe Total: {{bookDurationAndCost.cost}}€</div>
                    <div class="text-lines">Elije una de las siguientes opciones de pago</div>
                    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                </div>
            </div>

            <div class="step-container" *ngIf="stepSelected == 6">
                <div class="step-title">
                    Paso 7. Resumen de tu cita
                </div>
                <div class="data-private-session">
                    <div class="data-private-session-title">
                        Datos de la reserva
                    </div>
                    <div class="data-session">Nombre de maestro elegido: {{privateSessionData.seerName}}</div>
                    <div class="data-session">Duración: {{bookDurationAndCost.duration}} minutos</div>
                    <div class="data-session">Fecha: {{privateSessionData.bookDate}}</div>
                    <div class="data-session">Hora: {{privateSessionData.bookHour}}</div>
                    <div class="data-session">Teléfono: {{privateSessionData.phone[0].internationalNumber}}</div>
                </div>
            </div>

            <div class="btns-container" [ngClass]="{'one-button': stepSelected == 0 || stepSelected == 6 }">
                <a *ngIf="stepSelected != 0 && stepSelected != 6" class="template-btn btn-black mt20"
                    style="margin-right: 10px !important" (click)="goToPreviousStep()">Anterior</a>
                <a *ngIf="stepSelected != 6 && stepSelected != 5" class="template-btn btn-black mt20"
                    style="margin-right: 0 !important" (click)="goToNextStep()">Siguiente</a>
                <a *ngIf="stepSelected == 6" class="template-btn btn-black mt20" style="margin-right: 0 !important"
                    routerLink="/">Volver a inicio</a>
            </div>
        </div>
    </div>
</section>