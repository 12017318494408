<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Salas y emisiones en directo</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Salas y emisiones en directo</li>
            </ul>
        </div>
    </div>
</div>

<ng-container *ngIf="haveRooms">
    <div class="sessions-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <ng-container *ngFor="let room of seerRooms">
                    <div class="col-sm-6 col-lg-4">
                        <div class="session-item session-video-btn-two">
                            <div class="session-title">
                                {{room.nameRoom}}
                            </div>
                            <div class="session-date" *ngIf="room.showDate">
                                Próxima emisión en directo: <br> {{room.dateRoom}} {{room.timeRoom}} <br> (Hora de
                                España)
                            </div>
                            <div class="session-date" *ngIf="!room.showDate">
                                Próxima emisión en directo: <br> Pendiente llegar al mínimo de
                                <br>{{room.minimumNumberMessagesToStart}} mensajes grabados
                            </div>
                            <div class="tarot-people-images"
                                [ngStyle]="{'background-image': 'url(' + room.seerPhotoURL + ')'}" alt="{{room.seerName}}">
                            </div>
                            <div class="session-data-container">
                                <div class="session-state">
                                    <ng-container *ngIf="room.state">
                                        <span class="room-open">Sala abierta</span>
                                    </ng-container>
                                    <ng-container *ngIf="!room.state">
                                        <span class="room-closed">Sala cerrada</span>
                                    </ng-container>
                                </div>
                                <div class="session-users-upload-audio">
                                    Numero de consultas pendientes {{room.usersWithAudioUpload}} /
                                    {{room.minimumNumberMessagesToStart}}
                                </div>

                                <div class="session-seer">
                                    Nombre de vidente: {{room.seerName}}
                                </div>

                                <div class="btn-container">
                                    <a class="template-btn btn-black mt20" 
                                        (click)="goToSession(room.id, room.state, room.seerId)">Entrar a la sala</a><br>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>