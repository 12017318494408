import { SchedulePrivateSessionModel } from "./schedule-private-session.model"
import { DurationCostModel } from "./duration-cost.model"

export class SeerSchedulePrivateSessionModel {  
    public id: string
    public namePrivateSession: string
    public seerId: string
    public allowTraduction: boolean
    public schedule: SchedulePrivateSessionModel []
    public durationAndCost: DurationCostModel []
}