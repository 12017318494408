import { Injectable } from '@angular/core'
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { environment } from 'src/environments/environment';

@Injectable()

export class FirebaseManager {
    private firebaseConfig
    private app

    constructor() { 
        this.firebaseConfig = {
            apiKey: environment.apiKey,
            authDomain: environment.authDomain,
            projectId: environment.projectId,
            storageBucket: environment.storageBucket,
            messagingSenderId: environment.messagingSenderId,
            appId: environment.appId
          };
    }

    initializeFirebaseServices(){
        console.log("firebase services initialized")
        this.initializeFirebaseApp()
        this.initializeFirebasePermormance()
    }
    
    private initializeFirebaseApp(){
        this.app = initializeApp(this.firebaseConfig);
    }

    private initializeFirebasePermormance(): any{
        return getPerformance(this.app);
    }

}
