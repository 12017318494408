import { NgModule, APP_INITIALIZER, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { AuthInterceptor } from './services/http-interceptors/auth.interceptor'
import { ErrorInterceptor } from './services/http-interceptors/error.interceptor'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HttpModule } from '@angular/http'

/* Components */
import { AppComponent } from './app.component'
import { PreloaderComponent } from './components/common/preloader/preloader.component'
import { NavbarComponent } from './components/common/navbar/navbar.component'
import { FooterComponent } from './components/common/footer/footer.component'
import { LoginComponent } from './components/pages/login/login.component'
import { RegisterComponent } from './components/pages/register/register.component'
import { UserDashboardComponent } from './components/pages/user-dashboard/user-dashboard.component'
import { HomeComponent } from './components/pages/home/home.component'
import { FaqComponent } from './components/pages/faq/faq.component'
import { ErrorComponent } from './components/pages/error/error.component'
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component'
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component'
import { LegalAdviceComponent } from './components/pages/legal-advice/legal-advice.component'
import { PersonalDataAdviceComponent } from './components/pages/personal-data-advice/personal-data-advice.component'
import { HiringTermsAdviceComponent } from './components/pages/hiring-terms-advice/hiring-terms-advice.component'
import { CookiesAdviceComponent } from './components/pages/cookies-advice/cookies-advice.component'
import { AboutUsComponent } from './components/pages/about-us/about-us.component'
import { AboutMeComponent } from './components/pages/about-me/about-me.component'
import { SessionsComponent } from './components/pages/sessions/sessions.component'
import { BlogComponent } from './components/pages/blog/blog.component'
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component'
import { ContactComponent } from './components/pages/contact/contact.component'
import { SeersListComponent } from './components/pages/seers-list/seers-list.component'
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component'
import { TermsOfUseComponent } from './components/pages/terms-of-use/terms-of-use.component'
import { BookPrivateSessionComponent } from './components/pages/book-private-session/book-private-session.component'

// Services
import { UsersBackendService } from './services/users-backend.service'
import { SeersBackendService } from './services/seers-backend.service'
import { RoomsBackendService } from './services/rooms-backend.service'
import { OAuth2BackendService } from './services/oauth2-backend.service'
import { OAuth2Service } from './services/oauth2.service'
import { CookieService } from 'ngx-cookie-service'
import { LiveRoomBackendService } from './services/live-room-backend.service'
import { DonationBackendService } from './services/donation-backend.service'
import { BlobModule } from 'angular-azure-blob-service'
import { AzureBackendService } from './services/azure-backend.service'
import { LoadingService } from './services/loading/loading.service'
import { EmailBackendService } from './services/email-backend.service'
import { PromotionsBackendService } from './services/promotions-backend.service'
import { RedeemedPromotionsBackendService } from './services/redeemed-promotions.backend.service'
import { SessionsBackendService } from './services/sessions-backend.service'
import { ExcelService } from './services/excel/excel.service'
import { SessionCreditConsolidatedBackendService } from './services/session-credit-consolidated-backend.service'
import { PostsBackendService } from './services/posts-backend.service'
import { SeerSchedulePrivateSessionBackendService } from './services/seer-schedule-private-session-backend.service'
import { PrivateSessionBackendService } from './services/private-session-backend.service'
import { MessagingBackendService } from './services/messaging-backend.service'
import { YouTubeService } from './services/gcp/youtube.service'
import { CompressImageService } from './services/images/compress-image.service'

/* Utils */
import { Utils } from './utilities/utils'
import { Globals } from './utilities/globals'
import { GTMUtils } from './utilities/gtm.utils' 

/* Managers */
import { UserManager } from './managers/user.manager'
import { RoomManager } from './managers/room.manager'
import { LiveRoomManager } from './managers/live-room.manager'
import { SeerManager } from './managers/seer.manager'
import { PromotionManager } from './managers/promotion.manager'
import { RedeemedPromotionsManager } from './managers/redeemed-promotions.manager'
import { SessionsManager } from './managers/sessions.manager'
import { SessionCreditConsolidatedManager } from './managers/session-credit-consolidated.manager' 
import { PostsManager } from './managers/posts.manager'
import { FirebaseManager } from './managers/firebase/firebase.manager'
import { EmailManager } from './managers/email.manager'
import { YoutubeManager } from './managers/youtube.manager'
import { AzureManager } from './managers/azure.manager'
import { SeerSchedulePrivateSessionManager } from './managers/seer-schedule-private-session.manager'
import { PrivateSessionManager } from './managers/private-session.manager'
import { MessagingManager } from './managers/messaging.manager'

/* Pipes */
import { SafeHtmlPipe } from './pipes/safe-html.pipe'

/* Social Login */
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login'
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login'

// Custom Views
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgSwitcheryModule } from 'angular-switchery-ios'

// Material elements
import { MatTimepickerModule } from 'mat-timepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatRadioModule } from '@angular/material/radio'

// GDPR
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent'
import { environment } from 'src/environments/environment'

// GTM 
import { GoogleTagManagerModule } from 'angular-google-tag-manager'

// PayPal
import { NgxPayPalModule } from 'ngx-paypal'

// WYSIWYG
import { AngularEditorModule } from '@kolkov/angular-editor'
 
// GUIDED TOUR
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour'

// SHARE IN RRSS
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons'
import { ShareIconsModule } from 'ngx-sharebuttons/icons'

// Calendar
import { CalendarModule } from '@syncfusion/ej2-angular-calendars'

// Telephone prefixes input
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": environment.SiteDomainForCookies
  },
  "position": "bottom",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#c1ab65",
      "text": "#000000",
      "link": "#000000"
    },
    "button": {
      "background": "#ffffff",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "content": {
    "message": "Este sitio web utiliza cookies propias y de terceros que almacenan y recuperan información cuando navegas. Con su permiso, TOT, utilizará cookies con fines analíticos y para mostrarle publicidad personalizada con base en un perfil elaborado a partir de sus hábitos de navegación. Puede hacer clic en el botón “Aceptar” para otorgarnos su consentimiento para que llevemos a cabo todo el procesamiento previamente descrito. Puede consultar nuestra Política de Cookies.",
    "dismiss": "¡Entendido!",
    "allow": "Aceptar cookies",
    "link": "Leer más",
    "href": "https://cookiesandyou.com",
    "policy": "Política de cookies"
  }
}

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    UserDashboardComponent,
    HomeComponent,
    FaqComponent,
    ErrorComponent,
    ComingSoonComponent,
    PrivacyPolicyComponent,
    LegalAdviceComponent,
    PersonalDataAdviceComponent,
    HiringTermsAdviceComponent,
    CookiesAdviceComponent,
    AboutUsComponent,
    AboutMeComponent,
    SessionsComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactComponent,
    SeersListComponent,
    RecoverPasswordComponent,
    TermsOfUseComponent,
    BookPrivateSessionComponent,
    SafeHtmlPipe
  ],
  imports: [
    HttpClientModule,
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    BlobModule.forRoot(),
    NgbModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    MatTimepickerModule,
    MatIconModule,
    MatRadioModule,
    NgSwitcheryModule,
    NgxPayPalModule,
    AngularEditorModule,
    GuidedTourModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    CalendarModule,
    ShareIconsModule,
    NgxIntlTelInputModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.GTMId
    })
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GoogleAppId
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.FBAppId
            )
          },
        ]
      } as SocialAuthServiceConfig,
    },
    { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    OAuth2Service,
    UsersBackendService,
    SeersBackendService,
    RoomsBackendService,
    LiveRoomBackendService,
    DonationBackendService,
    AzureBackendService,
    OAuth2BackendService,
    CookieService,
    LoadingService,
    EmailBackendService, 
    PromotionsBackendService,
    RedeemedPromotionsBackendService,
    SessionsBackendService,
    SessionCreditConsolidatedBackendService,
    PostsBackendService,
    SeerSchedulePrivateSessionBackendService,
    PrivateSessionBackendService,
    MessagingBackendService,
    YouTubeService,
    CompressImageService,
    ExcelService,
    Utils,
    GTMUtils,
    Globals,
    UserManager,
    RoomManager,
    LiveRoomManager,
    SeerManager,
    PromotionManager,
    RedeemedPromotionsManager,
    SessionsManager,
    SessionCreditConsolidatedManager,
    PostsManager,
    FirebaseManager,
    EmailManager,
    YoutubeManager,
    AzureManager,
    SeerSchedulePrivateSessionManager,
    PrivateSessionManager,
    MessagingManager,
    GuidedTourService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
