import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { MessagingBackendService } from '../services/messaging-backend.service'

@Injectable()

export class MessagingManager {

    private messagingAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _cookieService: CookieService,
        private _messagingBackendService: MessagingBackendService
    ) { }

    ngOnDestroy() {
        if (this.messagingAPI != null) {
            this.messagingAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    sendPhoneVerificationSMS(dataToSendPhoneVerificationSMS) {
        return new Promise((resolve) => {
            this.messagingAPI = this._messagingBackendService.sendPhoneVerificationSMS(dataToSendPhoneVerificationSMS)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Get donation data retrieved success")

                        resolve(response.donation_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }
}