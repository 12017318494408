<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Contacto</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Contacto</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-item">
                    <h2>Contáctanos</h2>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" [(ngModel)]="inputName"  name="name" id="name" class="form-control" placeholder="Nombre*">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="email" [(ngModel)]="inputEmail" name="email" id="email" class="form-control"
                                        placeholder="Email*">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" [(ngModel)]="inputMessage" class="form-control" id="message" cols="30" rows="8"
                                        placeholder="Mensaje*"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <button type="submit" class="contact-btn btn" (click)="sendContactForm()">Enviar Mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-item">
                    <h2>Datos de contacto</h2>
                    <div class="contact-address">
                        <div class="contact-inner">
                            <i class='bx bx-current-location icon'></i>
                            <h4>Dirección:</h4>
                            <span>C/Santiago 84. 28822. Coslada. Madrid</span>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-phone-call icon'></i>
                            <h4>Teléfono:</h4>
                            <a href="tel:+34648168213">+34 648 168 213</a>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-mail-send icon'></i>
                            <h4>Email:</h4>
                            <a href="mailto:contacto@totonline.es">contacto@totonline.es</a>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-support icon'></i>
                            <h4>RRSS:</h4>

                            <ul>
                                <li><a href="https://www.facebook.com/TOTonline-106344058566599/" target="_blank"><i
                                            class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://www.instagram.com/totonline.es" target="_blank"><i
                                            class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="map-area">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.47306010645!2d-3.540312484293741!3d40.420522763332166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd423081aaeb5e95%3A0x28f2da1e6303260e!2sC.%20de%20Santiago%2C%2084%2C%2028822%20Coslada%2C%20Madrid!5e0!3m2!1ses-419!2ses!4v1638291803580!5m2!1ses-419!2ses"
        height="450" style="border:0; width: 100%" allowfullscreen="" loading="lazy"></iframe>
</div>