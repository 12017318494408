import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'

import { SocialAuthService, SocialUser } from 'angularx-social-login'

import { CookieService } from 'ngx-cookie-service'

import { Router } from "@angular/router"

import { Utils } from '../../../utilities/utils'

import { UserModel } from '../../../models/users/user.model'
import { SeerModel } from '../../../models/seers/seers.model'
import { RoomModel } from '../../../models/rooms/room.model'
import { PromotionsModel } from '../../../models/promotions/promotions.model'
import { SessionsModel } from '../../../models/sessions/sessions.model'
import { SessionCreditConsolidatedModel } from '../../../models/session-credit-consolidated/session-credit-consolidated.model'
import { RedeemedPromotionsModel } from '../../../models/promotions/redeemed-promotions.model'
import { PostsModel } from '../../../models/posts/posts-model'
import { SeerSchedulePrivateSessionModel } from '../../../models/private-session/seer-schedule-private-session.model'

import { LoadingService } from '../../../services/loading/loading.service'

import Swal from 'sweetalert2'

import { NavbarComponent } from '../../common/navbar/navbar.component'

import * as shajs from 'sha.js'
import * as moment from 'moment'

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal'
import { environment } from 'src/environments/environment'
import { ExcelService } from 'src/app/services/excel/excel.service'
import { UserManager } from 'src/app/managers/user.manager'
import { SeerManager } from 'src/app/managers/seer.manager'
import { RoomManager } from 'src/app/managers/room.manager'
import { PromotionManager } from 'src/app/managers/promotion.manager'
import { SessionsManager } from 'src/app/managers/sessions.manager'
import { SessionCreditConsolidatedManager } from 'src/app/managers/session-credit-consolidated.manager'
import { RedeemedPromotionsManager } from 'src/app/managers/redeemed-promotions.manager'
import { PostsManager } from 'src/app/managers/posts.manager'

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CompressImageService } from 'src/app/services/images/compress-image.service'
import { take } from 'rxjs'
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { SeerSchedulePrivateSessionManager } from 'src/app/managers/seer-schedule-private-session.manager'

@Component({
	selector: 'user-dashboard',
	templateUrl: './user-dashboard.component.html',
	styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

	public socialUser: SocialUser
	public tabSelected: Number
	public userCredit
	public creditToAdd
	public seers: SeerModel[]
	public sessions: SessionsModel[]
	public posts: PostsModel[]
	public seerScheduledPrivateSessions: SeerSchedulePrivateSessionModel[]
	public quantityInput: number = null
	public seerModel: SeerModel = new SeerModel()
	public roomModel: RoomModel = new RoomModel()
	public promotionModel: PromotionsModel = new PromotionsModel()
	public postsModel: PostsModel = new PostsModel()
	public seerSchedulePrivateSessionModel: SeerSchedulePrivateSessionModel = new SeerSchedulePrivateSessionModel()
	public currentUser
	public typeUser
	public nameNewSeer: string
	public surnameNewSeer: string
	public emailNewSeer: string
	public passwordNewSeer: string
	public idYouTubeChannelNewRoom: string
	public minimumAportationNewRoom: number
	public timeToConsultNewRoom: number
	public starValueNewRoom: number
	public minimumNumberMessagesToStart: number
	public minimumQuantityToDonate: string
	public introductionNewSeer: string
	public firstNameToEdit: string
	public lastNameToEdit: string
	public emailToEdit: string
	public birthdayToEdit: string
	public seerImageSelected
	public nameNewRoom: string
	public seerDataNewRoom: any
	public seerDataToDelete: any
	public seerIdNewRoom: string
	public seerNameNewRoom: string
	public seerEmailNewRoom: string
	public seerPhotoPath: string
	public dateNewPost: any
	public titleNewPost: any
	public subtitleNewPost: string
	public imageNewPost: string
	public textNewPost: string
	public stateRoom: boolean
	public stateRoomLabel: string
	public changeRoomStateTextButton: string
	public seerImage
	public dateNewRoom
	public timeNewRoom: string
	public seerRooms: RoomModel[]
	public promotions: PromotionsModel[]
	public haveRooms: boolean = false
	public havePromotions: boolean = false
	public haveSeers: boolean = false
	public haveSessions: boolean = false
	public havePosts: boolean = false
	public havePrivateSessions: boolean = false
	public payPalConfig: IPayPalConfig
	public titleCreateOrEditRoomTab: string
	public titleCreateOrEditSeerTab: string
	public titleCreateOrEditPromotionTab: string
	public titleCreateOrEditPostTab: string
	public titleCreateOrEditPrivateSession: string
	public currentDateRoomEdit: string
	public currentTimeRoomEdit: string
	public nameNewPromotion: string
	public valueNewPromotion: number
	public dateToNewPromotion
	public currentDateToNewPromotion
	public nameNewPrivateSession: string
	public labelForToogleShowDate: string = "¿Mostrar fecha?"
	public labelForTogglePromotionOnlyOneUse = "¿Promoción de un solo uso?"
	public labelForToggleAllowTraduction = "¿Permitir traducción?"
	public labelForToggleAllowPrivateSession = "¿Permite salas privadas?"
	public toggleShowDateValue: boolean = false
	public togglePromotionOnlyOneUseValue: boolean = false
	public toggleAllowTraductionPrivateSession: boolean = false
	public toggleAllowPrivateSession: boolean = false
	public daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
	public itemsDurationSession = []
	public allTimesToBookSession = []
	public daySelected: string
	public daySelectedIndex: number
	public selectedHours = []
	public hourSelectedByDay = []
	public seerDataNewPrivateSession: any
	private storageAccountImagesPath: string
	private idPromotionToEdit: string
	private idRoomToEdit: string
	private idSessionToEdit: string
	private idSeerToEdit: string
	private idPostToEdit: string
	private idPrivateSessionToEdit: string
	private operation: string
	private extension: string
	private uploadConfig: UploadConfig

	public angularEditorConfig: AngularEditorConfig

	//borrar 
	private test = false

	constructor(
		private _cookieService: CookieService,
		private _socialAuthService: SocialAuthService,
		private _router: Router,
		private _utils: Utils,
		private _loadingService: LoadingService,
		private _excelService: ExcelService,
		private _userManager: UserManager,
		private _seerManager: SeerManager,
		private _roomManager: RoomManager,
		private _promotionManager: PromotionManager,
		private _sessionManager: SessionsManager,
		private _sessionCreditConsolidatedManager: SessionCreditConsolidatedManager,
		private _redeemedPromotionsManager: RedeemedPromotionsManager,
		private _postsManager: PostsManager,
		private _seerSchedulePrivateSessionManager: SeerSchedulePrivateSessionManager,
		private _compressImageService: CompressImageService,
		private _blobService: BlobService,
		private _modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.storageAccountImagesPath = this._utils.getAzureStorageAccountPathForImages()

		if (this._utils.IsJsonString(this._cookieService.get('User'))) {
			this.typeUser = JSON.parse(this._cookieService.get("Type"))
			this.currentUser = JSON.parse(this._cookieService.get('User')) as UserModel

			NavbarComponent.typeUser = this.typeUser
			NavbarComponent.currentUser = this.currentUser
			NavbarComponent.userIsLogged = true
		}
		else {
			this._router.navigate(['home'])
		}



		this.getAllSeers()
		this.initWYSIWYG()

		this.tabSelected = 1

		this.addRowDurationAndCost()
	}

	private initWYSIWYG() {
		this.angularEditorConfig = {
			sanitize: false,
			editable: true,
			spellcheck: true,
			height: '15rem',
			minHeight: '5rem',
			placeholder: 'Introduce el texto aquí...',
			translate: 'no',
			defaultParagraphSeparator: 'p',
			defaultFontName: 'Arial',
			customClasses: [
				{
					name: "quote",
					class: "quote",
				},
				{
					name: 'redText',
					class: 'redText'
				},
				{
					name: "titleText",
					class: "titleText",
					tag: "h1",
				},
			]
		};
	}

	private initPayPal() {
		if (this.creditToAdd == null || this.creditToAdd == undefined) {
			Swal.fire({
				icon: 'error',
				title: 'Por favor, selecciona una cantidad o, introdúcela',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		let temporalUserCredit = (this.currentUser.credit + this.creditToAdd)

		if (temporalUserCredit < 10) {
			Swal.fire({
				icon: 'warning',
				title: 'Recuerda que para acceder a la sala, debes disponer mínimo de 10€ en tu cartera y ahora mismo con esta recarga, tendrías: '.concat(temporalUserCredit.toString()).concat("€"),
				showConfirmButton: false,
				timer: 5000
			})

		}

		let amount = this.creditToAdd

		if (this.test && environment.APIEndPointBackend.includes("localhost")) {
			this._loadingService.show()

			let dataTransaction = {
				"id": "94268485C9386952N",
				"intent": "CAPTURE",
				"status": "COMPLETED",
				"purchase_units": [
					{
						"amount": {
							"currency_code": "EUR",
							"value": "10.00",
							"breakdown": {
								"item_total": {
									"currency_code": "EUR",
									"value": "10.00"
								},
								"shipping": {
									"currency_code": "EUR",
									"value": "0.00"
								},
								"handling": {
									"currency_code": "EUR",
									"value": "0.00"
								},
								"insurance": {
									"currency_code": "EUR",
									"value": "0.00"
								},
								"shipping_discount": {
									"currency_code": "EUR",
									"value": "0.00"
								}
							}
						},
						"payee": {
							"email_address": "contacto@totonline.es",
							"merchant_id": "X83ULAWA4ME3N"
						},
						"description": "Credito TOT Online 10€",
						"soft_descriptor": "PAYPAL *TOTONLINE",
						"items": [
							{
								"name": "Credito TOT Online 10€",
								"unit_amount": {
									"currency_code": "EUR",
									"value": "10.00"
								},
								"tax": {
									"currency_code": "EUR",
									"value": "0.00"
								},
								"quantity": "1"
							}
						],
						"shipping": {
							"name": {
								"full_name": "David Linares"
							},
							"address": {
								"address_line_1": "Calle Santiago, Casa baja",
								"admin_area_2": "Coslada",
								"admin_area_1": "MADRID",
								"postal_code": "28231",
								"country_code": "ES"
							}
						},
						"payments": {
							"captures": [
								{
									"id": "87V80567K8631591U",
									"status": "COMPLETED",
									"amount": {
										"currency_code": "EUR",
										"value": "10.00"
									},
									"final_capture": true,
									"seller_protection": {
										"status": "NOT_ELIGIBLE"
									},
									"create_time": "2021-11-18T13:08:09Z",
									"update_time": "2021-11-18T13:08:09Z"
								}
							]
						}
					}
				],
				"payer": {
					"name": {
						"given_name": "David",
						"surname": "Linares"
					},
					"email_address": "gonzalo.lasarte@unus.digital",
					"payer_id": "R2SCLFRZME6NL",
					"address": {
						"country_code": "ES"
					}
				},
				"create_time": "2021-11-18T13:04:54Z",
				"update_time": "2021-11-18T13:08:09Z",
				"links": [
					{
						"href": "https://api.paypal.com/v2/checkout/orders/94268485C9386952N",
						"rel": "self",
						"method": "GET"
					}
				]
			}
			this.addCreditToUser(dataTransaction)

			return
		}

		this.payPalConfig = {
			currency: 'EUR',
			clientId: environment.PayPalClientId,
			createOrderOnClient: (data) => <ICreateOrderRequest>{
				intent: 'CAPTURE',
				purchase_units: [
					{
						amount: {
							currency_code: 'EUR',
							value: amount,
							breakdown: {
								item_total: {
									currency_code: 'EUR',
									value: amount
								}
							}
						},
						items: [
							{
								name: 'Credito TOT Online '.concat(amount).concat("€"),
								quantity: '1',
								category: 'DIGITAL_GOODS',
								unit_amount: {
									currency_code: 'EUR',
									value: amount,
								},
							}
						]
					}
				]
			},
			advanced: {
				commit: 'true'
			},
			style: {
				label: 'paypal',
				layout: 'vertical'
			},
			onApprove: (data, actions) => {
				console.log('onApprove - transaction was approved, but not authorized', data, actions);
				actions.order.get().then(details => {
					console.log('onApprove - you can get full order details inside onApprove: ', details);
				});
			},
			onClientAuthorization: (dataTransaction) => {
				console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', dataTransaction)

				this._loadingService.show()

				this.addCreditToUser(dataTransaction)
			},
			onCancel: (data, actions) => {
				console.log('OnCancel', data, actions)

				Swal.fire({
					icon: 'warning',
					title: 'Operación cancelada.',
					showConfirmButton: false,
					timer: 4000
				})
			},
			onError: err => {
				console.log('OnError', err)

				Swal.fire({
					icon: 'error',
					title: 'Ocurrió algún error al procesar el pago. Inténtelo de nuevo más tarde.',
					showConfirmButton: false,
					timer: 4000
				})
			},
			onClick: (data, actions) => {
				console.log('onClick', data, actions);
			},
		};

		this.tabSelected = 4

	}

	showUserData() {
		this.tabSelected = 1
	}

	showCreditTab() {
		this.tabSelected = 2
	}

	showAddCreditTab() {
		this.tabSelected = 3
	}

	showPayTab() {
		this.initPayPal()
	}

	showAddOrEditSeerTab(operation, seer: SeerModel = null) {
		this.operation = operation

		if (this.operation == "create") {
			this.titleCreateOrEditSeerTab = "Crear nuevo maestro"
			this.nameNewSeer = ""
			this.surnameNewSeer = null
			this.emailNewSeer = ""
			this.passwordNewSeer = ""
			this.introductionNewSeer = ""
			this.seerImage = null
			this.toggleAllowPrivateSession = false
		}
		else if (this.operation == "update") {
			this.titleCreateOrEditSeerTab = "Modificar maestro"

			if (seer != null) {
				this.idSeerToEdit = seer.id
				this.nameNewSeer = seer.firstName
				this.surnameNewSeer = seer.lastName
				this.emailNewSeer = seer.email
				this.introductionNewSeer = seer.introduction
				this.seerImage = this.storageAccountImagesPath.concat(seer.photoPath)
				this.toggleAllowPrivateSession = seer.allowPrivateSession
			}
		}

		this.tabSelected = 5
	}

	showManagementSeersTab() {
		this.tabSelected = 6
	}

	showManagementRoomsTab() {
		this.tabSelected = 7
		if (this.currentUser.rol == "3") {
			this.getRooms()
		}
		else if (this.currentUser.rol == "2") {
			this.getRooms(this.currentUser.email)
		}
	}

	showAddOrEditRoomTab(operation, room: RoomModel = null) {
		this.operation = operation

		if (this.operation == "create") {
			this.titleCreateOrEditRoomTab = "Crear nueva sala"
			this.nameNewRoom = ""
			this.seerDataNewRoom = null
			this.seerEmailNewRoom = ""
			this.idYouTubeChannelNewRoom = ""
			this.minimumAportationNewRoom = 0
			this.timeToConsultNewRoom = 0
			this.starValueNewRoom = 0
			this.minimumNumberMessagesToStart = 0
			this.dateNewRoom = ""
			this.timeNewRoom = ""
			this.currentDateRoomEdit = ""
			this.currentTimeRoomEdit = ""
			this.stateRoomLabel = ""
			this.stateRoom = false
			this.changeRoomStateTextButton = ""
			this.toggleShowDateValue = false
		}
		else if (this.operation == "update") {
			this.titleCreateOrEditRoomTab = "Modificar sala"

			if (room != null) {
				this.idRoomToEdit = room.id
				this.idSessionToEdit = room.sessionId
				this.nameNewRoom = room.nameRoom
				this.seerIdNewRoom = room.seerId
				this.seerNameNewRoom = room.seerName
				this.seerEmailNewRoom = room.seerEmail
				this.seerDataNewRoom = this.seers.filter((key) => (key.id == room.seerId))[0]
				this.idYouTubeChannelNewRoom = room.idYouTubeChannel
				this.minimumAportationNewRoom = room.minimumAportation
				this.timeToConsultNewRoom = (room.timeToConsult / 60)
				this.starValueNewRoom = room.starValue
				this.minimumNumberMessagesToStart = room.minimumNumberMessagesToStart
				this.currentDateRoomEdit = room.dateRoom
				this.currentTimeRoomEdit = room.timeRoom
				this.stateRoom = room.state
				this.toggleShowDateValue = room.showDate

				if (this.stateRoom) {
					this.stateRoomLabel = "Sala abierta"
					this.changeRoomStateTextButton = "Cerrar sala"
				}
				else {
					this.stateRoomLabel = "Sala cerrada"
					this.changeRoomStateTextButton = "Abrir sala"
				}
			}
		}

		this.tabSelected = 8
	}

	showManagementPromotionsTab() {
		this.getPromotions()
		this.tabSelected = 9
	}

	showManagementPrivateSessionsTab() {
		this.tabSelected = 7
		if (this.currentUser.rol == "3") {
			this.getPrivateSessions()
		}
		else if (this.currentUser.rol == "2") {
			this.getPrivateSessions(this.currentUser.id)
		}
		
		this.tabSelected = 11
	}

	showAddOrEditPrivateSessionTab(operation, seerScheduledPrivateSession: SeerSchedulePrivateSessionModel) {

		this.getHoursOfDayInSlot()

		this.operation = operation

		if (this.operation == "create") {
			this.titleCreateOrEditPrivateSession = "Crear sesión privada"
			this.nameNewPrivateSession = ""
			this.selectedHours = []
			this.itemsDurationSession = []
			this.addRowDurationAndCost()
		}
		else if (this.operation == "update") {
			this.titleCreateOrEditPostTab = "Modificar sesión privada"

			if (seerScheduledPrivateSession != null) {
				this.idPrivateSessionToEdit = seerScheduledPrivateSession.id
				this.seerDataNewPrivateSession = this.seers.filter((key) => (key.id == seerScheduledPrivateSession.seerId))[0]
				this.nameNewPrivateSession = seerScheduledPrivateSession.namePrivateSession
				seerScheduledPrivateSession.schedule.forEach(item =>{
					this.selectedHours[item.dayIndex] = item.hours.split(",")
				})

				this.itemsDurationSession = seerScheduledPrivateSession.durationAndCost
			}
		}

		this.tabSelected = 12
	}

	addRowDurationAndCost() {
		this.itemsDurationSession.push({ duration: 0, cost: 0 })
	}

	removeRowDurationAndCost(item) {
		this.itemsDurationSession.splice(this.itemsDurationSession.indexOf(item), 1)
	}

	showManagementBillingTab() {
		this.getSessions()
		this.tabSelected = 13
	}

	showAddOrEditPromotionTab(operation, promotion: PromotionsModel = null) {
		this.operation = operation

		if (this.operation == "create") {
			this.titleCreateOrEditPromotionTab = "Crear nueva promoción"
			this.nameNewPromotion = ""
			this.valueNewPromotion = 0
			this.dateToNewPromotion = ""
			this.togglePromotionOnlyOneUseValue = false
		}
		else if (this.operation == "update") {
			this.titleCreateOrEditPromotionTab = "Modificar promoción"

			if (promotion != null) {
				this.idPromotionToEdit = promotion.id
				this.nameNewPromotion = promotion.namePromotion
				this.valueNewPromotion = promotion.valuePromotion
				//this.dateToNewPromotion = moment(promotion.dateToPromotion,'DD-MM-YYYY').format('DD-MM-YYYY')
				this.currentDateToNewPromotion = promotion.dateToPromotion
				this.togglePromotionOnlyOneUseValue = promotion.promotionOnlyOneUse
			}
		}

		this.tabSelected = 10
	}

	showManagementPostsTab() {
		this.getAllPosts()
		this.tabSelected = 14
	}

	showManagementSettingsTab() {
		this.tabSelected = 16
	}

	showAddOrEditPostsTab(operation, post: PostsModel) {
		this.operation = operation

		if (this.operation == "create") {
			this.titleCreateOrEditPostTab = "Crear nuevo post"
			this.dateNewPost = ""
			this.titleNewPost = ""
			this.subtitleNewPost = ""
			this.imageNewPost = ""
			this.textNewPost = ""
		}
		else if (this.operation == "update") {
			this.titleCreateOrEditPostTab = "Modificar post"

			if (post != null) {
				this.idPostToEdit = post.id
				this.dateNewPost = post.date
				this.titleNewPost = post.title
				this.subtitleNewPost = post.subtitle
				this.imageNewPost = post.image
				this.textNewPost = post.text
			}
		}

		this.tabSelected = 15
	}

	changeRoomState() {
		let stateString = "¿Abrir sala?"

		if (this.stateRoom) {
			stateString = "¿Cerrar sala?"
		}

		if (this)
			Swal.fire({
				title: stateString,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Si',
				cancelButtonText: 'Cancelar'
			})
				.then((result) => {
					if (result.isConfirmed) {

						if (this.stateRoom) {
							console.log("go to close room")

							let session = {
								dateEnd: new Date(),
							}

							this.updateSession(session, false)
						}
						else if (!this.stateRoom) {
							console.log("go to open room")

							let session = {
								idRoom: this.idRoomToEdit,
								dateStart: new Date(),
								nameSession: this.nameNewRoom
							}

							this.createSession(session, true)
						}
					}
				})

	}

	editUser() {
		if (this.currentUser.provider.toLowerCase() == "google" || this.currentUser.provider.toLowerCase() == "facebook" && (this))
			if (this.currentUser.firstName == null || this.currentUser.firstName == "") {
				Swal.fire({
					icon: 'error',
					title: 'El nombre, es un campo obligatorio',
					showConfirmButton: false,
					timer: 4000
				})

				return;
			}

		if (this.currentUser.lastName == null || this.currentUser.lastName == "") {
			Swal.fire({
				icon: 'error',
				title: 'El/Los apellidos, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.currentUser.email == null || this.currentUser.email == "") {
			Swal.fire({
				icon: 'error',
				title: 'El email, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.typeUser != 2 && (this.currentUser.birthday == null || this.currentUser.birthday == "")) {
			Swal.fire({
				icon: 'error',
				title: 'La fecha de nacimiento, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		let userAge = this._utils.getAge(this.currentUser.birthday)

		if (userAge < 18) {
			Swal.fire({
				icon: 'error',
				title: 'Para acceder a la plataforma, debes tener al menos 18 años.',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		if (this.typeUser != 2 && (this.currentUser.nickname == null || this.currentUser.nickname == "")) {
			Swal.fire({
				icon: 'error',
				title: 'El nickname, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		this.currentUser.name = this.currentUser.firstName.concat(" ").concat(this.currentUser.lastName)

		if (this.typeUser == "2") {
			this.updateSeer(this.currentUser.id)
		}
		else {
			this._userManager.updateNickname(this.currentUser.id, this.currentUser).then(result => {
				if (result) {
					this.updateUser()
				}
			})
		}

	}

	selectCredit(quantity) {
		if (quantity != null) {
			this.creditToAdd = quantity
		}

		else if (this.quantityInput != null) {
			if (this.quantityInput < 15) {
				Swal.fire({
					icon: 'error',
					title: 'La cantidad mínima que se puede recargar, son 15€.',
					showConfirmButton: false,
					timer: 4000
				})

				return
			}

			if (this.quantityInput <= 0) {
				Swal.fire({
					icon: 'error',
					title: 'Por favor, introduzca una cantidad válida.',
					showConfirmButton: false,
					timer: 4000
				})

				return
			}
			this.creditToAdd = this.quantityInput
		}

		console.log(this.creditToAdd)
	}

	addCreditToUser(dataTransaction) {

		let currentCreditUser = this.currentUser.credit
		currentCreditUser = (currentCreditUser + Number(dataTransaction.purchase_units[0].amount.value))

		this.currentUser.credit = currentCreditUser

		let dataToUpdateUserCredit = {
			creditUserToUpdate: this.currentUser.credit,
			dataTransaction: dataTransaction
		}

		this._userManager.updateUserCredit(this.currentUser.id, dataToUpdateUserCredit).then(result => {
			if (result) {
				let userData = result as UserModel

				Swal.fire({
					icon: 'success',
					title: userData.firstName + ' su crédito ha sido añadido correctamente',
					showConfirmButton: false,
					timer: 4000
				})

				this._cookieService.set('User', JSON.stringify(userData), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax");
				this._utils.redirectTo(this._router, "user-dashboard")

				this._loadingService.hide()
			}
		})

	}

	createOrUpdatePost() {
		if (this.dateNewPost == null || this.dateNewPost == "") {
			Swal.fire({
				icon: 'error',
				title: 'La fecha del post, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.titleNewPost == null || this.titleNewPost == "") {
			Swal.fire({
				icon: 'error',
				title: 'El título del post, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.subtitleNewPost == null || this.subtitleNewPost == "") {
			Swal.fire({
				icon: 'error',
				title: 'El subtítulo del post, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.operation == "create" && (this.imageNewPost == null || this.imageNewPost == "")) {
			Swal.fire({
				icon: 'error',
				title: 'La foto del post, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.textNewPost == null || this.textNewPost == "") {
			Swal.fire({
				icon: 'error',
				title: 'El texto del post, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}


		this.postsModel.date = this.dateNewPost
		this.postsModel.title = this.titleNewPost
		this.postsModel.subtitle = this.subtitleNewPost
		this.postsModel.image = this.imageNewPost
		this.postsModel.text = this.textNewPost

		if (this.operation == "create") {
			this.createPost()
		}
		else if (this.operation == "update") {
			this.updatePost(this.idPostToEdit, this.postsModel)
		}
	}

	createOrUpdatePrivateSession() {
		
		if(this.seerDataNewPrivateSession == null){
			Swal.fire({
				icon: 'error',
				title: 'Debes seleccionar un maestro espiritual',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		if (this.nameNewPrivateSession == null || this.nameNewPrivateSession == "") {
			Swal.fire({
				icon: 'error',
				title: 'El nombre de la sala privada, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		if (this.selectedHours.length <= 0) {
			Swal.fire({
				icon: 'error',
				title: 'Se debe seleccionar al menos una hora de un día',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}
		
		if (this.itemsDurationSession.length == 1 && (this.itemsDurationSession[0].duration == 0 || this.itemsDurationSession[0].cost == 0)) {
			Swal.fire({
				icon: 'error',
				title: 'Se debe seleccionar al menos una duración y coste',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		let schedule = []
		this.selectedHours.forEach((hoursByDay, index) => {
			schedule.push({
				dayIndex: index,
				dayString: this.daysOfWeek[index],
				hours: hoursByDay.toString()
			})
		})

		let durationAndCost = []
		let sessionExceedTime = false
		this.itemsDurationSession.forEach(itemDurationCost =>{
			if(itemDurationCost.duration > 60){
				sessionExceedTime  = true
			}

			durationAndCost.push({
				duration: itemDurationCost.duration,
				cost: itemDurationCost.cost
			})
		})

		if(sessionExceedTime){
			Swal.fire({
				icon: 'error',
				title: 'No se puede programar una sesión con más de 60 minutos de duración',
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		this.seerSchedulePrivateSessionModel.namePrivateSession = this.nameNewPrivateSession
		this.seerSchedulePrivateSessionModel.seerId = this.seerDataNewPrivateSession.id
		this.seerSchedulePrivateSessionModel.allowTraduction = this.toggleAllowTraductionPrivateSession
		this.seerSchedulePrivateSessionModel.schedule = schedule
		this.seerSchedulePrivateSessionModel.durationAndCost = durationAndCost

		if (this.operation == "create") {
			this.createPrivateSession()
		}
		else if (this.operation == "update") {
			this.updatePrivateSession(this.idPrivateSessionToEdit, this.seerSchedulePrivateSessionModel)
		}

		
	}


	addOrEditNewSeer() {
		if (this.nameNewSeer == null || this.nameNewSeer == "") {
			Swal.fire({
				icon: 'error',
				title: 'El nombre del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.surnameNewSeer == null || this.surnameNewSeer == "") {
			Swal.fire({
				icon: 'error',
				title: 'El/Los apellidos del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.emailNewSeer == null || this.emailNewSeer == "") {
			Swal.fire({
				icon: 'error',
				title: 'El email del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.operation == "create" && (this.seerImageSelected == null || this.seerImageSelected == "")) {
			Swal.fire({
				icon: 'error',
				title: 'La foto del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.operation == "create" && (this.passwordNewSeer == null || this.passwordNewSeer == "")) {
			Swal.fire({
				icon: 'error',
				title: 'La password del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.introductionNewSeer == null || this.introductionNewSeer == "") {
			Swal.fire({
				icon: 'error',
				title: 'La introducción del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}


		this.seerModel.name = this.nameNewSeer + " " + this.surnameNewSeer
		this.seerModel.firstName = this.nameNewSeer
		this.seerModel.lastName = this.surnameNewSeer
		this.seerModel.email = this.emailNewSeer

		if (this.passwordNewSeer != null) {
			let hashedPassword = shajs('sha256').update(this.passwordNewSeer).digest('hex')
			this.seerModel.password = hashedPassword
		}

		this.seerModel.provider = "NATIVE"
		this.seerModel.introduction = this.introductionNewSeer
		this.seerModel.allowPrivateSession = this.toggleAllowPrivateSession

		if (this.operation == "create") {
			this.createSeer()
		}
		else if (this.operation == "update") {
			this.updateSeer(this.idSeerToEdit, this.seerModel, false)
		}

	}

	deleteSeer(seer) {
		Swal.fire({
			title: '¿Eliminar Maestro?',
			text: "Una vez lo eliminas, está acción no se puede revertir.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar'
		})
			.then((result) => {
				if (result.isConfirmed) {
					this._seerManager.deleteSeer(seer).then(result => {
						if (result) {
							this._utils.redirectTo(this._router, "user-dashboard")
						}
					})

				}
			})
	}

	changeFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}
	processFileSeer(event) {
		const file = event.target.files[0]
		this.extension = event.target.files[0].name.split(".").pop()

		this._compressImageService.compress(file)
			.pipe(take(1))
			.subscribe(compressedImage => {
				this.seerImageSelected = compressedImage
			})
	}

	processFilePost(event) {
		if (event.target.value) {
			const file = event.target.files[0];
			const type = file.type;
			this.changeFile(file).then((base64: string): any => {
				this.imageNewPost = base64
			});
		}
		else {
			Swal.fire({
				icon: 'error',
				title: 'Ocurrió un problema subiendo la imagen del post',
				showConfirmButton: false,
				timer: 4000
			})
		}
	}

	createOrUpdateRoom() {
		if (this.nameNewRoom == null || this.nameNewRoom == "") {
			Swal.fire({
				icon: 'error',
				title: 'El nombre de la sala, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.seerNameNewRoom == undefined || this.seerNameNewRoom == "") {
			Swal.fire({
				icon: 'error',
				title: 'El vidente de la sala, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}


		if (this.idYouTubeChannelNewRoom == null || this.idYouTubeChannelNewRoom == "") {
			Swal.fire({
				icon: 'error',
				title: 'El ID de YouTube del canal del vidente, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.minimumAportationNewRoom == null) {
			Swal.fire({
				icon: 'error',
				title: 'La aportación mínima de la sala, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.timeToConsultNewRoom == null) {
			Swal.fire({
				icon: 'error',
				title: 'El tiempo de duración de consulta, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.starValueNewRoom == null) {
			Swal.fire({
				icon: 'error',
				title: 'El valor de cada estrella, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.minimumNumberMessagesToStart == null) {
			Swal.fire({
				icon: 'error',
				title: 'El valor del número mínimo de mensajes para comenzar, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.dateNewRoom == null || this.dateNewRoom == "") {
			Swal.fire({
				icon: 'error',
				title: 'La fecha de la sala, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.timeNewRoom == null || this.timeNewRoom == "") {
			Swal.fire({
				icon: 'error',
				title: 'La hora de la sala, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		this.roomModel.seerId = this.seerIdNewRoom
		this.roomModel.nameRoom = this.nameNewRoom
		this.roomModel.seerName = this.seerNameNewRoom
		this.roomModel.seerEmail = this.seerEmailNewRoom
		this.roomModel.seerPhotoPath = this.seerPhotoPath
		this.roomModel.idYouTubeChannel = this.idYouTubeChannelNewRoom
		this.roomModel.minimumAportation = this.minimumAportationNewRoom
		this.roomModel.starValue = this.starValueNewRoom
		this.roomModel.minimumNumberMessagesToStart = this.minimumNumberMessagesToStart
		this.roomModel.showDate = this.toggleShowDateValue
		this.roomModel.timeToConsult = (this.timeToConsultNewRoom * 60)

		let dateFormated = moment(this.dateNewRoom, 'YYYY-MM-DD')
			.format('DD-MM-YYYY')
		this.roomModel.dateRoom = dateFormated

		let timeFormated = moment(this.timeNewRoom, 'DD-MM-YYYY')
			.format('HH:mm')
		this.roomModel.timeRoom = timeFormated

		if (this.operation == "create") {
			this.createRoom()
		}
		else if (this.operation == "update") {
			this.updateRoom()
		}

	}

	createOrUpdatePromotion() {
		if (this.nameNewPromotion == null || this.nameNewPromotion == "") {
			Swal.fire({
				icon: 'error',
				title: 'El nombre de la promoción, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		if (this.valueNewPromotion == undefined || this.valueNewPromotion == 0) {
			Swal.fire({
				icon: 'error',
				title: 'El valor de la promoción, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}


		if (this.dateToNewPromotion == null || this.dateToNewPromotion == "") {
			Swal.fire({
				icon: 'error',
				title: 'La fecha de validez de la promoción, es un campo obligatorio',
				showConfirmButton: false,
				timer: 4000
			})

			return;
		}

		this.promotionModel.namePromotion = this.nameNewPromotion
		this.promotionModel.valuePromotion = this.valueNewPromotion
		this.promotionModel.promotionOnlyOneUse = this.togglePromotionOnlyOneUseValue

		let dateFormated = moment(this.dateToNewPromotion, 'YYYY-MM-DD')
			.format('DD-MM-YYYY')

		this.promotionModel.dateToPromotion = dateFormated

		if (this.operation == "create") {
			this.createPromotion()
		}
		else if (this.operation == "update") {
			this.updatePromotion(this.promotionModel)
		}

	}

	getRooms(email = "") {
		this._loadingService.show()

		this._roomManager.getAllRooms(email).then(result => {
			if (result) {
				this.seerRooms = result as RoomModel[]

				if (this.seerRooms.length > 0) {
					this.haveRooms = true
				}

				this._loadingService.hide()
			}
		})
	}

	getPromotions() {
		this._loadingService.show()

		this._promotionManager.getPromotions().then(result => {
			if (result) {
				this.promotions = result as PromotionsModel[]

				if (this.promotions.length > 0) {
					this.havePromotions = true
				}

				this._loadingService.hide()
			}
		})
	}

	getPrivateSessions(id = ""){
		this._loadingService.show()

		this._seerSchedulePrivateSessionManager.getSeerSchedulePrivateSessionBySeerId(id).then(result => {
			if (result) {
				this.seerScheduledPrivateSessions = result as SeerSchedulePrivateSessionModel[]
				
				if (this.seerScheduledPrivateSessions.length > 0) {
					this.havePrivateSessions = true
				}

				this._loadingService.hide()
			}
		})
	}

	isReadOnly() {
		if (this.currentUser.provider != "NATIVE") {
			return true
		}

		return false
	}

	cancelRoom(roomId) {
		Swal.fire({
			title: '¿Cancelar Sala?',
			text: "Una vez la canceles, está acción no se puede revertir.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar'
		})
			.then((result) => {
				if (result.isConfirmed) {
					this._roomManager.deleteRoomById(roomId).then(result => {
						if (result) {
							this._utils.redirectTo(this._router, "user-dashboard")

							this.haveRooms = false
							if (this.currentUser.rol == "3") {
								this.getRooms()
							}
							else if (this.currentUser.rol == "2") {
								this.getRooms(this.currentUser.email)
							}
						}
					})
				}
			})
	}

	cancelPromotion(promotionId) {
		Swal.fire({
			title: '¿Cancelar Promocion?',
			text: "Una vez la canceles, está acción no se puede revertir.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar'
		})
			.then((result) => {
				if (result.isConfirmed) {
					this._promotionManager.deletePromotionById(promotionId).then(result => {
						if (result) {
							this._utils.redirectTo(this._router, "user-dashboard")

							this.havePromotions = false
							this.getPromotions()
						}
					})
				}
			})
	}

	cancelPrivateSession(privateSessionId) {
		Swal.fire({
			title: '¿Cancelar Sala Privada?',
			text: "Una vez la canceles, está acción no se puede revertir.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar'
		})
			.then((result) => {
				if (result.isConfirmed) {
					this._seerSchedulePrivateSessionManager.deletePrivateSessionById(privateSessionId).then(result => {
						if (result) {
							this._utils.redirectTo(this._router, "user-dashboard")
						}
					})
				}
			})
	}

	getProfileImage() {

		if (this.currentUser.photoPath == "" || this.currentUser.photoPath == undefined) {
			return "/assets/img/no-avatar.png"
		}
		else {
			if (!this._utils.getIfUserIsSocial(this.currentUser)) {
				return this.currentUser.photoURL = this.storageAccountImagesPath.concat(this.currentUser.photoPath)
			}
		}

		return this.currentUser.photoPath
	}

	getAllSeers() {
		this._seerManager.getAllSeers().then(result => {
			if (result) {
				this.seers = result as SeerModel[]

				if (this.seers.length > 0) {
					this.haveSeers = true
				}
			}
		})
	}

	fillSeerEmail() {
		if (this.seerDataNewRoom != undefined && this.seerDataNewRoom != null) {
			this.seerIdNewRoom = this.seerDataNewRoom.id
			this.seerNameNewRoom = this.seerDataNewRoom.name
			this.seerEmailNewRoom = this.seerDataNewRoom.email
			this.seerPhotoPath = this.seerDataNewRoom.photoPath

			return
		}

		this.seerIdNewRoom = ""
		this.seerNameNewRoom = ""
		this.seerEmailNewRoom = ""
		this.seerPhotoPath = ""
	}

	getBilling(idSession) {
		this._sessionManager.getSessionById(idSession).then(result => {
			if (result) {
				let sessionData: SessionsModel = result as SessionsModel

				if (sessionData.dateEnd == undefined) {
					Swal.fire({
						icon: 'error',
						title: 'La sesión está actualmente activa. No se puede generar el documento hasta que finalice.',
						showConfirmButton: false,
						timer: 4000
					})

					return
				}

				let sessionCreditConsolidatedDates = {
					dateStart: sessionData.dateStart,
					dateEnd: sessionData.dateEnd
				}

				this._sessionCreditConsolidatedManager.getCreditConsolidatedBetweenDates(sessionCreditConsolidatedDates).then(result => {
					if (result) {
						let sessionCreditConsolidatedData: SessionCreditConsolidatedModel[] = result as SessionCreditConsolidatedModel[]

						if (sessionCreditConsolidatedData.length > 0) {
							this._excelService.generateExcelForBilling(sessionData.dateEnd, sessionData.nameSession, sessionCreditConsolidatedData)
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'No existe crédito consolidado para esta sesión',
								showConfirmButton: false,
								timer: 4000
							})

							return
						}
					}
				})

			}
		})
	}

	getRedeemedPromotionsInSession(idSession) {
		this._sessionManager.getSessionById(idSession).then(result => {
			if (result) {
				let sessionData: SessionsModel = result as SessionsModel

				if (sessionData.dateEnd == undefined) {
					Swal.fire({
						icon: 'error',
						title: 'La sesión está actualmente activa. No se puede generar el documento hasta que finalice.',
						showConfirmButton: false,
						timer: 4000
					})

					return
				}

				this._redeemedPromotionsManager.getRedeemPromotionsBySessionId(idSession).then(result => {
					if (result) {
						let redeemedPromotionsData: RedeemedPromotionsModel[] = result as RedeemedPromotionsModel[]

						if (redeemedPromotionsData.length > 0) {
							this._excelService.generateExcelForRedeemedPromotions(sessionData.dateEnd, sessionData.nameSession, redeemedPromotionsData)
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'No existen utilizaciones de promociones para esta sesión',
								showConfirmButton: false,
								timer: 4000
							})

							return
						}
					}
				})
			}
		})
	}

	deletePost(idPost) {
		Swal.fire({
			title: "¿Eliminar Post?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar'
		})
			.then((result) => {
				if (result.isConfirmed) {
					this._postsManager.deletePost(idPost).then(result => {
						if (result) {
							this._utils.redirectTo(this._router, "user-dashboard")
						}
					})
				}
			})

	}

	updatePreferences() {
		this.updateUser()
	}

	selectDay(index, day) {

	}

	openModalSelectHourForDay(modalSelectHourForDay, index, day) {
		this.daySelected = day
		this.daySelectedIndex = index

		if (typeof this.selectedHours[this.daySelectedIndex] !== 'undefined') {
			this.hourSelectedByDay = this.selectedHours[this.daySelectedIndex]
		}
		else {
			this.hourSelectedByDay = []
		}

		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			ariaLabelledBy: 'modal-basic-title'
		};

		this._modalService.open(modalSelectHourForDay, ngbModalOptions).result.then((result) => {
			if (result == "OK") {
				this.selectedHours[this.daySelectedIndex] = this.hourSelectedByDay
			}
		})
	}

	selectHourForPrivateSession(hour) {
		console.log("value: ", this.hourSelectedByDay)
		this.hourSelectedByDay.push(hour)
	}

	logOut(): void {
		this._cookieService.delete('User', '/', environment.SiteDomainForPersistCookies, false, "Lax")
		this._cookieService.delete('Type', '/', environment.SiteDomainForPersistCookies, false, "Lax")

		this._socialAuthService.signOut();

		NavbarComponent.typeUser = null
		NavbarComponent.currentUser = null
		NavbarComponent.userIsLogged = false

		this._utils.redirectTo(this._router, "home")
	}

	private createSeer() {
		this._seerManager.registerSeer(this.seerModel).then(result => {
			if (result) {
				let seerData = result as SeerModel
				this.uploadImageSeerToAzure(seerData.id)
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private createRoom() {
		this._roomManager.createRoom(this.roomModel).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private updateRoom(roomDataToUpdate = this.roomModel) {
		if (this.idRoomToEdit == "") {
			Swal.fire({
				icon: 'error',
				title: "Ocurrió un error al actualizar la sala. Por favor, inténtalo de nuevo.",
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		this._roomManager.updateRoom(this.idRoomToEdit, roomDataToUpdate).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private createPost() {
		this._postsManager.createPost(this.postsModel).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private updatePost(idPost, postData) {
		if (idPost == "") {
			Swal.fire({
				icon: 'error',
				title: "Ocurrió un error al actualizar el post. Por favor, inténtalo de nuevo.",
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		this._postsManager.updatePost(idPost, postData).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private createPrivateSession() {
		this._seerSchedulePrivateSessionManager.createSeerSchedulePrivateSession(this.seerSchedulePrivateSessionModel).then((seerSchedulePrivateSeesionResponse: SeerSchedulePrivateSessionModel) => {
			if (seerSchedulePrivateSeesionResponse) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
			else {
				Swal.fire({
					icon: 'error',
					title: "Ocurrió un error guardando la sesión privada del maestro. Por favor, inténtalo de nuevo.",
					showConfirmButton: false,
					timer: 4000
				})

				return
			}
		})
	}

	private updatePrivateSession(idPrivateSession, privateSessionData) {
		if (idPrivateSession == "") {
			Swal.fire({
				icon: 'error',
				title: "Ocurrió un error al actualizar la sala privada. Por favor, inténtalo de nuevo.",
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		this._seerSchedulePrivateSessionManager.updatePrivateSession(idPrivateSession, privateSessionData).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}


	private createSession(session, stateRoom) {
		this._sessionManager.createSession(session).then(result => {
			if (result) {
				let sessionData: SessionsModel = result as SessionsModel

				let roomToEdit: RoomModel = new RoomModel()
				roomToEdit.sessionId = sessionData.id
				roomToEdit.state = stateRoom

				this.updateRoom(roomToEdit)
			}
		})
	}

	private updateSession(session, stateRoom) {
		this._sessionManager.updateSession(this.idSessionToEdit, session).then(result => {
			if (result) {
				let roomToEdit: RoomModel = new RoomModel()
				roomToEdit.state = stateRoom

				this.updateRoom(roomToEdit)
			}
		})
	}

	private getSessions() {
		this._sessionManager.getSessions().then(result => {
			if (result) {
				this.sessions = result as SessionsModel[]

				if (this.sessions.length > 0) {
					this.haveSessions = true

					this.sessions.forEach((currentSession: SessionsModel) => {
						if (currentSession.dateStart != undefined) {
							currentSession.dateStart = this._utils.formatDate(currentSession.dateStart)
						}

						if (currentSession.dateEnd != undefined) {
							currentSession.dateEnd = this._utils.formatDate(currentSession.dateEnd)
						}
					})
				}
			}
		})
	}

	private createPromotion() {
		this._promotionManager.createPromotion(this.promotionModel).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private updatePromotion(promotionData) {
		if (this.idPromotionToEdit == "") {
			Swal.fire({
				icon: 'error',
				title: "Ocurrió un error al actualizar la promoción. Por favor, inténtalo de nuevo.",
				showConfirmButton: false,
				timer: 4000
			})

			return
		}

		this._promotionManager.updatePromotion(this.idPromotionToEdit, promotionData).then(result => {
			if (result) {
				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private getAllPosts() {
		this._loadingService.show()

		this._postsManager.getAllPosts().then(result => {
			if (result) {
				this.posts = result as PostsModel[]

				if (this.posts.length > 0) {
					this.havePosts = true
				}

				this._loadingService.hide()
			}
		})
	}

	private updateUser() {
		this._userManager.updateUser(this.currentUser.id, this.currentUser).then(result => {
			if (result) {
				let userData = result as UserModel

				Swal.fire({
					icon: 'success',
					title: userData.firstName + ', sus datos han sido actualizados correctamente',
					showConfirmButton: false,
					timer: 4000
				})

				this._cookieService.set('User', JSON.stringify(userData), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax");
				this._utils.redirectTo(this._router, "user-dashboard")

			}
		})
	}

	private updateSeer(seerId, seerData = this.currentUser, own = true) {

		if (this.seerImageSelected != null && this.seerImageSelected != "") {
			{
				this.uploadImageSeerToAzure(seerId)
			}
		}

		this._seerManager.updateSeer(seerId, seerData).then(result => {
			if (result) {
				Swal.fire({
					icon: 'success',
					title: this.currentUser.firstName + ', sus datos han sido actualizados correctamente',
					showConfirmButton: false,
					timer: 4000
				})

				let userData = result as SeerModel

				if (own) {
					this._cookieService.set('User', JSON.stringify(userData), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax");
				}

				this._utils.redirectTo(this._router, "user-dashboard")
			}
		})
	}

	private uploadImageSeerToAzure(userId) {

		const Config: UploadParams = {
			sas: environment.AzureSASTokenImage,
			storageAccount: environment.AzureStorageAccountName,
			containerName: environment.BlobContainerImage
		}

		const fileName = userId.concat(".").concat(this.extension)

		const baseUrl = this._blobService.generateBlobUrl(Config, fileName)

		this.uploadConfig = {
			baseUrl: baseUrl,
			sasToken: Config.sas,
			file: this.seerImageSelected,
			complete: () => {
				let seerData = {
					photoPath: fileName
				}

				this._seerManager.updateSeer(userId, seerData).then(result => {
					if (result) {
						this._loadingService.hide()
						this._router.navigate(["/login"])
					}
				})
			},
			error: (err) => {
				console.log('Error:', err);
				Swal.fire({
					icon: 'error',
					title: 'Ocurrió algun error guardando la imagen. Inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es',
					showConfirmButton: false,
					timer: 4000
				})
			}
		}

		this._blobService.upload(this.uploadConfig);
	}

	private getHoursOfDayInSlot() {
		this.allTimesToBookSession = []

		let x = {
			slotInterval: 30,
			openTime: '00:00',
			closeTime: '00:00'
		};

		let startTime = moment(x.openTime, "HH:mm")

		let endTime = moment(x.closeTime, "HH:mm").add(1, 'days')

		while (startTime < endTime) {
			this.allTimesToBookSession.push(startTime.format("HH:mm"))
			startTime.add(x.slotInterval, 'minutes')
		}
	}
}