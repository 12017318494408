import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class RedeemedPromotionsBackendService {
    public httpOptions: HttpHeaders
    private _redeemedPromotionsEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._redeemedPromotionsEndPoint = environment.APIEndPointBackend + environment.RedeemedPromotions
    }

    /* REDEEM PROMOTION */
    public redeemPromotion(promotionData): Observable<any> {

        return this._httpClient.post(this._redeemedPromotionsEndPoint, promotionData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when redeem promotion: ", error)
                    return error
                })
            )
    }

    /* GET REDEEM PROMOTIONs BY SESSION ID */
    public getRedeemPromotionsBySessionId(idSession): Observable<any> {

        return this._httpClient.get(this._redeemedPromotionsEndPoint + idSession)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get redeem promotions by sessionId: ", error)
                    return error
                })
            )
    }

}