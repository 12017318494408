import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class MessagingBackendService {
  public httpOptions: HttpHeaders
  private _messagingEndPoint: string

  constructor(
    public _httpClient: HttpClient
  ) {
    this._messagingEndPoint = environment.APIEndPointBackend + environment.Messaging
  }

  /* SEND PHONE VERIFICATION SMS */
  public sendPhoneVerificationSMS(dataToSendPhoneVerificationSMS): Observable<any> {
    return this._httpClient.post(this._messagingEndPoint + environment.SendPhoneVerificationSMS, dataToSendPhoneVerificationSMS)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Send Phone Verification SMS problem: ", error)
          return error
        })
      )
  }
}