import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { PostsBackendService } from '../services/posts-backend.service'

@Injectable()

export class PostsManager {

    private postsAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _postsBackendService: PostsBackendService
    ) { }

    ngOnDestroy() {
        if (this.postsAPI != null) {
            this.postsAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getAllPosts() {
        return new Promise((resolve) => {
            this.postsAPI = this._postsBackendService.getAllPosts()
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("All Posts retrieved successfully")

                        resolve(response.posts_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    getLatestPosts() {
        return new Promise((resolve) => {
            this.postsAPI = this._postsBackendService.getLatestPost()
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Latest Posts retrieved successfully")

                        resolve(response.posts_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    createPost(post) {
        return new Promise((resolve) => {
            this.postsAPI = this._postsBackendService.createPost(post)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("New Post is Created Successfully")

                        Swal.fire({
                            icon: 'success',
                            title: "Nuevo post creado correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(response.posts_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    updatePost(idPost, postData) {
        return new Promise((resolve) => {
            this.postsAPI = this._postsBackendService.updatePost(idPost, postData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        let post = response.posts_data
                        console.log("Post is Updated Successfully")

                        Swal.fire({
                            icon: 'success',
                            title: "Post actualizado correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(post)    
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    getPostById(idPost) {
        return new Promise((resolve) => {
            this.postsAPI = this._postsBackendService.getPostById(idPost)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        let post = response.posts_data
                        console.log("Post retrieved Successfully")

                        resolve(post)    
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    deletePost(idPost) {
        return new Promise((resolve) => {
            this.postsAPI = this._postsBackendService.deletePost(idPost)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Post deleted Successfully")

                        Swal.fire({
                            icon: 'success',
                            title: "Post eliminado correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)    
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }
}