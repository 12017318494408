// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  SiteDomainForCookies: "totonline.es", // Local localhost Azure DEV: totonline.es
  SiteDomainForPersistCookies: ".totonline.es", 
  APIEndPointBackend: "https://tot-server.azurewebsites.net/api/", // Local http://localhost:8080/api/ Azure DEV: https://tot-server.azurewebsites.net/api/
  ServerSocket: 'https://tot-server.azurewebsites.net', // Local http://localhost:8080 Azure DEV: https://tot-server.azurewebsites.net 
  OnlineSessionUrl: "https://online-session.totonline.es",
  YouTubeStreamingUrl: "https://www.youtube.com/embed/live_stream?channel=",
  GCPApiKey: "AIzaSyC8ISC9Is83ep6eBZYuNKWDZSZgiJi8Q-g",
  YouTubeAPIToSearchEndPoint: "https://www.googleapis.com/youtube/v3/search?key=AIzaSyB02-bZCBA7JEO_ANL66JC8XHA5Edx3kIA&channelId=",
  GTMId: "GTM-K9339SH",
  LiveRoom: "live-room/",
  Users: "users/",
  Seers: "seers/",
  Rooms: "rooms/",
  Zoom: "zoom/",
  Azure: "azure/",
  OAuth2: "oauth2/",
  Donation: "donation/",
  Email: "email/",
  Promotions: "promotions/",
  Sessions: "sessions/",
  Posts: "posts/",
  Messaging: "messaging/",
  SessionCreditConsolidated: "session-credit-consolidated/",
  SeerSchedulePrivateSession: "seer-schedule-private-session/",
  PrivateSession: "private-session/",
  GetAllSeerSchedulePrivateRooms: "get-seer-schedule-private-session-by-id/",
  RedeemedPromotions: "redeemed-promotions/",
  GetRoomsByEmail: "get-rooms-by-email/",
  GetAllRooms: "get-all-rooms/",
  GetSocialUser: "get-social-user/",
  Login: "login/",
  RegisterUser: "register-user/",
  RegisterSocialUser: "register-social-user/",
  GetUserData: "get-user-data/",
  UpdateDonateLiveRoomUser: "update-donate/",
  DonateFromThird: "donate-from-third/",
  GetLiveRoomUser: "get-live-room-user/",
  GetLiveRoomUserByIdRoom: "get-live-room-user-by-id-room/",
  UpdateNickname: "update-nickname/",
  AzureGetSASToken: "sas-token/",
  UpdateUserCredit: "update-user-credit/",
  SendEmailRecoverPassword: "send-email-recover-password/",
  GetPromotionByName: "get-promotion/",
  GetSessionById: "get-session-by-id/",
  GetDonationsBetweenDates: "get-donations-between-dates/",
  GetSessionCreditConsolidatedBetweenDates: "get-session-credit-consolidated-between-dates/",
  GetPost: "get-post/",
  GetPrivateSessionHoursScheduledByDate: "get-private-sessions-hours-scheduled-by-date/",
  GetLatestPosts: "get-latest-posts",
  SendEmailBookedPrivateSession: "send-email-booked-private-session/",
  SendPhoneVerificationSMS: "send-phone-verification-sms/",
  ClientId: "3a365853-0afc-4f99-9370-a750a2caa819",
  AzureStorageAccountPath: "https://totdevsa01.blob.core.windows.net/",
  AzureStorageAccountName: "totdevsa01",
  BlobContainerAudio: "users-audio",
  BlobContainerImage: "users-image",
  AzureSASTokenAudio: "?sp=racwdl&st=2021-10-28T09:25:02Z&se=2040-12-31T18:25:02Z&sv=2020-08-04&sr=c&sig=HfFhu0g%2Bs9kluOG%2F5KxAqDbz%2BLqcyJlpQSdt1HcdHdg%3D", // Restringir por IP al subir a Azure. Caduca el 31 de diciembre de 2040
  AzureSASTokenImage: "?sp=racwl&st=2022-04-11T15:17:08Z&se=2040-12-30T00:17:08Z&spr=https&sv=2020-08-04&sr=c&sig=2yIQ0%2FKeU%2FPPUSdLQXfETtU5Pkju7lnu%2BSypL8%2Br44A%3D", // Restringir por IP al subir a Azure. Caduca el 31 de diciembre de 2040
  BlobSuffix: "blob.core.windows.net",
  FBAppId: "2190377561128055",
  GoogleAppId: "399728730991-pf97q09sv36j6e1i1jav7e557budf0kf.apps.googleusercontent.com",
  PayPalClientId: "AcnaYFWVvXzhRIUhKtL_01utHZaY5xF1fbxW5VRs1BpVcmZaWJX28_3Up7ek0I9ZJlPQdBGXcsXpN8HV",
  /* Firebase Config */
  apiKey: "AIzaSyC8ISC9Is83ep6eBZYuNKWDZSZgiJi8Q-g",
  authDomain: "tot-online.firebaseapp.com",
  projectId: "tot-online",
  storageBucket: "tot-online.appspot.com",
  messagingSenderId: "53655080857",
  appId: "1:53655080857:web:d853a163ffd88aabc84d08"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
