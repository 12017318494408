<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Noticia de blog</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Noticia de blog</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-item">
                    <div class="blog-details-img-container" *ngIf="postData.image">
                        <div class="blog-details-image" [ngStyle]="{'background-image': 'url(' + postData.image + ')'}"
                            alt="Blog"></div>
                    </div>


                    <share-buttons class="share-buttons" [theme]="'material-dark'"
                        [include]="['facebook','twitter','linkedin','pinterest','telegram','whatsapp','sms','email','copy']"
                        [show]="9" [size]="1" [url]="blogURL"
                        [image]="postData.image"
                        [autoSetMeta]="false"></share-buttons>

                    <div class="blog-details-content">
                        <ul>
                            <li>{{postData.date | date: 'dd/MM/yyyy'}}</li>
                        </ul>
                        <h3>{{postData.title}}</h3>
                        <div [innerHTML]="postData.text | safeHtml"></div>
                    </div>
                    <!-- <div class="work-details-quote">
                        <i class='bx bxs-quote-left'></i>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab cumque iure reiciendis maiores ipsum itaque placeat rem modi saepe minima sit eveniet officiis magni culpa, impedit explicabo non assumenda.</p>
                        <div class="link">
                            <a href="#">- Tom Henry</a>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>