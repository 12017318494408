<div class="page-title-area">
  <div class="container">
    <div class="page-title-item">
      <h2>Iniciar sesión</h2>
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><span></span></li>
        <li>Iniciar sesión</li>
      </ul>
    </div>
  </div>
</div>

<section class="login-area login-area-two pt-100 pb-70">
  <div class="login-container">
    <div *ngIf="isLoggedin === false">
      <form id="contactForm">
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="text" [(ngModel)]="inputEmail" name="email" class="form-control" placeholder="Email">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="password" name="password" [(ngModel)]="inputPassword" class="form-control"
                placeholder="Password">
            </div>
          </div>
        </div>

      </form>

      <div class="social-buttons-container">
        <a _ngcontent-ukw-c68="" _ngcontent-nrn-c16="" (click)="doNativeLogin()"
          class="template-btn btn-black width-100 text-align-center">Iniciar sesión</a>
        <a _ngcontent-ukw-c68="" _ngcontent-nrn-c16="" (click)="doRegister()"
          class="template-btn btn-black width-100 text-align-center btn-register">Registrarse</a>
        <div class="social-login-title">O, inicia sesión mediante:</div>
        <button type="button" (click)="loginWithGoogle()" class="btn btn-danger btn-social-login"><i
            class="fa fa-google" aria-hidden="true"></i> Login con Google</button>
        <!--<button type="button" (click)="loginWithFacebook()" class="btn btn-primary btn-social-login"><i
            class="fa fa-facebook" aria-hidden="true"></i> Login con Facebook</button> -->
        <div class="social-login-title">¿Has olvidado tu contraseña?. Pulsa <a routerLink="/recover-password">aquí</a>
          para reestablecerla</div>
      </div>
    </div>
  </div>
</section>