<div class="banner-area-two banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <p>Los mejores tarotistas</p>
                    <h1>A tu alcance</h1>
                    <a class="banner-btn" routerLink="/seers-list">Listado de maestros</a><br>
                    <a class="template-btn btn-black mt20" style="margin-right: 0 !important"
                        routerLink="/sessions">Listado de salas</a><br>
                    <a *ngIf="!isUserLogged" class="template-btn btn-gold mt20" style="margin-right: 0 !important"
                        routerLink="/register">Registrate</a>
                    <a *ngIf="isUserLogged" class="template-btn btn-gold mt20" style="margin-right: 0 !important"
                        routerLink="/book-private-session">Reserva Consulta privada</a>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-social">
        <span>Redes Sociales</span>

        <ul>
            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
        </ul>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="team-area team-area-two team-area-three pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Nuestros tarotistas</span>
            <h2>Videntes en sesiones</h2>
        </div>

        <ng-container *ngIf="seersIsAvailable">
            <div class="row">
                <ng-container *ngFor="let seer of seers">
                    <div class="col-sm-6 col-lg-3">
                        <a href="/sessions">
                            <div class="team-item">
                                <div class="team-top">
                                    <div class="tarot-people-images"
                                        [ngStyle]="{'background-image': 'url(' + storageAccountImagesPath + seer.photoPath + ')'}"
                                        alt="{{seer.name}}">
                                    </div>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                        <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                                    </ul>
                                </div>
                                <div class="team-bottom">
                                    <h3>{{seer.name}}</h3>
                                    <span>Maestro Espiritual</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</section>

<section class="service-area service-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">¿Que ofrecemos?</span>
            <h2>Nuestros servicios</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-theater'></i>
                    <div class="service-inner">
                        <h3>Tarotistas reputados</h3>
                        <p>Contamos con los mejores tarotistas para resolver tus dudas </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-camera-2'></i>
                    <div class="service-inner">
                        <h3>Vídeo Streaming</h3>
                        <p>Motor de streaming que ofrece calidad y velocidad en las sesiones con el vidente </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-camera-1'></i>
                    <div class="service-inner">
                        <h3>Modelo de participación</h3>
                        <p>En las sesiones en vivo se ofrece un modelo de participación basado en donativos </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="blog-area blog-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Últimas noticias</h2>
        </div>

        <div class="row">
            <ng-container *ngFor="let post of posts">
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item">
                        <a href="/blog-details?id={{post.id}}">
                            <div class="blog-image" [ngStyle]="{'background-image': 'url(' + post.image + ')'}"
                                alt="Blog"></div>
                        </a>
                        <div class="blog-bottom">
                            <span>{{post.date | date: 'dd/MM/yyyy'}}</span>
                            <h3><a href="/blog-details?id={{post.id}}">{{post.title}}</a></h3>
                            <p>{{post.subtitle}}</p>
                            <a class="blog-btn" href="/blog-details?id={{post.id}}">Leer más</a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>