<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Aviso Legal</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Aviso Legal</li>
            </ul>
        </div>
    </div>
</div>

<section class="legal-advice-area pt-100">
    <div class="container">

        <h2 style="text-align:center;">Aviso legal</h2>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div style="text-align: justify;"></div>

        <div style="text-align: center;"><span ><strong>LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA
                    INFORMACI&Oacute;N (LSSI)</strong></span></div>

        <div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <p style="text-align: justify;"><span >DAVID LINARES GALÁN, responsable del sitio web,
                    en adelante RESPONSABLE, pone a disposici&oacute;n de los usuarios el presente documento, con el que
                    pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de
                    Servicios de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico (LSSICE), BOE
                    N.&ordm; 166, as&iacute; como informar a todos los usuarios del sitio web respecto a cu&aacute;les
                    son las condiciones de uso.</span></p>

            <div style="text-align: justify;"><span >Toda persona que acceda a este sitio web
                    asume el papel de usuario, comprometi&eacute;ndose a la observancia y cumplimiento riguroso de las
                    disposiciones aqu&iacute; dispuestas, as&iacute; como a cualquier otra disposici&oacute;n legal que
                    fuera de aplicaci&oacute;n. </span></div>

            <p style="text-align: justify;"><span >DAVID LINARES GALÁN se reserva el derecho de
                    modificar cualquier tipo de informaci&oacute;n que pudiera aparecer en el sitio web, sin que exista
                    obligaci&oacute;n de preavisar o poner en conocimiento de los usuarios dichas obligaciones,
                    entendi&eacute;ndose como suficiente la publicaci&oacute;n en el sitio web de DAVID LINARES
                    GALÁN.</span></p>

            <p><span ><br />
                    <strong>1. DATOS IDENTIFICATIVOS</strong></span></p>

            <p><span >Nombre de dominio: <a href="http://totonline.es/"
                        target="_blank">totonline.es/</a><br />
                    Nombre comercial: TOT ONLINE<br />
                    Denominaci&oacute;n social: DAVID LINARES GALÁN<br />
                    NIF: 52349967N<br />
                    Domicilio social: C/ SANTIAGO, 84 28822 COSLADA (Madrid)<br />
                    Tel&eacute;fono: 648168213<br />
                    E-mail: contacto@totonline.es<br />
                </span></p>
        </div>

        <div></div>

        <div><span ><strong>2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></span>
        </div>

        <div></div>

        <div><span >El sitio web, incluyendo a t&iacute;tulo enunciativo pero no limitativo su
                programaci&oacute;n, edici&oacute;n, compilaci&oacute;n y dem&aacute;s elementos necesarios para su
                funcionamiento, los dise&ntilde;os, logotipos, texto y/o gr&aacute;ficos, son propiedad del RESPONSABLE
                o, si es el caso, dispone de licencia o autorizaci&oacute;n expresa por parte de los autores. Todos los
                contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad intelectual
                e industrial, as&iacute; como inscritos en los registros p&uacute;blicos correspondientes. </span><span
                ></span></div>

        <div><span >Independientemente de la finalidad para la que fueran destinados, la
                reproducci&oacute;n total o parcial, uso, explotaci&oacute;n, distribuci&oacute;n y
                comercializaci&oacute;n, requiere en todo caso la autorizaci&oacute;n escrita previa por parte del
                RESPONSABLE. Cualquier uso no autorizado previamente se considera un incumplimiento grave de los
                derechos de propiedad intelectual o industrial del autor. </span><span ></span>
        </div>

        <div><span >Los dise&ntilde;os, logotipos, texto y/o gr&aacute;ficos ajenos al RESPONSABLE
                y que pudieran aparecer en el sitio web, pertenecen a sus respectivos propietarios, siendo ellos mismos
                responsables de cualquier posible controversia que pudiera suscitarse respecto a los mismos. El
                RESPONSABLE autoriza expresamente a que terceros puedan redirigir directamente a los contenidos
                concretos del sitio web, y en todo caso redirigir al sitio web principal de <a
                    href="http://totonline.es/" target="_blank">totonline.es/</a>. </span><span >
            </span></div>

        <div><span >El RESPONSABLE reconoce a favor de sus titulares los correspondientes derechos
                de propiedad intelectual e industrial, no implicando su sola menci&oacute;n o aparici&oacute;n en el
                sitio web la existencia de derechos o responsabilidad alguna sobre los mismos, como tampoco respaldo,
                patrocinio o recomendaci&oacute;n por parte del mismo.</span></div>

        <div><span >Para realizar cualquier tipo de observaci&oacute;n respecto a posibles
                incumplimientos de los derechos de propiedad intelectual o industrial, as&iacute; como sobre cualquiera
                de los contenidos del sitio web, puede hacerlo a trav&eacute;s del correo electr&oacute;nico
                contacto@totonline.es.</span><br />
        </div>

        <div style="text-align: justify;"><span ><strong>3. EXENCI&Oacute;N DE
                    RESPONSABILIDADES</strong></span></div>

        <div style="text-align: justify;"></div>

        <div style="text-align: justify;"><span >El RESPONSABLE se exime de cualquier tipo de
                responsabilidad derivada de la informaci&oacute;n publicada en su sitio web siempre que no tenga
                conocimiento efectivo de que esta informaci&oacute;n haya sido manipulada o introducida por un tercero
                ajeno al mismo o, si lo tiene, haya actuado con diligencia para retirar los datos o hacer imposible el
                acceso a ellos.</span></div>

        <div style="text-align: justify;"></div>

        <div style="text-align: justify;"><span ><strong>Uso de Cookies</strong></span></div>

        <div style="text-align: justify;"><span >Este sitio web puede utilizar cookies
                t&eacute;cnicas (peque&ntilde;os archivos de informaci&oacute;n que el servidor env&iacute;a al
                ordenador de quien accede a la p&aacute;gina) para llevar a cabo determinadas funciones que son
                consideradas imprescindibles para el correcto funcionamiento y visualizaci&oacute;n del sitio. Las
                cookies utilizadas tienen, en todo caso, car&aacute;cter temporal, con la &uacute;nica finalidad de
                hacer m&aacute;s eficaz la navegaci&oacute;n, y desaparecen al terminar la sesi&oacute;n del usuario. En
                ning&uacute;n caso, estas cookies proporcionan por s&iacute; mismas datos de car&aacute;cter personal y
                no se utilizar&aacute;n para la recogida de los mismos. </span><span ></span>
        </div>

        <div style="text-align: justify;"><span >Mediante el uso de cookies tambi&eacute;n es
                posible que el servidor donde se encuentra la web reconozca el navegador utilizado por el usuario con la
                finalidad de que la navegaci&oacute;n sea m&aacute;s sencilla, permitiendo, por ejemplo, el acceso de
                los usuarios que se hayan registrado previamente a las &aacute;reas, servicios, promociones o concursos
                reservados exclusivamente a ellos sin tener que registrarse en cada visita. Tambi&eacute;n se pueden
                utilizar para medir la audiencia, par&aacute;metros de tr&aacute;fico, controlar el progreso y
                n&uacute;mero de entradas, etc., siendo en estos casos cookies prescindibles t&eacute;cnicamente, pero
                beneficiosas para el usuario. Este sitio web no instalar&aacute; <i>cookies</i> prescindibles sin el
                consentimiento previo del usuario.</span></div>

        <div style="text-align: justify;"><span >Este sitio web utiliza cookies anal&iacute;ticas
                para habilitar la funci&oacute;n de control de visitas &uacute;nicas con el fin de facilitarle su
                navegaci&oacute;n. A todo usuario que visita la web se le informa del uso de estas cookies mediante un
                banner flotante, considerando que si acepta el tratamiento est&aacute; de acuerdo con su uso. En el caso
                de aceptar su uso, el banner desaparecer&aacute;, aunque en todo momento podr&aacute; revocar el
                consentimiento y obtener m&aacute;s informaci&oacute;n consultando nuestra Pol&iacute;tica de
                cookies.</span></div>

        <div style="text-align: justify;"><span ></span><span >El usuario
                tiene la posibilidad de configurar su navegador para ser alertado de la recepci&oacute;n de cookies y
                para impedir su instalaci&oacute;n en su equipo. Por favor, consulte las instrucciones de su navegador
                para ampliar esta informaci&oacute;n.</span><br />
        </div>

        <div style="text-align: justify;"><span ><strong>Pol&iacute;tica de
                    enlaces</strong></span><span ></span></div>

        <div style="text-align: justify;"><span >Desde el sitio web, es posible que se redirija a
                contenidos de terceros sitios web. Dado que el RESPONSABLE no puede controlar siempre los contenidos
                introducidos por terceros en sus respectivos sitios web, no asume ning&uacute;n tipo de responsabilidad
                respecto a dichos contenidos. En todo caso, proceder&aacute; a la retirada inmediata de cualquier
                contenido que pudiera contravenir la legislaci&oacute;n nacional o internacional, la moral o el orden
                p&uacute;blico, procediendo a la retirada inmediata de la redirecci&oacute;n a dicho sitio web, poniendo
                en conocimiento de las autoridades competentes el contenido en cuesti&oacute;n. </span><span
                ></span></div>

        <div style="text-align: justify;"><span >El RESPONSABLE no se hace responsable de la
                informaci&oacute;n y contenidos almacenados, a t&iacute;tulo enunciativo pero no limitativo, en foros,
                chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio que permita a terceros
                publicar contenidos de forma independiente en la p&aacute;gina web del RESPONSABLE. Sin embargo, y en
                cumplimiento de lo dispuesto en los art&iacute;culos 11 y 16 de la LSSICE, se pone a disposici&oacute;n
                de todos los usuarios, autoridades y fuerzas de seguridad, colaborando de forma activa en la retirada o,
                en su caso, bloqueo de todos aquellos contenidos que puedan afectar o contravenir la legislaci&oacute;n
                nacional o internacional, los derechos de terceros o la moral y el orden p&uacute;blico. En caso de que
                el usuario considere que existe en el sitio web alg&uacute;n contenido que pudiera ser susceptible de
                esta clasificaci&oacute;n, se ruega lo notifique de forma inmediata al administrador del sitio web.
            </span><span ></span></div>

        <div style="text-align: justify;"><span >Este sitio web se ha revisado y probado para que
                funcione correctamente. En principio, puede garantizarse el correcto funcionamiento los 365 d&iacute;as
                del a&ntilde;o, 24 horas al d&iacute;a. Sin embargo, el RESPONSABLE no descarta la posibilidad de que
                existan ciertos errores de programaci&oacute;n, o que acontezcan causas de fuerza mayor,
                cat&aacute;strofes naturales, huelgas o circunstancias semejantes que hagan imposible el acceso a la
                p&aacute;gina web.</span><br />
        </div>

        <div style="text-align: justify;"><span ><strong>Direcciones IP</strong></span></div>

        <div style="text-align: justify;"><span >Los servidores del sitio web podr&aacute;n
                detectar de manera autom&aacute;tica la direcci&oacute;n IP y el nombre de dominio utilizados por el
                usuario. Una direcci&oacute;n IP es un n&uacute;mero asignado autom&aacute;ticamente a un ordenador
                cuando este se conecta a Internet. Toda esta informaci&oacute;n se registra en un fichero de actividad
                del servidor debidamente inscrito que permite el posterior procesamiento de los datos con el fin de
                obtener mediciones &uacute;nicamente estad&iacute;sticas que permitan conocer el n&uacute;mero de
                impresiones de p&aacute;ginas, el n&uacute;mero de visitas realizadas a los servidores web, el orden de
                visitas, el punto de acceso, etc.</span></div>

        <div style="text-align: justify;"></div>

        <div style="text-align: justify;"><span ><strong>4. LEY APLICABLE Y
                    JURISDICCI&Oacute;N</strong></span></div>

        <div style="text-align: justify;"></div>

        <div style="text-align: justify;"><span >Para la resoluci&oacute;n de todas las
                controversias o cuestiones relacionadas con el presente sitio web o de las actividades en &eacute;l
                desarrolladas, ser&aacute; de aplicaci&oacute;n la legislaci&oacute;n espa&ntilde;ola, a la que se
                someten expresamente las partes, siendo competentes para la resoluci&oacute;n de todos los conflictos
                derivados o relacionados con su uso los Juzgados y Tribunales del domicilio del USUARIO o el lugar del
                cumplimiento de la obligaci&oacute;n.</span></div>

    </div>
</section>