import { PhonePrivateSessionModel } from "./phone-private-session.model"

export class PrivateSessionModel {  
    public idUser: string
    public email: string
    public nickname: string
    public seerId: string
    public seerName: string
    public seerEmail: string
    public bookDuration: number
    public bookCost: number
    public bookDate: string
    public bookHour: string
    public phone: PhonePrivateSessionModel[]
    public dataTransaction: any
    public allowTraduction: boolean
}