<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Condiciones de contratación</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Condiciones de contratación</li>
            </ul>
        </div>
    </div>
</div>

<section class="hiring-terms-advice-area pt-100">
    <div class="container">
        
            <h2 style="text-align:center;">Condiciones de contratación</h2>
        <p>
            <span >Por favor lee, atentamente estas
                condiciones de contrataci&oacute;n ya que contienen toda la informaci&oacute;n relativa a tus derechos y
                obligaciones como Usuario de nuestro servicio:&nbsp;</span>
        </p>
        <p>
            <strong><span >1.- ACEPTACI&Oacute;N DE
                    CONDICIONES&nbsp;</span></strong>
        </p>
        <p>
            <span >Los siguientes t&eacute;rminos y
                condiciones son de obligado conocimiento, aceptaci&oacute;n y cumplimiento por todos los Usuarios que
                accedan a los servicios prestados por totonline.es con NIF 52349967N, (en adelante la
                &ldquo;Empresa).</span>
        </p>
        <p>
            <span >El acceso a nuestros servicios supone la
                aceptaci&oacute;n expresa por parte del Usuario de todos los t&eacute;rminos y condiciones que
                aqu&iacute; se establecen.</span>
        </p>
        <p>
            <span >SI EL USUARIO NO EST&Aacute; DE ACUERDO
                CON CUALQUIERA DE CONDICIONES REFLEJADAS A CONTINUACION NO DEBER&Aacute; UTILIZAR LOS SERVICIOS
                OFERTADOS.</span>
        </p>
        <p>
            <strong><span >2.- PRESTACI&Oacute;N DEL
                    SERVICIO&nbsp;</span></strong>
        </p>
        <p>
            <span >La prestaci&oacute;n de los servicios
                atender&aacute; en todo momento a la legislaci&oacute;n espa&ntilde;ola vigente y no amparar&aacute;
                lesiones ni derechos de terceros o cualquier quebranto de norma imperativa o de los usos y de las buenas
                costumbres.</span>
        </p>
        <p>
            <span >Podr&aacute;n ser Usuarios del servicio
                todas las personas f&iacute;sicas mayores de edad con capacidad de obrar. Por tanto, no podr&aacute;n
                utilizar los servicios ofrecidos, ni menores de edad ni mayores sin plena capacidad de obrar.</span>
        </p>
        <p>
            <span >El Usuario garantiza haber alcanzado la
                mayor&iacute;a de edad y ser el responsable de que la informaci&oacute;n aportada es veraz y
                l&iacute;cita y que no contradice a las disposiciones legales y reglamentarias en vigor ni viola
                derechos de ning&uacute;n tipo.</span>
        </p>
        <p>
            <span >La prestaci&oacute;n de los servicios
                ofertados tiene una duraci&oacute;n de car&aacute;cter indefinido. Pese a ello, la Empresa se reserva el
                derecho y el Usuario acepta que la empresa pueda, en cualquier momento, modificar, interrumpir,
                desactivar y/o cancelar unilateralmente de forma indefinida, permanente cualquiera de los servicios
                prestados por ella prestados, sin necesidad de preaviso, en este caso, el &uacute;nico derecho del
                usuario ser&aacute; su derecho de desistimiento conforme a las condiciones previstas en las presentes
                clausulas y, en cualquier caso, si el usuario hubiera pagado ya un servicio pero este no hubiese sido
                consumido en su totalidad, a que se le preste el servicio hasta su finalizaci&oacute;n conforme a las
                condiciones pactadas en el momento de su contrataci&oacute;n.</span>
        </p>
        <p>
            <span >El Usuario se compromete a realizar un uso
                correcto y l&iacute;cito del sitio, de conformidad con la legislaci&oacute;n vigente, la moral, las
                buenas costumbres, el orden p&uacute;blico y la buena fe, con el &uacute;nico fin de beneficiarse de los
                Servicios ofertados por los Asesores espirituales.</span>
        </p>
        <p>
            <span >La Empresa no responder&aacute; por las
                interrupciones que se produzcan en los servicios de telecomunicaciones que impidan a los Usuarios
                utilizar los servicios ofrecidos, independientemente de la clase de conexi&oacute;n utilizada por el
                Usuario, ni ser&aacute; responsable de los retrasos o fallos que se produjeran en el acceso y/o
                funcionamiento de los servicios, debido a un caso de Fuerza Mayor.</span>
        </p>
        <p>
            <span >La empresa se reserva y el Usuario declara
                estar informado y conceder expresamente el derecho de grabar y registrar la confirmaci&oacute;n de la
                operaci&oacute;n, as&iacute; como la consulta telem&aacute;tica mantenida con el Asesor y los datos que
                se desprendan de dicha consulta, a fin de garantizar la correcta prestaci&oacute;n del servicio. ESTOS
                REGISTROS PUEDEN SER CONTITUTIVOS DE PRUEBA ANTE LOS TRIBUNALES PARA DEFENDER LOS INTERESES DE LA
                EMPRESA Y PODRAN SER CEDIDOS A TERCEROS POR PETICI&Oacute;N DE LOS TRIBUNALES O LAS FUERZAS Y CUERPOS DE
                SEGURIDAD DEL ESTADO.</span>
        </p>
        <p>
            <span >Por el contrario, El Usuario no
                est&aacute; autorizado a grabar y/o almacenar, total o parcialmente, el contenido de las consultas
                telem&aacute;ticas mantenidas entre las partes, a menos que obtenga permiso previo y por escrito por
                parte de La Empresa.</span>
        </p>
        <p>
            <strong><span >3.- TARIFICACI&Oacute;N E
                    INSTRUCCIONES DE USO</span></strong>
        </p>
        <p>
            <span >Previo a la prestaci&oacute;n del servicio
                es necesario realizar una recarga de cr&eacute;dito, de tal forma que el usuario disponga de al menos
                10&euro; en su monedero de cr&eacute;dito real (no computable cr&eacute;dito de promociones) antes de
                entrar en una sala. Una vez dentro de la sala si el usuario desea enviar una consulta telem&aacute;tica
                (v&iacute;a mensaje de audio), tendr&aacute; que asumir la aportaci&oacute;n m&iacute;nima definida en
                cada sala, la cual ser&aacute; informada mediante mensaje emergente a la hora de grabar el mensaje de
                audio, se podr&aacute; utilizar cr&eacute;dito de promociones siempre que el usuario disponga de dicho
                cr&eacute;dito, para realizar esta aportaci&oacute;n.</span>
        </p>
        <p>
            <span >Los mensajes de audio de los usuarios, se
                ordenaran en una lista por orden de grabaci&oacute;n de mensaje en cada sala para ser consultados en
                dicho orden, como algunas salas acumular&aacute;n listas extensas, la aplicaci&oacute;n web te permite
                realizar aportaciones extras, para escalar en la lista, de tal forma que la ordenaci&oacute;n de la
                lista se realizar&aacute; teniendo en cuenta estos 2 criterios; primero por orden de aportaci&oacute;n
                realizada en la sala y despu&eacute;s por orden de la fecha/hora de grabaci&oacute;n del mensaje de
                audio correspondiente. En este caso, se podr&aacute; utilizar cr&eacute;dito de promociones siempre que
                el usuario disponga de dicho cr&eacute;dito, para realizar estas aportaciones extras.</span>
        </p>
        <p>
            <span >La aplicaci&oacute;n web est&aacute;
                dise&ntilde;ada para poder realizar aportaciones a terceros, pudiendo escuchar los mensajes de otros
                usuarios y puntuando en forma de &ldquo;estrellas&rdquo;, el valor de cada estrella estar&aacute;
                definido en cada sala, de tal forma que, si se quiere puntuar un mensaje de otro usuario, se debe asumir
                una aportaci&oacute;n en forma de donaci&oacute;n en el contador de cantidad aportada en sala del otro
                usuario, por valor de; n&uacute;mero de estrellas puntuadas * valor monetario de cada estrella definido
                en cada sala. Un usuario, podr&aacute; donar en forma de puntuaci&oacute;n de estrellas un mensaje de
                audio de cualquier usuario de la sala, pero solo una &uacute;nica vez por usuario en esa sala. Cada una
                de estas donaciones a terceros siempre ser&aacute;n informadas y validadas mediante mensajes emergentes
                de validaci&oacute;n. En este caso, se podr&aacute; utilizar cr&eacute;dito de promociones siempre que
                el usuario disponga de dicho cr&eacute;dito, para realizar estas donaciones.</span>
        </p>
        <p>
            <span >En el caso en el que el usuario se quede
                sin cr&eacute;dito para poder realizar aportaciones extras o donaciones a terceros, puede ir al
                dashboard de su cuenta y recargar el cr&eacute;dito de su monedero para volver a entrar en la sala
                correspondiente.</span>
        </p>
        <p>
            <span >La duraci&oacute;n de la sala es un valor
                estimado, teniendo en cuenta que cada una de las salas registradas por cada asesor espiritual, permanece
                abierta al p&uacute;blico en modo offline, en el que el asesor espiritual no est&aacute; conectado y
                transcurrido un tiempo (normalmente en torno a una semana), se programa la sesi&oacute;n online en la
                que el asesor espiritual se conecta mediante youtube live y comienza a realizar las consultas
                telem&aacute;ticas por orden de prioridad de los mensajes de audio introducidos en la lista siguiendo
                los criterios de ordenaci&oacute;n anteriormente expuestos. Si el usuario que mand&oacute; el mensaje de
                audio a consultar esta el primero de la lista para ser consultado y no est&aacute; online, se
                pasar&aacute; al siguiente mensaje cuyo usuario si est&eacute; conectado, as&iacute; hasta que el
                usuario est&eacute; en l&iacute;nea.</span>
        </p>
        <p>
            <span >&nbsp;Si una sala tuviera muchos mas
                mensajes de consulta que el tiempo previamente definido de la sesi&oacute;n online, el asesor espiritual
                se reserva el derecho de alargar la duraci&oacute;n de la sala para terminar de consultar a todos los
                usuarios, o bien, podr&aacute; finalizar la sala online, cre&aacute;ndose una nueva sala y los usuarios
                con mensajes de audio no consultados permanecer&aacute;n en la misma posici&oacute;n de la lista y
                contador de aportaci&oacute;n realizada en dicha sala online recientemente finalizada, que
                volver&aacute; a estar en estado offline, hasta la nueva programaci&oacute;n de sesi&oacute;n online
                correspondiente.&nbsp;</span>
        </p>
        <p>
            <span >La Empresa podr&aacute; modificar los
                precios y honorarios del servicio, en cualquier momento y comunicar&aacute; puntualmente est&aacute;
                circunstancia al Usuario, previo al acceso del servicio correspondiente.</span>
        </p>
        <p>
            <strong><span >4.- IMPAGOS&nbsp;</span></strong>
        </p>
        <p>
            <span >En caso de impago o devoluci&oacute;n del
                importe previamente cobrado, el importe de la Transacci&oacute;n aumentar&aacute; por los gastos que se
                deriven hacia La Empresa a consecuencia de la devoluci&oacute;n de la suma en conflicto, con el banco
                del Usuario.&nbsp;</span>
        </p>
        <p>
            <span >Cualquier retraso o incumplimiento de pago
                de la Comisi&oacute;n aumentar&aacute; dicha cuant&iacute;a con las sumas de los intereses por demora
                correspondientes a una vez y media (1.5) la tasa de inter&eacute;s legal sin opci&oacute;n a la
                reclamaci&oacute;n por da&ntilde;os y perjuicios y otras acciones necesarias para proteger los intereses
                de la Empresa.</span>
        </p>
        <p>
            <span >En cambio, cada vez que el pago se haga
                por Tarjeta de Cr&eacute;dito de forma fraudulenta, el titular de dicha tarjeta tendr&aacute; la
                posibilidad de reclamar la devoluci&oacute;n de la suma en conflicto directamente a su entidad bancaria
                o a la Empresa, previa mediaci&oacute;n de denuncia por usurpaci&oacute;n de identidad, quien
                devolver&aacute; el importe cobrado a el propietario de la tarjeta e iniciara denuncia conjunta con el
                Usuario a fin de reclamar el importe adeudado por los servicios prestados, a quien hubiese suplantado su
                identidad.</span>
        </p>
        <p>
            <strong><span >5.-
                    DESISTIMIENTO&nbsp;</span></strong>
        </p>
        <p>
            <span >El Usuario tiene derecho a desistir del
                presente contrato en un plazo de 14 d&iacute;as naturales sin necesidad de justificaci&oacute;n ni pagar
                penalizaciones. El plazo de desistimiento empieza a contar desde la fecha de realizaci&oacute;n de su
                pago y expirar&aacute; a los 14 d&iacute;as naturales del d&iacute;a de la celebraci&oacute;n del
                contrato. Siempre y cuando no se haya iniciado la prestaci&oacute;n del servicio, entendi&eacute;ndose
                como servicio prestado las aportaciones o donaciones a terceros realizadas dentro de una sala. Cuando
                cae en s&aacute;bado, domingo o festivo el plazo de catorce d&iacute;as se prolonga hasta el siguiente
                d&iacute;a h&aacute;bil.</span>
        </p>
        <p>
            <span >Seg&uacute;n lo dispuesto en el
                p&aacute;rrafo a) del art&iacute;culo 103 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por
                el que se aprueba el texto refundido de la Ley General para la Defensa de los Usuarios y Usuarios, con
                las disposiciones del art&iacute;culo L. 121-20-2 del C&oacute;digo de Consumo y otras leyes
                complementarias, una vez iniciada la prestaci&oacute;n de servicios, el Usuario perder&aacute; su
                derecho de desistimiento. El Usuario reconoce y acepta la p&eacute;rdida de su derecho de desistimiento
                en los t&eacute;rminos expresados en el p&aacute;rrafo anterior. Asimismo, el Usuario reconoce que la
                ejecuci&oacute;n del servicio se corresponde con tiempo del servicio prestado por el Asesor.</span>
        </p>
        <p>
            <span >Para ejercer el derecho de desistimiento,
                el Usuario deber&aacute; notificar en plazo su decisi&oacute;n de desistimiento a trav&eacute;s de una
                declaraci&oacute;n inequ&iacute;voca enviada mediante correo electr&oacute;nico con confirmaci&oacute;n
                de lectura a contacto@totonline.es</span>
        </p>
        <p>
            <span >En caso de desistimiento, se
                efectuar&aacute; las devoluciones correspondientes, en cumplimento a lo establecido, sin ninguna demora
                indebida y, en todo caso, a m&aacute;s tardar de 14 d&iacute;as naturales a partir de la fecha de
                notificaci&oacute;n. No se aplicar&aacute; el derecho de desistimiento cuando el Usuario haya hecho uso,
                parcial o total, de los servicios contratados.</span>
        </p>
        <p>
            <strong><span >6.-
                    DEPENDENCIA&nbsp;</span></strong>
        </p>
        <p>
            <span >La Empresa advierte que algunos Usuarios
                pueden desarrollar una Dependencia al uso excesivo de los Servicios y recomienda a todo Usuario hacer un
                consumo responsable y razonable de los Servicios para evitar cualquier tipo de Dependencia, recomendando
                especialmente&hellip;</span>
        </p>
        <p>
            <span >&bull; Controlar el tiempo de gasto de
                cada una de tus consultas o en un determinado periodo.</span>
        </p>
        <p>
            <span >&bull; Evitar el uso de Servicios, en caso
                de que tu situaci&oacute;n econ&oacute;mica sea ajustada.</span>
        </p>
        <p>
            <span >&bull; Evitar el uso de Servicios en los
                per&iacute;odos de estado confuso.</span>
        </p>
        <p>
            <span >&bull; No considerar los Servicios como
                una escapatoria a sus problemas.</span>
        </p>
        <p>
            <span >&bull; Evite que el consumo de estos
                servicios si est&aacute;n afectando en su vida.</span>
        </p>
        <p>
            <span >Adopte SIEMPRE un comportamiento
                responsable para evitar un uso excesivo de los Servicios que podr&iacute;an producirle a una
                Dependencia.</span>
        </p>
        <p>
            <span >Para luchar contra la aparici&oacute;n de
                la Dependencia, La Empresa instruir&aacute; a los Asesores para que cuando estos sospechen de un estado
                de Dependencia por parte de un Usuario estos puedan informar a la empresa.</span>
        </p>
        <p>
            <strong><span >7.-
                    SATISFACCI&Oacute;N&nbsp;</span></strong>
        </p>
        <p>
            <span >La prestaci&oacute;n de servicios
                ser&aacute; efectuada por Asesores independientes a trav&eacute;s de la Empresa, actuando la Empresa
                como mero intermediario entre el Asesor y el Usuario.</span>
        </p>
        <p>
            <span >Los Asesores prestar&aacute;n en todo
                momento un servicio de calidad y en condiciones apropiadas. En caso contrario, la Empresa se reserva el
                derecho a excluir a los asesores que sean incapaces de prestar sus servicios en unas condiciones
                m&iacute;nimas de calidad o que atiendan a los Usuarios de manera inapropiada.</span>
        </p>
        <p>
            <span >Por &uacute;ltimo, la Empresa no toma
                parte contractual en el suministro de los Servicios y, por tanto: no responder&aacute; del contenido ni
                tendr&aacute; ninguna responsabilidad de las consultas telem&aacute;ticas con los asesores, que no
                fueran de satisfacci&oacute;n del Usuario ni tampoco ofrece ninguna garant&iacute;a en cuanto al
                &eacute;xito de los Servicios o del pago de las Transacciones por parte del Usuario sujeto eso
                s&iacute;, a las responsabilidades que le incumben como suministrador de los Servicios. El Usuario tiene
                prohibido incitar al Asesor a proporcionarle los Servicios fuera de la Plataforma.</span>
        </p>
        <p>
            <strong><span >8.- MODIFICACI&Oacute;N DE
                    CONDICIONES&nbsp;</span></strong>
        </p>
        <p>
            <span >La Empresa se reserva el derecho y
                est&aacute; facultada para modificar, limitar o cancelar en todo momento las Condiciones expuestas,
                as&iacute; como los precios y/o servicios prestados, sin necesidad de aviso previo ni citar el
                consentimiento a sus Usuarios, y respetando en todo momento la legislaci&oacute;n vigente. Sin que ello
                de lugar, ni derecho a ninguna reclamaci&oacute;n o indemnizaci&oacute;n, ni implique reconocimiento de
                responsabilidad alguna por parte de la Empresa, sin perjuicio de lo establecido en el p&aacute;rrafo
                3&ordm;.</span>
        </p>
        <p>
            <span >En caso de realizar modificaciones en las
                Condiciones, las nuevas Condiciones se publicar&aacute;n &ldquo;in situ&rdquo;, a fin de que el Usuario,
                previo acceso al servicio, sea conocedor de las mismas y ser&aacute;n aplicables a cualquier contrato
                celebrado a partir de dicha publicaci&oacute;n.</span>
        </p>
        <p>
            <span >En ning&uacute;n caso las antedichas
                modificaciones afectaran a los servicios ya contratados y/o efectivamente prestados al Usuario con
                anterioridad a la entrada en vigor de los cambios efectuados.</span>
        </p>
        <p>
            <strong><span >9.-
                    CONFLICTOS&nbsp;</span></strong>
        </p>
        <p>
            <span >El Usuario responder&aacute; personalmente
                de los da&ntilde;os y perjuicios de cualquier naturaleza causados a la Empresa, directa o
                indirectamente, por el incumplimiento de cualquiera de las obligaciones derivadas de estas condiciones
                generales u otras normas por las que se rija la utilizaci&oacute;n de los servicios prestados.</span>
        </p>
        <p>
            <span >La Empresa, se reserva el derecho de
                emprender cualquier acci&oacute;n legal o judicial que resulte conveniente para la protecci&oacute;n de
                sus derechos, siempre que resulte procedente y muy especialmente, en caso de incumplimiento de las
                Condiciones establecidas.</span>
        </p>
        <p>
            <span >Cualquier cl&aacute;usula de las presentes
                condiciones que se declare nula o il&iacute;cita por un juez competente ser&aacute; privada de efectos,
                pero su nulidad no afectar&aacute; a otras disposiciones ni afectar&aacute; a la validez de las
                Condiciones de Uso en su conjunto o en sus efectos jur&iacute;dicos.</span>
        </p>
        <p>
            <strong><span >10.-
                    JURISDICCI&Oacute;N&nbsp;</span></strong>
        </p>
        <p>
            <span >El sistema de compra se somete a la
                legislaci&oacute;n vigente en Espa&ntilde;a.</span>
        </p>
        <p>
            <span >Las presentes condiciones generales de
                compra est&aacute;n sujetas a lo dispuesto a la Ley 7/1988, de 13 de abril sobre Condiciones Generales
                de Contrataci&oacute;n, a la actual Ley 34/2002 de Servicios de la sociedad de la informaci&oacute;n y
                comercio electr&oacute;nico y la Ley 3/2014, de 27 de marzo, por la que se modifica el texto refundido
                de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, que
                transpone al derecho espa&ntilde;ol la Directiva Europea 2011/83 sobre los derechos de los Usuarios. Al
                Real Decreto 1906/1999, de 17 de diciembre de 1999, por el que se regula la Contrataci&oacute;n
                Telef&oacute;nica o Electr&oacute;nica con condiciones generales, la Ley Org&aacute;nica 15/1999, de 13
                de diciembre de Protecci&oacute;n de Datos de Car&aacute;cter Personal, y el Reglamento (UE) 2016/679,
                del Parlamento Europeo y del Consejo, de 27 de abril (&ldquo;Reglamento General de Protecci&oacute;n de
                Datos&rdquo; o &ldquo;RGPD&rdquo;)</span>
        </p>
        <p>
            <span >En el supuesto de que surgiera
                alg&uacute;n conflicto o discrepancia, los Juzgados y Tribunales que conocer&aacute;n del asunto,
                ser&aacute;n lo que disponga la normativa legal aplicable que trat&aacute;ndose de Usuarios finales
                ser&aacute; el domicilio del comprador o el lugar del cumplimiento de la obligaci&oacute;n. En caso de
                que la parte compradora tenga domicilio fuera de Espa&ntilde;a o si la compraventa se realiza por una
                empresa, ambas partes renuncian de manera expresa a cualquier otro foro, someti&eacute;ndose a los
                Juzgados y Tribunales de la Provincia de Madrid, para mejor reclamaci&oacute;n de sus derechos, salvo en
                los casos en que no sea posible por ministerio de la Ley o una norma de derecho imperativo establezca un
                fuero distinto, debiendo aplicarse en todo caso la Legislaci&oacute;n Espa&ntilde;ola.</span>
        </p>
        <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;'>
            &nbsp;</p>
    </div>
</section>