import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { SeerSchedulePrivateSessionBackendService } from '../services/seer-schedule-private-session-backend.service'

@Injectable()

export class SeerSchedulePrivateSessionManager {

    private seerSchedulePrivateSessionAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _seerSchedulePrivateSessionBackendService: SeerSchedulePrivateSessionBackendService
    ) { }

    ngOnDestroy() {
        if (this.seerSchedulePrivateSessionAPI != null) {
            this.seerSchedulePrivateSessionAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    createSeerSchedulePrivateSession(seerSchedulePrivateSessionData) {
        return new Promise((resolve) => {
            this.seerSchedulePrivateSessionAPI = this._seerSchedulePrivateSessionBackendService.createSeerSchedulePrivateSession(seerSchedulePrivateSessionData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Seer schedule private session created success")

                        Swal.fire({
                            icon: 'success',
                            title: "Sala Privada creada correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(response.seer_schedule_private_session_data)
                    }
                    else if (response.code == 201) {
                        console.log("The Seer have a Private Session created")

                        Swal.fire({
                            icon: 'error',
                            title: "El maestro ya tiene una sala privada creada. Modifica la existente o borra y crea una nueva",
                            showConfirmButton: false,
                            timer: 4000
                        })
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    getSeerSchedulePrivateSessionBySeerId(seerId) {
        return new Promise((resolve) => {
            this.seerSchedulePrivateSessionAPI = this._seerSchedulePrivateSessionBackendService.getSeerSchedulePrivateSessionBySeerId(seerId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Seer schedule private rooms retrieved success")

                        resolve(response.seer_schedule_private_session_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    deletePrivateSessionById(idPrivateSession) {
        return new Promise((resolve) => {
            this.seerSchedulePrivateSessionAPI = this._seerSchedulePrivateSessionBackendService.deletePrivateSessionById(idPrivateSession)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sala privada eliminada correctamente',
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    updatePrivateSession(idPrivateSession, seerSchedulePrivateSessionData) {
        return new Promise((resolve) => {
            this.seerSchedulePrivateSessionAPI = this._seerSchedulePrivateSessionBackendService.updatePrivateSession(idPrivateSession, seerSchedulePrivateSessionData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sala privada actualizada correctamente',
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }
}