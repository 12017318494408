<div class="page-title-area">
    <div class="container">
      <div class="page-title-item">
        <h2>Terminos de uso</h2>
        <ul>
          <li><a routerLink="/">Inicio</a></li>
          <li><span></span></li>
          <li>Terminos de uso</li>
        </ul>
      </div>
    </div>
  </div>

<section class="terms-of-use-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            Última modificación: 06 de diciembre de 2021.
            
            <ol>
                <li>
                    <strong>Introducción</strong>
                    Te damos la bienvenida al portal totonline.es (en adelante, el "Portal"). Los productos y los
                    servicios
                    de TOTonline (en adelante, los "Servicios") se proporcionan a través de TOTonline. (en adelante, la
                    «Empresa»), con Número de CIF 52349967N.
                    El uso de nuestro Portal implica la aceptación y adhesión a todas nuestras Condiciones de Uso,
                    Política
                    de Privacidad y Condiciones de Servicios. Te recomendamos que las leas detenidamente.
                </li>
                <li>
                    <strong>Uso del portal y sus servicios</strong>
                    El usuario se compromete a utilizar el Portal, sus contenidos y servicios conforme con:
                    <ol>
                        <li>La Ley o cualesquiera otras normas del ordenamiento jurídico aplicable</li>
                        <li>Las presentes Condiciones de Uso</li>
                        <li>La Política de Privacidad</li>
                        <li>Las buenas costumbres</li>
                        <li>El orden público</li>
                    </ol>
                    Asimismo, el Usuario se compromete expresamente a no destruir, alterar, inutilizar o, de cualquier
                    otra
                    forma, dañar los datos, programas o documentos electrónicos y demás que se encuentren en el Portal.
                    El Usuario se compromete a no obstaculizar el acceso de otros usuarios al servicio de acceso
                    mediante el
                    consumo masivo de los recursos informáticos a través de los cuales la Empresa presta el servicio,
                    así
                    como realizar acciones que dañen, interrumpan o generen errores en dichos sistemas.
                    El Usuario se compromete a no transmitir, introducir, difundir y poner a disposición de terceros
                    cualquier tipo de material e información (programas, virus, macros, applets, controles ActiveX,
                    datos,
                    contenidos, dibujos, archivos de sonido e imagen, etc.) que sean contrarios a la ley, el orden
                    público o
                    que causen o sean susceptibles de causar cualquier tipo de alteración en los sistemas informáticos
                    de la
                    Empresa o de terceros.
                </li>
                <li>
                    <strong>Propiedad intelectual e industrial</strong>
                    El Portal está protegido por leyes de propiedad intelectual y por los tratados internacionales en la
                    materia. El contenido que se muestra en o a través del sitio web está protegido en su condición de
                    obra
                    colectiva y / o compilación, de acuerdo con las leyes de propiedad intelectual y los tratados
                    internacionales sobre la materia.
                    Salvo que fuera autorizado por la Empresa o a menos que ello resulte legalmente permitido, el
                    usuario no
                    podrá copiar, modificar, distribuir, vender, alquilar o explotar de cualquier otra forma contenidos
                    del
                    Portal. Asimismo, el usuario no puede llevar a cabo operaciones de desensamblaje o descompilación,
                    ingeniería inversa o cualquier otra operación destinada a obtener cualquier código fuente contenido
                    en
                    este Portal.
                </li>
                <li>
                    <strong>Contenidos y Servicios de la Empresa</strong>
                    La Empresa responderá única y exclusivamente de los Servicios que preste por sí misma y de los
                    contenidos directamente originados por la Empresa e identificados con su copyright. Dicha
                    responsabilidad quedará excluida en los casos en que concurran causas de fuerza mayor o en los
                    supuestos
                    en que la configuración de los equipos del Usuario no sea la adecuada para permitir el correcto uso
                    de
                    los servicios de Internet prestados por la Empresa.
                </li>
                <li>
                    <strong>Modificaciones en el Servicio y Condiciones de Uso</strong>
                    La Empresa se reserva el derecho a realizar cambios en el Portal, pudiendo modificar, suprimir e
                    incluir, unilateralmente y sin previo aviso, nuevos contenidos, así como la forma en que estos
                    aparezcan
                    presentados y localizados.
                    Funcionamiento de uso de Salas
                    Previo a la prestación del servicio es necesario realizar una recarga de crédito, de tal forma que
                    el
                    usuario disponga de al menos 10€ en su monedero antes de entrar en una sala. Una vez dentro de la
                    sala
                    si el usuario desea enviar una consulta telemática (vía mensaje de audio), tendrá que asumir la
                    aportación mínima definida en cada sala, la cual será informada mediante mensaje emergente a la hora
                    de
                    grabar el mensaje de audio.
                    Los mensajes de audio de los usuarios, se ordenaran en una lista por orden de grabación de mensaje
                    en
                    cada sala para ser consultados en dicho orden, como algunas salas acumularán listas extensas, la
                    aplicación web te permite realizar aportaciones extras, para escalar en la lista, de tal forma que
                    la
                    ordenación de la lista se realizará teniendo en cuenta estos 2 criterios; primero por orden de
                    aportación realizada en la sala y después por orden de la fecha/hora de grabación del mensaje de
                    audio
                    correspondiente.
                    La aplicación web está diseñada para poder realizar aportaciones a terceros, pudiendo escuchar los
                    mensajes de otros usuarios y puntuando en forma de “estrellas”, el valor de cada estrella estará
                    definido en cada sala, de tal forma que, si se quiere puntuar un mensaje de otro usuario, se debe
                    asumir
                    una aportación en forma de aportación en el contador de cantidad aportada en sala del otro usuario,
                    por
                    valor de; número de estrellas puntuadas * valor monetario de cada estrella definido en cada sala. Un
                    usuario, podrá donar en forma de puntuación de estrellas un mensaje de audio de cualquier usuario de
                    la
                    sala, pero solo una única vez por usuario en esa sala. Cada una de estas donaciones a terceros
                    siempre
                    serán informadas y validadas mediante mensajes emergentes de validación.
                    En el caso en el que el usuario se quede sin crédito para poder realizar aportaciones extras o
                    donaciones a terceros, puede ir al dashboard de su cuenta y recargar el crédito de su monedero para
                    volver a entrar en la sala correspondiente.
                    La duración de la sala es un valor estimado, teniendo en cuenta que cada una de las salas
                    registradas
                    por cada asesor espiritual, permanece abierta al público en modo offline, en el que el asesor
                    espiritual
                    no está conectado y transcurrido un tiempo (normalmente en torno a una semana), se programa la
                    sesión
                    online en la que el asesor espiritual se conecta mediante youtube live y comienza a realizar las
                    consultas telemáticas por orden de prioridad de los mensajes de audio introducidos en la lista
                    siguiendo
                    los criterios de ordenación anteriormente expuestos. Si el usuario que mandó el mensaje de audio a
                    consultar esta el primero de la lista para ser consultado y no está online, se pasará al siguiente
                    mensaje cuyo usuario si esté conectado, así hasta que el usuario esté en línea, de tal forma que a
                    la
                    finalización de cada consulta al usuario consultado le aparecerá un mensaje emergente para puntuar
                    en
                    forma de estrellas la consulta correspondiente. Dicha puntuación se utilizará para medir el grado de
                    satisfacción global de consultas por maestro espiritual y poder ordenar a dichos maestros en base a
                    este
                    criterio en el listado de maestros espirituales de la web correspondiente.
                    Si una sala tuviera muchos más mensajes de consulta que el tiempo previamente definido de la sesión
                    online, el asesor espiritual se reserva el derecho de alargar la duración de la sala para terminar
                    de
                    consultar a todos los usuarios, o bien, podrá finalizar la sala online, creándose una nueva sala y
                    los
                    usuarios con mensajes de audio no consultados permanecerán en la misma posición de la lista y
                    contador
                    de aportación realizada en dicha sala online recientemente finalizada, que volverá a estar en estado
                    offline, hasta la nueva programación de sesión online correspondiente.
                    Asimismo, la Empresa se reserva el derecho a realizar cambios en las presentes Condiciones de Uso en
                    cualquier momento. El usuario quedará sujeto a las nuevas Condiciones de Uso que hayan sido
                    publicadas
                    en el momento en que acceda o utilice los servicios del Portal.
                    Si alguna de las presentes condiciones resulta invalidada, nula o inaplicable por cualquier motivo,
                    dicha condición quedará excluida y no afectará a la validez ni la aplicabilidad del resto de
                    condiciones.
                </li>
                <li>
                    <strong>Disponibilidad y Continuidad del Portal y los Servicios</strong>
                    La Empresa no garantiza la disponibilidad, el acceso y/o la continuidad del funcionamiento del
                    Portal y
                    de sus Servicios. Asimismo, la Empresa no será responsable, con los límites establecidos por la Ley,
                    de
                    los daños y perjuicios causados al Usuario como consecuencia de la indisponibilidad, fallos de
                    acceso y
                    falta de continuidad del Portal y de sus Servicios.
                </li>
                <li>
                    <strong>Duración y terminación</strong>
                    La prestación de los servicios y/o contenidos del Portal tiene una duración indefinida.
                    Sin perjuicio de lo anterior, y además de por las causas establecidas legalmente, la Empresa está
                    facultada para dar por terminada, suspender o interrumpir unilateralmente, en cualquier momento y
                    sin
                    necesidad de preaviso, la prestación del servicio y del Portal y/o cualquiera de los servicios.
                </li>
                <li>
                    <strong>Jurisdicción</strong>
                    La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman
                    estas
                    Condiciones de Uso, así como cualquier cuestión relacionada con los servicios del Portal, será la
                    ley
                    española.
                    Para la resolución de cualquier controversia que pudiera surgir con ocasión del uso del Portal y sus
                    servicios, las partes acuerdan someterse a la jurisdicción de los Juzgados y Tribunales de la ciudad
                    de
                    Madrid (España), y sus superiores jerárquicos, con expresa renuncia a otros fueros si lo tuvieren y
                    fueran diferentes de los reseñados.
                </li>
            </ol>
        </div>
    </div>
</section>