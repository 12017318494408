import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class PostsBackendService {
    public httpOptions: HttpHeaders
    private _postsEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._postsEndPoint = environment.APIEndPointBackend + environment.Posts
    }

    /* GET ALL POSTS */
    public getAllPosts(): Observable<any> {

        return this._httpClient.get(this._postsEndPoint)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get all post: ", error)
                    return error
                })
            )
    }

    /* GET LATEST POSTS */
    public getLatestPost(): Observable<any> {

        return this._httpClient.get(this._postsEndPoint + environment.GetLatestPosts)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get latest posts: ", error)
                    return error
                })
            )
    }
    
    

    /* CREATE POST */
    public createPost(post): Observable<any> {

        return this._httpClient.post(this._postsEndPoint, post)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when create post: ", error)
                    return error
                })
            )
    }

    /* UPDATE POST */
    public updatePost(idPost, postData): Observable<any> {
        
        return this._httpClient.put(this._postsEndPoint + idPost, postData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when update post: ", error)
                    return error
                })
            )
    }

    /* GET POST BY ID */
    public getPostById(idPost): Observable<any> {

        return this._httpClient.get(this._postsEndPoint + environment.GetPost + idPost)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get post: ", error)
                    return error
                })
            )
    }

    
    /* GET POST BY ID */
    public deletePost(idPost): Observable<any> {

        return this._httpClient.delete(this._postsEndPoint + idPost)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when delete post: ", error)
                    return error
                })
            )
    }
}