<div class="page-title-area">
	<div class="container">
		<div class="page-title-item">
			<img class="profile-img" [src]="getProfileImage()" />
			<h2>
				<span *ngIf="currentUser.rol === '3'">(Admin)</span>
				Bienvenido {{currentUser.firstName}} {{currentUser.lastName}}
			</h2>
			<ul>
				<li><a routerLink="/">Inicio</a></li>
				<li><span></span></li>
				<li>Dashboard de usuario</li>
			</ul>
		</div>
	</div>
</div>

<div class="user-area pt-100 pb-70">
	<div class="container">
		<div class="row menu-container">
			<div class="col-sm-2 col-lg-2 btns-container">
				<a (click)="showUserData()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Datos de
					usuario</a>
				<a *ngIf="currentUser.rol === '3'" (click)="showManagementSeersTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Gestión de maestros</a>
				<a *ngIf="currentUser.rol != '1'" (click)="showManagementRoomsTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Gestionar salas</a>
				<a *ngIf="currentUser.rol === '3'" (click)="showManagementPromotionsTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Gestionar promociones</a>
				<a *ngIf="currentUser.rol != '1'" (click)="showManagementPrivateSessionsTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Salas
					privadas</a>
				<a *ngIf="currentUser.rol != '2'" (click)="showCreditTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Crédito</a>
				<a *ngIf="currentUser.rol === '3'" (click)="showManagementBillingTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Facturación</a>
				<a *ngIf="currentUser.rol === '3'" (click)="showManagementPostsTab()"
					class="template-btn btn-black width-100 text-align-center btn-responsive">Posts</a>
				<a class="template-btn btn-black width-100 text-align-center btn-responsive"
					(click)="showManagementSettingsTab()">Ajustes</a>
				<a (click)="logOut()" class="template-btn btn-black width-100 text-align-center btn-responsive">Cerrar
					sesión</a>
			</div>

			<div class="user-data-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 1">
				<div class="tab-title">
					Datos de usuario
				</div>
				<div class="form-group">
					<label class="form-title">Nombre</label>
					<input type="text" class="form-control" [readonly]="isReadOnly()"
						[(ngModel)]="currentUser.firstName">
				</div>
				<div class="form-group">
					<label class="form-title">Apellidos</label>
					<input type="text" class="form-control" [readonly]="isReadOnly()"
						[(ngModel)]="currentUser.lastName">
				</div>
				<div class="form-group">
					<label class="form-title">Email</label>
					<input type="text" class="form-control" [readonly]="isReadOnly()" [(ngModel)]="currentUser.email">
				</div>
				<div class="form-group" *ngIf="typeUser != '2'">
					<label class="form-title">Fecha de nacimiento</label>
					<input type="date" class="form-control" [(ngModel)]="currentUser.birthday">
				</div>
				<div class="form-group" *ngIf="typeUser != '2'">
					<label class="form-title">Nickname</label>
					<input type="text" class="form-control" [(ngModel)]="currentUser.nickname">
				</div>
				<div class="inputs-container">
					<a (click)="editUser()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>
			</div>

			<div class="credit-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 2">
				<div class="user-credit">
					<i class="fa fa-money" aria-hidden="true"></i> Credito disponible: {{currentUser.credit}} €
				</div>
				<div class="col-sm-4 col-lg-4 add-credit-button">
					<a (click)="showAddCreditTab()" class="template-btn btn-black width-100 text-align-center">Añadir
						crédito</a>
				</div>
			</div>

			<div class="add-credit-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 3">
				<div class="tab-title">
					¿Cuanto quieres añadir?
				</div>
				<div class="add-credit-buttons">
					<div class="credit-buttons-row">
						<button (click)="selectCredit(15)" [ngStyle]="{'background-color': bgColor}"
							class="template-btn btn-black text-align-center btn-responsive">15€</button>
						<button (click)="selectCredit(20)" [ngStyle]="{'background-color': bgColor}"
							class="template-btn btn-black text-align-center btn-responsive">20€</button>
						<button (click)="selectCredit(25)" [ngStyle]="{'background-color': bgColor}"
							class="template-btn btn-black text-align-center btn-responsive">25€</button>
					</div>
					<div class="credit-buttons-row">
						<button (click)="selectCredit(30)" [ngStyle]="{'background-color': bgColor}"
							class="template-btn btn-black text-align-center btn-responsive">30€</button>
						<button (click)="selectCredit(35)" [ngStyle]="{'background-color': bgColor}"
							class="template-btn btn-black text-align-center btn-responsive">35€</button>
						<button (click)="selectCredit(40)" [ngStyle]="{'background-color': bgColor}"
							class="template-btn btn-black text-align-center btn-responsive">40€</button>
					</div>

					<div class="inputs-container">
						<input type="number" class="form-control input-other-quantity" [(ngModel)]='quantityInput'
							(change)="selectCredit()" [value]='quantity' id="other-quantity"
							placeholder="Introduce otra cantidad">

						<a _ngcontent-ukw-c68="" _ngcontent-nrn-c16="" (click)="showPayTab()"
							class="template-btn btn-black text-align-center">Continuar</a>
					</div>


				</div>
			</div>

			<div class="pay-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 4">
				<div class="pay-container">
					<div class="text-lines">Credito TOT Online {{creditToAdd}}</div>
					<div class="text-lines">Importe Total: {{creditToAdd}}€</div>
					<div class="text-lines">Elije una de las siguientes opciones de pago</div>
					<ngx-paypal [config]="payPalConfig"></ngx-paypal>
				</div>
			</div>

			<div class="add-seer-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 5">
				<div class="tab-title">
					{{titleCreateOrEditSeerTab}}
				</div>
				<div class="form-group">
					<label class="form-title">Nombre</label>
					<input type="text" [(ngModel)]='nameNewSeer' class="form-control">
				</div>
				<div class="form-group">
					<label class="form-title">Apellidos</label>
					<input type="text" [(ngModel)]='surnameNewSeer' class="form-control">
				</div>
				<div class="form-group">
					<label class="form-title">Email</label>
					<input type="text" [(ngModel)]='emailNewSeer' class="form-control">
				</div>

				<div class="form-group" *ngIf="seerImage != null">
					<label class="form-title">Imagen actual</label><br>
					<img class="seer-image" [src]="seerImage">
				</div>

				<div class="form-group">
					<label class="form-title">Seleccionar imagen</label><br>
					<input type="file" accept="image/*" (change)="processFileSeer($event)">
				</div>
				<div class="form-group">
					<label class="form-title">Password</label>
					<input type="password" [(ngModel)]='passwordNewSeer' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Introducción (máximo 2500 caracteres) </label>
					<textarea [(ngModel)]='introductionNewSeer' class="form-control" maxlength="2500"></textarea>
				</div>

				<div class="form-group mt20">
					<ng-switchery [(ngModel)]="toggleAllowPrivateSession"
						onLabel="{{labelForToggleAllowPrivateSession}}" class="toogle-allow-private-session"></ng-switchery>
				</div>

				<div class="inputs-container">
					<a (click)="addOrEditNewSeer()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>

			</div>

			<div class="management-seers-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 6">
				<div class="tab-title">
					Gestionar maestros
				</div>

				<ng-container *ngIf="haveSeers">
					<table class="seers-table">
						<tr>
							<th>Nombre de maestro</th>
							<th>Email maestro</th>
							<th></th>
							<th></th>
						</tr>
						<ng-container *ngFor="let seer of seers">
							<tr>
								<td>{{seer.name}}</td>
								<td>{{seer.email}}</td>
								<td><a (click)="deleteSeer(seer)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="far fa-times-circle"></i></a>
								</td>
								<td><a (click)="showAddOrEditSeerTab('update', seer)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-edit"></i></a></td>
							</tr>
						</ng-container>
					</table>
				</ng-container>

				<div *ngIf="currentUser.rol === '3'" class="inputs-container">
					<a (click)="showAddOrEditSeerTab('create')" class="template-btn btn-black text-align-center">Crear
						maestro</a>
				</div>
			</div>

			<div class="management-rooms-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 7">
				<div class="tab-title">
					Gestionar salas
				</div>

				<ng-container *ngIf="haveRooms">
					<table class="seer-rooms-table">
						<tr>
							<th>Nombre de sala</th>
							<th>Vidente</th>
							<th>Día</th>
							<th>Hora</th>
							<th>Cantidad mínima</th>
							<th>Valor estrella</th>
							<th>Estado</th>
							<th *ngIf="currentUser.rol === '3'"></th>
							<th *ngIf="currentUser.rol === '3'"></th>
						</tr>
						<ng-container *ngFor="let room of seerRooms">
							<tr>
								<td>{{room.nameRoom}}</td>
								<td>{{room.seerName}}</td>
								<td>{{room.dateRoom}}</td>
								<td>{{room.timeRoom}}</td>
								<td>{{room.minimumAportation}}€</td>
								<td>{{room.starValue}}€</td>
								<ng-container *ngIf="room.state">
									<td><i class="fas fa-circle" style="color: green;"></i></td>
								</ng-container>
								<ng-container *ngIf="!room.state">
									<td><i class="fas fa-circle" style="color: red;"></i></td>
								</ng-container>
								<td *ngIf="currentUser.rol === '3'"><a (click)="cancelRoom(room.id)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="far fa-times-circle"></i></a>
								</td>
								<td *ngIf="currentUser.rol === '3'"><a (click)="showAddOrEditRoomTab('update', room)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-edit"></i></a></td>
							</tr>
						</ng-container>
					</table>
				</ng-container>

				<div *ngIf="currentUser.rol === '3'" class="inputs-container">
					<a (click)="showAddOrEditRoomTab('create')" class="template-btn btn-black text-align-center">Crear
						sala</a>
				</div>
			</div>

			<div class="create-room-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 8">
				<div class="tab-title">
					{{titleCreateOrEditRoomTab}}
				</div>

				<div class="state-room-container" *ngIf="operation == 'update'">
					<label class="state-label">Estado de la sala <span [ngClass]="{'room-open': stateRoom,
						'room-closed': !stateRoom }">{{stateRoomLabel}}</span></label>
					<br>
					<a *ngIf="currentUser.rol === '3'" (click)="changeRoomState()"
						class="template-btn btn-black width-100 text-align-center">{{changeRoomStateTextButton}}</a>

				</div>

				<div class="form-group">
					<label class="form-title">Nombre de sala</label>
					<input type="text" [(ngModel)]='nameNewRoom' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Vidente</label>
					<select class="form-control" [(ngModel)]='seerDataNewRoom' (change)="fillSeerEmail()">
						<option [ngValue]="undefined">Selecciona un vidente</option>
						<ng-container *ngFor="let seer of seers">
							<option [ngValue]="seer">{{seer.name}}</option>
						</ng-container>
					</select>
				</div>

				<div class="form-group">
					<label class="form-title">Email del vidente</label>
					<input type="text" [(ngModel)]='seerEmailNewRoom' class="form-control" readonly>
				</div>

				<div class="form-group">
					<label class="form-title">ID de canal YouTube</label>
					<input type="text" [(ngModel)]='idYouTubeChannelNewRoom' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Aportación mínima de la sala (€)</label>
					<input type="number" [(ngModel)]='minimumAportationNewRoom' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Tiempo de consulta (en minutos)</label>
					<input type="number" [(ngModel)]='timeToConsultNewRoom' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Valor de cada estrella (€)</label>
					<input type="number" [(ngModel)]='starValueNewRoom' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Número mínimo de mensajes para comenzar</label>
					<input type="number" [(ngModel)]='minimumNumberMessagesToStart' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Fecha de sala <span *ngIf="currentDateRoomEdit != ''">(Actual:
							{{currentDateRoomEdit}})</span></label>
					<input type="date" [(ngModel)]='dateNewRoom' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Hora de sala <span *ngIf="currentTimeRoomEdit != ''">(Actual:
							{{currentTimeRoomEdit}})</span></label>

				</div>

				<mat-form-field>
					<input matTimepicker #t1="matTimepicker" okLabel="Aceptar" cancelLabel="Cancelar"
						[(ngModel)]='timeNewRoom' [minDate]="minValue" [maxDate]="maxValue" mode="24h"
						placeholder="Por favor, selecciona la hora de la sala..." class="input-time-picker form-control"
						name="time-1" required>
				</mat-form-field>

				<div class="form-group mt20">
					<ng-switchery [(ngModel)]="toggleShowDateValue" onLabel="{{labelForToogleShowDate}}"
						class="toogle-show-date"></ng-switchery>
				</div>

				<div class="inputs-container">
					<a (click)="createOrUpdateRoom()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>
			</div>

			<div class="management-promotions-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 9">
				<div class="tab-title">
					Gestionar promociones
				</div>

				<ng-container *ngIf="havePromotions">
					<table class="promotions-table">
						<tr>
							<th>Nombre de promoción</th>
							<th>Valor</th>
							<th>Valido hasta</th>
							<th>Utilizada</th>
							<th></th>
							<th></th>
						</tr>
						<ng-container *ngFor="let promotion of promotions">
							<tr>
								<td>{{promotion.namePromotion}}</td>
								<td>{{promotion.valuePromotion}}€</td>
								<td>{{promotion.dateToPromotion}}</td>
								<td *ngIf="promotion.promotionOnlyOneUse">{{promotion.isUsed ? "Utilizada": "No
									utilizada"}}</td>
								<td *ngIf="!promotion.promotionOnlyOneUse">Ilimitada</td>
								<td><a (click)="cancelPromotion(promotion.id)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="far fa-times-circle"></i></a></td>
								<td><a (click)="showAddOrEditPromotionTab('update', promotion)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-edit"></i></a>
								</td>
							</tr>
						</ng-container>
					</table>
				</ng-container>

				<div *ngIf="currentUser.rol === '3'" class="inputs-container">
					<a (click)="showAddOrEditPromotionTab('create')"
						class="template-btn btn-black text-align-center">Crear promoción</a>
				</div>
			</div>

			<div class="create-room-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 10">
				<div class="tab-title">
					{{titleCreateOrEditPromotionTab}}
				</div>
				<div class="form-group">
					<label class="form-title">Nombre de promoción</label>
					<input type="text" [(ngModel)]='nameNewPromotion' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Valor de la promoción</label>
					<input type="number" [(ngModel)]='valueNewPromotion' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Promoción válida hasta <span *ngIf="dateToNewPromotion != ''">(Actual:
							{{currentDateToNewPromotion}})</span></label>
					<input type="date" [(ngModel)]="dateToNewPromotion" class="form-control">
				</div>

				<div class="form-group mt20">
					<ng-switchery [(ngModel)]="togglePromotionOnlyOneUseValue"
						onLabel="{{labelForTogglePromotionOnlyOneUse}}" class="toogle-show-date"></ng-switchery>
				</div>

				<div class="inputs-container">
					<a (click)="createOrUpdatePromotion()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>
			</div>

			<div class="management-private-sessions-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 11">
				<div class="tab-title">
					Gestionar salas privadas
				</div>

				<ng-container *ngIf="havePrivateSessions">
					<table class="private-sessions-table">
						<tr>
							<th>Nombre</th>
							<th>Permitir traducción</th>
							<th></th>
							<th></th>
						</tr>
						<ng-container *ngFor="let seerSchedulePrivateSession of seerScheduledPrivateSessions">
							<tr>
								<td>{{seerSchedulePrivateSession.namePrivateSession}}</td>
								<td>{{seerSchedulePrivateSession.allowTraduction}}</td>
								
								<td><a *ngIf="currentUser.rol === '3'" (click)="cancelPrivateSession(seerSchedulePrivateSession.id)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="far fa-times-circle"></i></a></td>
								<td><a *ngIf="currentUser.rol === '3'" (click)="showAddOrEditPrivateSessionTab('update', seerSchedulePrivateSession)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-edit"></i></a>
								</td>
							</tr>
						</ng-container>
					</table>
				</ng-container>

				<div *ngIf="currentUser.rol === '3'" class="inputs-container">
					<a (click)="showAddOrEditPrivateSessionTab('create')"
						class="template-btn btn-black text-align-center">Crear sala privada</a>
				</div>
			</div>

			<div class="create-private-session-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 12">
				<div class="tab-title">
					{{titleCreateOrEditPrivateSession}}
				</div>

				<div class="form-group">
					<label class="form-title">Vidente</label>
					<select class="form-control" [(ngModel)]='seerDataNewPrivateSession' (change)="fillSeerEmail()">
						<option [ngValue]="undefined">Selecciona un vidente</option>
						<ng-container *ngFor="let seer of seers">
							<option [ngValue]="seer">{{seer.name}}</option>
						</ng-container>
					</select>
				</div>

				<div class="form-group">
					<label class="form-title">Nombre de sesión privada</label>
					<input type="text" [(ngModel)]='nameNewPrivateSession' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Días disponibles para sesión privada</label>
					<div class="days-btns-container">
						<div class="day-btn" *ngFor="let day of daysOfWeek; let indexOfelement = index;">
							<a class="template-btn btn-black mt20" 
								(click)="openModalSelectHourForDay(modalSelectHourForDay, indexOfelement, day)">{{day}}</a>
						</div>
					</div>
				</div>

				<div *ngFor="let item of itemsDurationSession;">
					<div class="form-group">
						<label class="form-title">Duración de la sesión</label>
						<input type="number" [(ngModel)]='item.duration' class="form-control">
					</div>
	
					<div class="form-group">
						<label class="form-title">Coste de la sesión</label>
						<input type="number" [(ngModel)]='item.cost' class="form-control">
					</div>
					<hr>

					<a (click)="removeRowDurationAndCost(item)" class="template-btn btn-black remove-row">Eliminar fila</a>
				</div>
			
				<a (click)="addRowDurationAndCost()" class="template-btn btn-black text-align-center mt20">Añadir fila</a>
				

				<div class="form-group mt20">
					<ng-switchery [(ngModel)]="toggleAllowTraductionPrivateSession"
						onLabel="{{labelForToggleAllowTraduction}}" class="toogle-allow-traduction"></ng-switchery>
				</div>

				<div class="inputs-container">
					<a (click)="createOrUpdatePrivateSession()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>
			</div>

			<div class="billing-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 13">
				<div class="tab-title">
					Facturación
				</div>

				<ng-container *ngIf="haveSessions">
					<table class="billing-table">
						<tr>
							<th>Nombre de sala</th>
							<th>Fecha inicio</th>
							<th>Fecha fin</th>
							<th>Informe de credito</th>
							<th>Informe de promociones</th>
						</tr>
						<ng-container *ngFor="let session of sessions">
							<tr>
								<td>{{session.nameSession}}</td>
								<td>{{session.dateStart | date: 'dd/MM/yyyy HH:mm'}}</td>
								<td *ngIf="session.dateEnd != undefined">{{session.dateEnd | date: 'dd/MM/yyyy HH:mm'}}
								</td>
								<td *ngIf="session.dateEnd == undefined">Sala activa</td>
								<td><a (click)="getBilling(session.id)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-file-download"></i></a>
								</td>
								<td><a (click)="getRedeemedPromotionsInSession(session.id)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-file-download"></i></a>
								</td>
							</tr>
						</ng-container>
					</table>
				</ng-container>
			</div>

			<div class="posts-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 14">
				<div class="tab-title">
					Posts
				</div>

				<ng-container *ngIf="havePosts">
					<table class="posts-table">
						<tr>
							<th>Fecha post</th>
							<th>Título</th>
						</tr>
						<ng-container *ngFor="let post of posts">
							<tr>
								<td>{{post.date | date: 'dd/MM/yyyy'}}</td>
								<td>{{post.title}}</td>
								<td><a (click)="deletePost(post.id)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="far fa-times-circle"></i></a>
								</td>
								<td><a (click)="showAddOrEditPostsTab('update', post)"
										class="template-btn btn-black text-align-center btn-small"><i
											class="fas fa-edit"></i></a></td>
							</tr>
						</ng-container>
					</table>
				</ng-container>

				<div *ngIf="currentUser.rol === '3'" class="inputs-container">
					<a (click)="showAddOrEditPostsTab('create')" class="template-btn btn-black text-align-center">Crear
						post</a>
				</div>
			</div>

			<div class="create-room-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 15">
				<div class="tab-title">
					{{titleCreateOrEditPostTab}}
				</div>
				<div class="form-group">
					<label class="form-title">Fecha <span *ngIf="dateNewPost != ''">(Actual:
							{{dateNewPost | date: 'dd/MM/yyyy'}})</span></label>
					<input type="date" [(ngModel)]='dateNewPost' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Título</label>
					<input type="text" [(ngModel)]='titleNewPost' class="form-control">
				</div>

				<div class="form-group">
					<label class="form-title">Subtítulo</label>
					<input type="text" [(ngModel)]='subtitleNewPost' class="form-control">
				</div>

				<div class="form-group" *ngIf="imageNewPost != null && imageNewPost != ''">
					<label class="form-title">Imagen actual</label><br>
					<img class="seer-image" [src]="imageNewPost">
				</div>

				<div class="form-group">
					<label class="form-title">Seleccionar imagen</label><br>
					<input type="file" accept="image/*" (change)="processFilePost($event)">
				</div>

				<angular-editor [(ngModel)]="textNewPost" [config]="angularEditorConfig"></angular-editor>

				<div class="inputs-container">
					<a (click)="createOrUpdatePost()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>
			</div>

			<div class="settings-tab col-sm-10 col-lg-10" *ngIf="tabSelected == 16">
				<div class="tab-title">
					Gestiona tus preferencias
				</div>

				<div class="toggles-container">
					<div class="toggle">
						<label for="aceptSendComercialNotifications">Acepto recibir notificaciones
							comerciales</label>
						<ng-switchery [(ngModel)]="currentUser.aceptSendNotifications"
							name="aceptSendComercialNotifications"></ng-switchery>
					</div>
				</div>

				<div class="inputs-container">
					<a (click)="updatePreferences()" class="template-btn btn-black text-align-center">Guardar</a>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #modalSelectHourForDay let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">
		Seleccionar horas disponibles para el: {{daySelected}}
	  </h4>
	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
  
		<span aria-hidden="true">
		  ×
		</span>
	  </button>
	</div>
	<div class="modal-body">
		<div class="hour-btns-container">
			<div class="hour-btn" *ngFor="let hour of allTimesToBookSession; let indexOfelement = index;">
				<a class="template-btn btn-black mt20" [ngClass]="{'hour-selected': hourSelectedByDay.indexOf(hour) != -1 }"
					(click)="selectHourForPrivateSession(hour)" style="width: 100px;">{{hour}}</a>
				<ng-template *ngIf="indexOfelement % 6 == 0"><br></ng-template>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	  <button type="button" class="btn btn-outline-dark" (click)="modal.close('OK')">
		Enviar
	  </button>
	</div>
  </ng-template>