<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Política de cookies</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Política de cookies</li>
            </ul>
        </div>
    </div>
</div>

<section class="cookies-advice-area pt-100">
    <div class="container">


        <h2 style="text-align:center;">POLÍTICA DE COOKIES</h2>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div style="text-align: center;"><br />
            <strong>INFORMACIÓN SOBRE <em>COOKIES</em></strong><br />
        </div>

        <div style="text-align: justify;">Conforme con la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la
            información y de comercio electrónico (LSSI), en relación con el Reglamento (UE) 2016/679 del Parlamento
            Europeo y del Consejo, de 27 de abril de 2016, General de Protección de Datos (GDPR) y la Ley Orgánica
            3/2018, de 5 de diciembre, de Protección de Datos y Garantía de los Derechos Digitales (LOPDGDD), es
            obligado obtener el consentimiento expreso del usuario de todas las páginas web que usan cookies
            prescindibles, antes de que este navegue por ellas. </div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div style="text-align: center;"><br />
            <strong>&iquest;QUÉ SON LAS <em>COOKIES</em>?</strong><br />
        </div>

        <div style="text-align: justify;">Las <em>cookies </em>y otras tecnologías similares tales como local shared
            objects, flash <em>cookies </em>o píxeles, son herramientas empleadas por los servidores Web para almacenar
            y recuperar información acerca de sus visitantes, así como para ofrecer un correcto funcionamiento del
            sitio.</div>

        <div style="text-align: justify;"><br />
            Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al
            usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña,
            productos que más le interesan, etc.</div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div style="text-align: center;"><br />
            <em><strong>COOKIES </strong></em><strong>AFECTADAS POR LA NORMATIVA Y <em>COOKIES </em>EXCEPTUADAS</strong>
        </div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div style="text-align: justify;">Según la directiva de la UE, las <em>cookies </em>que requieren el
            consentimiento informado por parte del usuario son las <em>cookies </em>de analítica y las de publicidad y
            afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio
            web o la prestación de servicios expresamente solicitados por el usuario.</div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div style="text-align: center;"><br />
            <strong>TIPOS DE <em>COOKIES</em></strong><br />
        </div>

        <div style="text-align: justify;"><strong>SEGÚN LA FINALIDAD</strong>

            <ul>
                <li><strong><em>Cookies </em></strong><strong>técnicas y funcionales</strong>: son aquellas que permiten
                    al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las
                    diferentes opciones o servicios que en ella existan<em>.</em> </li>
                <li><strong><em>Cookies </em></strong><strong>analíticas</strong>: son aquellas que permiten al
                    responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios
                    web a los que están vinculadas. La información recogida mediante este tipo de <em>cookies</em> se
                    utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la
                    elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas,
                    con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios
                    del servicio.</li>
                <li> <strong><em>Cookies</em></strong> <strong>publicitarias:</strong> son aquellas que permiten la
                    gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor
                    haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado
                    en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.
                </li>
                <li><strong><em>Cookies </em></strong><strong>de publicidad comportamental</strong>: recogen información
                    sobre las preferencias y elecciones personales del usuario (<em>retargeting</em>) para permitir la
                    gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor
                    haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado.
                </li>
                <li><strong><em>Cookies </em></strong><strong>sociales</strong>: son establecidas por las plataformas de
                    redes sociales en los servicios para permitirle compartir contenido con sus amigos y redes. Las
                    plataformas de medios sociales tienen la capacidad de rastrear su actividad en línea fuera de los
                    Servicios. Esto puede afectar al contenido y los mensajes que ve en otros servicios que visita.</li>
                <li> <strong><em>Cookies </em>de afiliados</strong>: permiten hacer un seguimiento de las visitas
                    procedentes de otras webs, con las que el sitio web establece un contrato de afiliación (empresas de
                    afiliación).</li>
                <li><strong><em>Cookies </em></strong><strong>de seguridad</strong>: almacenan información cifrada para
                    evitar que los datos guardados en ellas sean vulnerables a ataques maliciosos de terceros.</li>
            </ul>
            <br />
            <strong>SEGÚN LA PROPIEDAD</strong>

            <ul>
                <li><strong><em>Cookies</em></strong><strong> propias</strong>: son aquellas que se envían al equipo
                    terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se
                    presta el servicio solicitado por el usuario. </li>
                <li><strong><em>Cookies</em></strong> <strong>de terceros:</strong> son aquellas que se envían al equipo
                    terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra
                    entidad que trata los datos obtenidos través de las cookies.</li>
            </ul>
            <br />
            <strong>SEGÚN EL PLAZO DE CONSERVACIÓN</strong>

            <ul>
                <li><strong><em>Cookies</em></strong><strong> de sesión:</strong> son un tipo de <em>cookies</em>
                    diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. </li>
                <li><strong><em>Cookies</em></strong><strong> persistentes:</strong> son un tipo de cookies en el que
                    los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un período
                    definido por el responsable de la <em>cookie</em>, y que puede ir de unos minutos a varios años.
                </li>
            </ul>
        </div>

        <div style="text-align: center;"><br />
            <strong>TRATAMIENTO DE DATOS PERSONALES</strong>
        </div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div>
            <p>DAVID LINARES GALÁN es el <strong>Responsable del tratamiento</strong> de los datos personales del
                <strong>Interesado </strong>y le informa de que estos datos serán tratados de conformidad con lo
                dispuesto en el Reglamento (UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la
                siguiente información del tratamiento:
            </p>

            <p><strong>Fines del tratamiento: </strong>según se especifica en el apartado de <em>cookies </em>que se
                utilizan en este sitio web.</p>
            <strong>Legitimación del tratamiento</strong>: por consentimiento del interesado (art. 6.1 GDPR).

            <p><strong>Criterios de conservación de los datos</strong>: según se especifica en el apartado de
                <em>cookies </em>utilizadas en la web.
            </p>

            <p><strong>Comunicación de los datos</strong>: no se comunicarán los datos a terceros, excepto en cookies
                propiedad de terceros o por obligación legal.</p>

            <p><strong>Derechos que asisten al Interesado</strong>:</p>

            <p>- Derecho a retirar el consentimiento en cualquier momento.<br />
                - Derecho de acceso, rectificación, portabilidad y supresión de sus datos y a la limitación u oposición
                a su tratamiento.<br />
                - Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el
                tratamiento no se ajusta a la normativa vigente.</p>

            <p><strong>Datos de contacto para ejercer sus derechos:</strong></p>

            <p>DAVID LINARES GALÁN.<strong> </strong>C/ SANTIAGO, 84 - 28822 COSLADA (Madrid). E-mail:
                contacto@totonline.es<br />
                <br />
            </p>
        </div>

        <div style="text-align: center;">
            <div style="text-align: center;">
                <div style="text-align: center;">
                    <p><strong><em>COOKIES </em>UTILIZADAS EN ESTE SITIO WEB</strong></p>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>

                    <div>
                        <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable" style="width:100%;">
                            <tbody>
                                <tr>
                                    <td
                                        style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                        <span style="font-size:14px;"><strong>PANEL DE CONFIGURACIÓN</strong></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                        <strong> COOKIES TÉCNICAS Y FUNCIONALES</strong><br />
                                        <span style="font-size:16px;"></span>No precisan consentimiento del usuario
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable" style="width:100%;">
                            <tbody>
                                <tr>
                                    <td style="width: 5%; text-align: left;">NOMBRE</td>
                                    <td style="width: 10%; text-align: left;">TIPO</td>
                                    <td style="width: 10%; text-align: left;">PROPIEDAD</td>
                                    <td style="width: 50%; text-align: left;">FINALIDAD</td>
                                    <td style="width: 10%; text-align: left;">PLAZO</td>
                                    <td style="width: 15%; text-align: left;">OBSERVACIONES</td>
                                </tr>
                                <tr>
                                    <td style="width: 5%; text-align: left;"><strong>PHPSESSID</strong></td>
                                    <td style="width: 10%; text-align: left;">Técnica</td>
                                    <td style="width: 10%; text-align: left;">Propia</td>
                                    <td style="width: 50%; text-align: left;">Contiene el identificador de la sesión
                                    </td>
                                    <td style="width: 10%; text-align: left;">Al cerrar el navegador</td>
                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                </tr>
                                <tr>
                                    <td style="width: 5%; text-align: left;"><strong>_lang</strong></td>
                                    <td style="width: 10%; text-align: left;">Técnica</td>
                                    <td style="width: 10%; text-align: left;">Propia</td>
                                    <td style="width: 50%; text-align: left;">Contiene el idioma de la sesión</td>
                                    <td style="width: 10%; text-align: left;">Al cerrar el navegador</td>
                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                </tr>
                                <tr>
                                    <td style="width: 5%; text-align: left;"><strong>ac_cookies</strong></td>
                                    <td style="width: 10%; text-align: left;">Técnica</td>
                                    <td style="width: 10%; text-align: left;">Propia</td>
                                    <td style="width: 50%; text-align: left;">Contiene el valor de si se ha aceptado la
                                        instalación de cookies</td>
                                    <td style="width: 10%; text-align: left;">1 año</td>
                                    <td style="width: 15%; text-align: left;">Persistente</td>
                                </tr>
                                <tr>
                                    <td style="width: 5%; text-align: left;"><strong>contador</strong></td>
                                    <td style="width: 10%; text-align: left;">Técnica</td>
                                    <td style="width: 10%; text-align: left;">Propia</td>
                                    <td style="width: 50%; text-align: left;">Se utiliza para efectuar el recuento del
                                        número de accesos a la página por sesión</td>
                                    <td style="width: 10%; text-align: left;">1 año</td>
                                    <td style="width: 15%; text-align: left;">Persistente</td>
                                </tr>
                                <tr>
                                    <td style="width: 5%; text-align: left;"><strong>viewed_cookie_policy</strong></td>
                                    <td style="width: 10%; text-align: left;">Técnica</td>
                                    <td style="width: 10%; text-align: left;">Propia</td>
                                    <td style="width: 50%; text-align: left;">Se utiliza para recordar sus preferencias
                                        en cuanto a la Política de Cookies establecida</td>
                                    <td style="width: 10%; text-align: left;">1 año</td>
                                    <td style="width: 15%; text-align: left;">Persistente</td>
                                </tr>
                                <tr>
                                    <td style="width: 5%; text-align: left;"><strong>_icl_current_language</strong></td>
                                    <td style="width: 10%; text-align: left;">Técnica</td>
                                    <td style="width: 10%; text-align: left;">Propia</td>
                                    <td style="width: 50%; text-align: left;">Se utiliza para recordar el idioma que ha
                                        seleccionado el usuario y mostrarle los contenidos en ese mismo idioma a lo
                                        largo de su navegación</td>
                                    <td style="width: 10%; text-align: left;">1 año</td>
                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                </tr>
                            </tbody>
                        </table>

                        <div style="text-align: center;">
                            <div style="text-align: center;">
                                <div style="text-align: center;"><br />
                                    <br />

                                    <div>
                                        <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                            style="width:100%;">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                                        <span style="font-size:14px;"><strong>PANEL DE
                                                                CONFIGURACIÓN</strong></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                                        <strong> COOKIES ANALÍTICAS</strong><br />
                                                        <strong><span style="font-size:16px;">O</span><span
                                                                style="font-size:18px;"> </span></strong>Marcar o
                                                        desmarcar según se desee aceptar o rechazar la instalación de
                                                        estas cookies.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                            style="width:100%;">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;">NOMBRE</td>
                                                    <td style="width: 10%; text-align: left;">TIPO</td>
                                                    <td style="width: 10%; text-align: left;">PROPIEDAD</td>
                                                    <td style="width: 50%; text-align: left;">FINALIDAD</td>
                                                    <td style="width: 10%; text-align: left;">PLAZO</td>
                                                    <td style="width: 15%; text-align: left;">OBSERVACIONES</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_ga</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Habilita la función de
                                                        control de visitas únicas. La primera vez que un usuario entre
                                                        en el sitio web a través de un navegador se instalará esta
                                                        <em>cookie</em>. Cuando este usuario vuelva a entrar en la web
                                                        con el mismo navegador, la <em>cookie </em>considerará que es el
                                                        mismo usuario. Solo en el caso de que el usuario cambie de
                                                        navegador, se considerará otro usuario
                                                    </td>
                                                    <td style="width: 10%; text-align: left;">2 años</td>
                                                    <td style="width: 15%; text-align: left;">Persistente</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;">_<strong>gat</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Se utiliza para limitar la
                                                        velocidad de petición - la limitación de la recogida de datos en
                                                        los sitios de alto tráfico</td>
                                                    <td style="width: 10%; text-align: left;">10 minutos</td>
                                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_gid</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Se utiliza para distinguir
                                                        a los usuarios</td>
                                                    <td style="width: 10%; text-align: left;">24 horas</td>
                                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_utma</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Registra la fecha de la
                                                        primera y última vez que el usuario vistió el sitio web</td>
                                                    <td style="width: 10%; text-align: left;">2 años</td>
                                                    <td style="width: 15%; text-align: left;">Persistente</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_utmb</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Registra la hora de
                                                        llegada a la página web</td>
                                                    <td style="width: 10%; text-align: left;">30 minutos</td>
                                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_utmc</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Se utiliza para la
                                                        interoperabilidad con el código de seguimiento urchin.js</td>
                                                    <td style="width: 10%; text-align: left;">1 año</td>
                                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_utmt</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Procesa el tipo de
                                                        solicitud pedida por el usuario</td>
                                                    <td style="width: 10%; text-align: left;">Al finalizar la sesión
                                                    </td>
                                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_utmv</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Segmenta datos
                                                        demográficos</td>
                                                    <td style="width: 10%; text-align: left;">Al finalizar la sesión
                                                    </td>
                                                    <td style="width: 15%; text-align: left;">Sesión</td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 5%; text-align: left;"><strong>_utmz</strong></td>
                                                    <td style="width: 10%; text-align: left;">Analítica</td>
                                                    <td style="width: 10%; text-align: left;">Google Analytics</td>
                                                    <td style="width: 50%; text-align: left;">Almacena la fuente de
                                                        tráfico o una campaña para explicar cómo el usuario llegó al
                                                        sitio web</td>
                                                    <td style="width: 10%; text-align: left;">6 meses</td>
                                                    <td style="width: 15%; text-align: left;">Persistente</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div style="text-align: center;">
                                            <div style="text-align: center;">
                                                <div style="text-align: center;"><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p style="text-align: left;">Obtener más información para <strong>deshabilitar
                                                las <em>cookies</em></strong> de Google Analytics:</p>

                                        <ul>
                                            <li style="text-align: left;"><a
                                                    href="https://policies.google.com/technologies/managing">Política de
                                                    privacidad de </a><a
                                                    href="https://tools.google.com/dlpage/gaoptout">Google</a></li>
                                            <li style="text-align: left;"><a
                                                    href="https://support.google.com/analytics/answer/181881?hl=es">Exclusión
                                                    de Google Analytics</a></li>
                                        </ul>
                                    </div>

                                    <div><br />
                                        <br />

                                        <div>
                                            <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                                style="width:100%;">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                                            <span style="font-size:14px;"><strong>PANEL DE
                                                                    CONFIGURACIÓN</strong></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                                            <strong> COOKIES DE PUBLICIDAD COMPORTAMENTAL</strong><br />
                                                            <strong><span style="font-size:16px;">O</span><span
                                                                    style="font-size:18px;"> </span></strong>Marcar o
                                                            desmarcar según se desee aceptar o rechazar la instalación
                                                            de estas cookies.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                                style="width:100%;">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 5%; text-align: left;">NOMBRE</td>
                                                        <td style="width: 10%; text-align: left;">TIPO</td>
                                                        <td style="width: 10%; text-align: left;">PROPIEDAD</td>
                                                        <td style="width: 50%; text-align: left;">FINALIDAD</td>
                                                        <td style="width: 10%; text-align: left;">PLAZO</td>
                                                        <td style="width: 15%; text-align: left;">OBSERVACIONES</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 5%; text-align: left;"><strong>_gads</strong>
                                                        </td>
                                                        <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                        <td style="width: 10%; text-align: left;">Google</td>
                                                        <td style="width: 50%; text-align: left;"><em>Cookie
                                                            </em>asociada al servicio de <em>Doubleclick.net </em>de
                                                            Google para que el dueño pueda ganar crédito</td>
                                                        <td style="width: 10%; text-align: left;">1 año</td>
                                                        <td style="width: 15%; text-align: left;">Persistente</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 5%; text-align: left;"><strong>_sonar</strong>
                                                        </td>
                                                        <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                        <td style="width: 10%; text-align: left;">Doubleclick.net</td>
                                                        <td style="width: 50%; text-align: left;">Se utiliza para
                                                            mejorar y orientar la publicidad según el contenido que es
                                                            relevante para un usuario, mejorar los informes de
                                                            rendimiento de la campaña y evitar mostrar anuncios que el
                                                            usuario ya haya visto <em>Doubleclick.net</em></td>
                                                        <td style="width: 10%; text-align: left;">1 año</td>
                                                        <td style="width: 15%; text-align: left;">Persistente</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 5%; text-align: left;"><strong>_ncuid</strong>
                                                        </td>
                                                        <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                        <td style="width: 10%; text-align: left;">Google</td>
                                                        <td style="width: 50%; text-align: left;">Se utiliza para medir
                                                            el rendimiento de los anuncios y proporcionar
                                                            recomendaciones relativas a productos basadas en datos
                                                            estadísticos</td>
                                                        <td style="width: 10%; text-align: left;">6 meses</td>
                                                        <td style="width: 15%; text-align: left;">Persistente</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 5%; text-align: left;"><strong>IDE</strong>
                                                        </td>
                                                        <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                        <td style="width: 10%; text-align: left;">Doubleclick.net</td>
                                                        <td style="width: 50%; text-align: left;">Se utiliza para la
                                                            focalización, optimización, presentación de informes y
                                                            atribución de anuncios en línea.</td>
                                                        <td style="width: 10%; text-align: left;">1 año</td>
                                                        <td style="width: 15%; text-align: left;">Persistente</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 5%; text-align: left;">
                                                            <strong>_conv_v</strong><br />
                                                            <strong>bt2</strong><br />
                                                            <strong>di2</strong><br />
                                                            <strong>dt</strong><br />
                                                            <strong>loc</strong><br />
                                                            <strong>ssc</strong><br />
                                                            <strong>ssh</strong><br />
                                                            <strong>ssshs</strong><br />
                                                            <strong>uid</strong><br />
                                                            <strong>um</strong><br />
                                                            <strong>uvc</strong><br />
                                                            <strong>vc</strong>
                                                        </td>
                                                        <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                        <td style="width: 10%; text-align: left;">AddThis</td>
                                                        <td style="width: 50%; text-align: left;">Se utilizan para
                                                            habilitar el contenido con el fin de ser compartido.<br />
                                                            AddThis también se utiliza para recopilar información sobre
                                                            cómo se comparte contenido del sitio web</td>
                                                        <td style="width: 10%; text-align: left;">2 años, excepto:<br />
                                                            <br />
                                                            _conv_v: 1 año,<br />
                                                            bt2: 8 meses,<br />
                                                            dt: 30 días
                                                        </td>
                                                        <td style="width: 15%; text-align: left;">Persistentes</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div style="text-align: center;">
                                                <div style="text-align: center;">
                                                    <div style="text-align: center;"><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="text-align: left;">También podrá <strong>controlar los anuncios</strong> y
                                    las <strong>tecnologías de seguimiento</strong> con la aplicación Ghostery: <a
                                        href="http://www.ghostery.com/">http://www.ghostery.com/</a></div>
                                <br />
                                <br />
                                <br />

                                <div>
                                    <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                        style="width:100%;">
                                        <tbody>
                                            <tr>
                                                <td
                                                    style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                                    <span style="font-size:14px;"><strong>PANEL DE
                                                            CONFIGURACIÓN</strong></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                                    <strong> COOKIES SOCIALES</strong><br />
                                                    <strong><span style="font-size:16px;">O</span><span
                                                            style="font-size:18px;"> </span></strong>Marcar o desmarcar
                                                    según se desee aceptar o rechazar la instalación de estas cookies.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                        style="width:100%;">
                                        <tbody>
                                            <tr>
                                                <td style="width: 5%; text-align: left;">NOMBRE</td>
                                                <td style="width: 10%; text-align: left;">TIPO</td>
                                                <td style="width: 10%; text-align: left;">PROPIEDAD</td>
                                                <td style="width: 50%; text-align: left;">FINALIDAD</td>
                                                <td style="width: 10%; text-align: left;">PLAZO</td>
                                                <td style="width: 15%; text-align: left;">OBSERVACIONES</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>c_user, fr</strong>
                                                </td>
                                                <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para el complemento
                                                    social de Facebook. Permite medir el rendimiento de los anuncios y
                                                    proporcionar recomendaciones</td>
                                                <td style="width: 10%; text-align: left;">30 días</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>datr, sb</strong></td>
                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para el complemento
                                                    social de Facebook</td>
                                                <td style="width: 10%; text-align: left;">2 años</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>dpr</strong></td>
                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para el complemento
                                                    social de Facebook</td>
                                                <td style="width: 10%; text-align: left;">30 minutos</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>xs</strong></td>
                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para el complemento
                                                    social de Facebook</td>
                                                <td style="width: 10%; text-align: left;">90 días</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>wd</strong></td>
                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para el complemento
                                                    social de Facebook</td>
                                                <td style="width: 10%; text-align: left;">48 horas</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>XSRF-TOKEN</strong>
                                                </td>
                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para controlar que
                                                    todos los envíos de formularios son efectuados por el usuario
                                                    actualmente en sesión, evitando ataques CSRF (Cross-Site Request
                                                    Forgery)</td>
                                                <td style="width: 10%; text-align: left;">1 hora</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>_fbp</strong></td>
                                                <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                <td style="width: 10%; text-align: left;">Facebook</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para proporcionar
                                                    una serie de productos publicitarios, como pujas en tiempo real de
                                                    terceros anunciantes</td>
                                                <td style="width: 10%; text-align: left;">3 meses</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>guest_id</strong></td>
                                                <td style="width: 10%; text-align: left;">Publicitaria</td>
                                                <td style="width: 10%; text-align: left;">Twitter</td>
                                                <td style="width: 50%; text-align: left;">Identificador de Twitter</td>
                                                <td style="width: 10%; text-align: left;">2 años</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div style="text-align: center;">
                                        <div style="text-align: center;">
                                            <div style="text-align: center;"><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="text-align: left;"><strong>Más info: </strong><a
                                        href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a>
                                </div>
                                <br />
                                <br />

                                <div>
                                    <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                        style="width:100%;">
                                        <tbody>
                                            <tr>
                                                <td
                                                    style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                                    <span style="font-size:14px;"><strong>PANEL DE
                                                            CONFIGURACIÓN</strong></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                                    <strong> COOKIES DE AFILIADOS</strong><br />
                                                    <strong><span style="font-size:16px;">O</span><span
                                                            style="font-size:18px;"> </span></strong>Marcar o desmarcar
                                                    según se desee aceptar o rechazar la instalación de estas cookies.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table border="0" cellpadding="1" cellspacing="1" class="pdf_datatable"
                                        style="width:100%;">
                                        <tbody>
                                            <tr>
                                                <td style="width: 5%; text-align: left;">NOMBRE</td>
                                                <td style="width: 10%; text-align: left;">TIPO</td>
                                                <td style="width: 10%; text-align: left;">PROPIEDAD</td>
                                                <td style="width: 50%; text-align: left;">FINALIDAD</td>
                                                <td style="width: 10%; text-align: left;">PLAZO</td>
                                                <td style="width: 15%; text-align: left;">OBSERVACIONES</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>prli_click</strong>
                                                </td>
                                                <td style="width: 10%; text-align: left;">Afiliados</td>
                                                <td style="width: 10%; text-align: left;">Pretty Links</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para guardar
                                                    información estadística de los clics que se hacen en los enlaces de
                                                    afiliado</td>
                                                <td style="width: 10%; text-align: left;">30 días</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 5%; text-align: left;"><strong>prli_visitor</strong>
                                                </td>
                                                <td style="width: 10%; text-align: left;">Afiliados</td>
                                                <td style="width: 10%; text-align: left;">Pretty Links</td>
                                                <td style="width: 50%; text-align: left;">Necesaria para guardar
                                                    información estadística de los clics que se hacen en los enlaces de
                                                    afiliado</td>
                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                <td style="width: 15%; text-align: left;">Persistente</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div style="text-align: center;">
                                        <div style="text-align: center;">
                                            <div style="text-align: center;"><br />
                                                <br />
                                                <br />

                                                <div>
                                                    <table border="0" cellpadding="1" cellspacing="1"
                                                        class="pdf_datatable" style="width:100%;">
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                                                    <span style="font-size:14px;"><strong>PANEL DE
                                                                            CONFIGURACIÓN</strong></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                                                    <strong> COOKIES DE TERCEROS CONTROLADAS POR EL
                                                                        EDITOR (Google, Youtube, Cloudflare,
                                                                        Bizible)</strong><br />
                                                                    <strong><span style="font-size:16px;">O</span><span
                                                                            style="font-size:18px;">
                                                                        </span></strong>Marcar o desmarcar según se
                                                                    desee aceptar o rechazar la instalación de estas
                                                                    cookies.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table border="0" cellpadding="1" cellspacing="1"
                                                        class="pdf_datatable" style="width:100%;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">NOMBRE</td>
                                                                <td style="width: 10%; text-align: left;">TIPO</td>
                                                                <td style="width: 10%; text-align: left;">PROPIEDAD</td>
                                                                <td style="width: 50%; text-align: left;">FINALIDAD</td>
                                                                <td style="width: 10%; text-align: left;">PLAZO</td>
                                                                <td style="width: 15%; text-align: left;">OBSERVACIONES
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>1P_JAR</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Publicitaria
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Transfiere
                                                                    datos a Google para hacer la publicidad más
                                                                    atractiva</td>
                                                                <td style="width: 10%; text-align: left;">1 semana</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>ANID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Publicitaria
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Contiene un
                                                                    valor generado aleatoriamente único que permite a la
                                                                    Plataforma distinguir navegadores y dispositivos.
                                                                    Esta información se utiliza para medir el
                                                                    rendimiento de los anuncios y proporcionar
                                                                    recomendaciones relativas a productos basadas en
                                                                    datos estadísticos</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>APISID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Esta
                                                                    <em>cookie</em> es utilizada por Google para
                                                                    almacenar las preferencias del usuario y la
                                                                    información durante la visualización de las páginas
                                                                    con los mapas de Google en ellos
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">2 años</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>CONSENT</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Publicitaria
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Contiene un
                                                                    valor generado aleatoriamente único que permite a la
                                                                    Plataforma distinguir navegadores y dispositivos.
                                                                    Esta información se utiliza para medir el
                                                                    rendimiento de los anuncios y proporcionar
                                                                    recomendaciones relativas a productos basadas en
                                                                    datos estadísticos</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>HSID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Al crear o
                                                                    iniciar sesión en una cuenta de Google se almacena
                                                                    esta <em>cookie</em></td>
                                                                <td style="width: 10%; text-align: left;">2 años</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>SID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Recoger
                                                                    información para el buscador de Google incluido en
                                                                    la web (Google CSE)</td>
                                                                <td style="width: 10%; text-align: left;">2 años</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>NID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">El botón
                                                                    &laquo;Google +1&raquo; utilizado en nuestro sitio
                                                                    web está alojado en Google, su navegador envía
                                                                    información de las <em>cookies</em> que Google
                                                                    requiere si mantiene la sesión activa con su cuenta,
                                                                    estos datos son utilizados por Google con el fin de
                                                                    asociarlos a su cuenta</td>
                                                                <td style="width: 10%; text-align: left;">6 meses</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>SIDCC</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Esta
                                                                    <em>cookie</em> es utilizada por Google para proveer
                                                                    servicios y extraer información anónima sobre la
                                                                    navegación
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">3 meses</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>SSID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Recoge
                                                                    información para el buscador de Google incluido en
                                                                    la web (Google CSE)</td>
                                                                <td style="width: 10%; text-align: left;">3 meses</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>PREF</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">
                                                                    <em>Cookie</em> que almacena las preferencias de
                                                                    configuración, tales como idioma preferido, número
                                                                    de resultados de búsqueda mostrados por página o
                                                                    activación del filtro <em>SafeSearch</em> de Google
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">10 años</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>DV</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Es utilizada
                                                                    por Google para proveer servicios y extraer
                                                                    información anónima sobre la navegación</td>
                                                                <td style="width: 10%; text-align: left;">10 minutos
                                                                </td>
                                                                <td style="width: 15%; text-align: left;">Sesión</td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>UULE</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Google</td>
                                                                <td style="width: 50%; text-align: left;">Cookie
                                                                    asociada al servicio de Google Maps. Permite la
                                                                    geolocalización de nuestro laboratorio en la sección
                                                                    correspondiente de este sitio web. Le remitimos a la
                                                                    política de privacidad de Google</td>
                                                                <td style="width: 10%; text-align: left;">24 horas</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>SAPISID</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Youtube</td>
                                                                <td style="width: 50%; text-align: left;">
                                                                    <em>Cookies</em> técnicas utilizadas por Youtube
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">3 meses</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>VISITOR_INFO1_LIVE</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Youtube</td>
                                                                <td style="width: 50%; text-align: left;">
                                                                    <em>Cookie</em> que realiza el seguimiento de los
                                                                    vídeos visitados que se encuentran incrustados en la
                                                                    web
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">240 días</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>use_hitbox</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Youtube</td>
                                                                <td style="width: 50%; text-align: left;">La
                                                                    <em>cookie</em> incrementa el contador de
                                                                    &laquo;vistas&raquo; en el vídeo de YouTube
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Al finalizar
                                                                    la sesión</td>
                                                                <td style="width: 15%; text-align: left;">Sesión</td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;"><strong>SID,
                                                                        SSID, HSID, APISID, LOGIN_INFO, PREF, SAPISID,
                                                                        SIDCC</strong></td>
                                                                <td style="width: 10%; text-align: left;">Técnicas</td>
                                                                <td style="width: 10%; text-align: left;">Youtube</td>
                                                                <td style="width: 50%; text-align: left;"><em>Cookies
                                                                    </em>técnicas utilizadas por Youtube</td>
                                                                <td style="width: 10%; text-align: left;">1 año,
                                                                    excepto:<br />
                                                                    <br />
                                                                    PREF: 7 meses,<br />
                                                                    SIDCC: 3 meses
                                                                </td>
                                                                <td style="width: 15%; text-align: left;">Persistentes
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>YSC</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Youtube</td>
                                                                <td style="width: 50%; text-align: left;">
                                                                    <em>Cookie</em> que mide las reproducciones de
                                                                    vídeos realizadas por el usuario y registra los
                                                                    eventos de &laquo;Me gusta&raquo; o &laquo;Compartir
                                                                    vídeo&raquo;
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Al finalizar
                                                                    la sesión</td>
                                                                <td style="width: 15%; text-align: left;">Sesión</td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>_cfduid</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Cloudflare
                                                                </td>
                                                                <td style="width: 50%; text-align: left;">La
                                                                    <em>cookie</em> de <em>Cloudflare</em> utilizada
                                                                    para anular las restricciones de seguridad basadas
                                                                    en la dirección IP del visitante que está viniendo
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>_biz_flagsA</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Bizible</td>
                                                                <td style="width: 50%; text-align: left;">Da seguimiento
                                                                    al estado del usuario. Por ejemplo, si el usuario ha
                                                                    enviado un formulario, realizado una migración de
                                                                    dominio cruzado o no</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>_biz_nA</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Bizible</td>
                                                                <td style="width: 50%; text-align: left;">Número de
                                                                    secuencia que se incrementa y añade a cada solicitud
                                                                    al servidor de Bizible con fines de diagnóstico</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>_biz_pendingA</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Bizible</td>
                                                                <td style="width: 50%; text-align: left;">Cookie
                                                                    temporal que contiene todas las solicitudes
                                                                    pendientes de envío al servidor Bizible</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>_biz_sid</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;">Técnica</td>
                                                                <td style="width: 10%; text-align: left;">Bizible</td>
                                                                <td style="width: 50%; text-align: left;">ID único que
                                                                    identifica la sesión de cada usuario</td>
                                                                <td style="width: 10%; text-align: left;">30 minutos
                                                                </td>
                                                                <td style="width: 15%; text-align: left;">Sesión</td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    <strong>_biz_uid</strong>
                                                                </td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Bizible</td>
                                                                <td style="width: 50%; text-align: left;">ID único que
                                                                    identifica a cada usuario</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width: 5%; text-align: left;">
                                                                    _<strong>BUID</strong></td>
                                                                <td style="width: 10%; text-align: left;"></td>
                                                                <td style="width: 10%; text-align: left;">Bizible</td>
                                                                <td style="width: 50%; text-align: left;">ID de usuario
                                                                    universal que identifica al mismo usuario a través
                                                                    de varios dominios de clientes</td>
                                                                <td style="width: 10%; text-align: left;">1 año</td>
                                                                <td style="width: 15%; text-align: left;">Persistente
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <div style="text-align: center;">
                                                        <div style="text-align: center;">
                                                            <div style="text-align: center;"><br />
                                                                <br />

                                                                <div>
                                                                    <table border="0" cellpadding="1" cellspacing="1"
                                                                        class="pdf_datatable" style="width:100%;">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td
                                                                                    style="text-align: center; width: 100%; background-color: rgb(221, 221, 221); height: 25px;">
                                                                                    <span style="font-size:14px;"><span
                                                                                            style="font-size:14px;"><strong>PANEL
                                                                                                DE
                                                                                                CONFIGURACIÓN</strong></span></span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    style="width: 100%; background-color: rgb(221, 221, 221); text-align: left;">
                                                                                    <strong> COOKIES DE TERCEROS NO
                                                                                        CONTROLADAS POR EL EDITOR
                                                                                        (Google, Youtube, Cloudflare,
                                                                                        Bizible, etc.) </strong><br />
                                                                                    Los servicios de terceros son ajenos
                                                                                    al control del editor.<br />
                                                                                    Los proveedores pueden modificar en
                                                                                    todo momento sus condiciones de
                                                                                    servicio, finalidad y utilización de
                                                                                    las cookies, etc.
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    style="width: 100%; background-color: rgb(255, 255, 255); text-align: left;">
                                                                                    <br />
                                                                                    Algunas de nuestras páginas del
                                                                                    sitio web muestran contenido de
                                                                                    proveedores externos, como YouTube,
                                                                                    Facebook, Twitter, LinkedIn,
                                                                                    etc.<br />
                                                                                    <br />
                                                                                    Para acceder a los contenidos de
                                                                                    terceros, los usuarios deben aceptar
                                                                                    previamente las condiciones que
                                                                                    estos aplican (entre las que se
                                                                                    incluyen sus políticas de cookies,
                                                                                    ajenas a nuestro control).<br />
                                                                                    <br />
                                                                                    Si los usuarios optan por no acceder
                                                                                    a esos contenidos, estas cookies de
                                                                                    terceros no se instalan en sus
                                                                                    dispositivos.<br />
                                                                                    <br />
                                                                                    Proveedores externos de este sitio
                                                                                    web:<br />
                                                                                    <a
                                                                                        href="https://www.youtube.com/t/terms">YouTube</a><br />
                                                                                    <a
                                                                                        href="https://archive.org/about/terms.php">Internet
                                                                                        Archive</a><br />
                                                                                    <a
                                                                                        href="https://www.google.com/intl/en_be/help/terms_maps/">Google
                                                                                        Maps</a><br />
                                                                                    <a
                                                                                        href="https://www.microsoft.com/en/servicesagreement/">Microsoft</a><br />
                                                                                    <a
                                                                                        href="https://www.facebook.com/legal/terms">Facebook</a><br />
                                                                                    <a
                                                                                        href="https://policies.google.com/terms?hl=en&amp;gl=be">Google</a><br />
                                                                                    <a
                                                                                        href="https://www.linkedin.com/legal/user-agreement">LinkedIn</a><br />

                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <div style="text-align: center;">
                                                                        <div style="text-align: center;">
                                                                            <div style="text-align: center;">
                                                                                <div>
                                                                                    <div style="text-align: center;">
                                                                                        <div
                                                                                            style="text-align: center;">
                                                                                            <div
                                                                                                style="text-align: center;">
                                                                                                <div>
                                                                                                    <div
                                                                                                        style="text-align: center;">
                                                                                                        <div
                                                                                                            style="text-align: center;">
                                                                                                            <div
                                                                                                                style="text-align: center;">
                                                                                                                <br />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div>
                                                                                    <table border="0" cellpadding="1"
                                                                                        cellspacing="1"
                                                                                        class="pdf_datatable"
                                                                                        style="width:100%;">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="text-align: center; width: 100%; height: 25px; background-color: rgb(221, 221, 221);">
                                                                                                    <strong>CÓMO
                                                                                                        GESTIONAR LAS
                                                                                                        COOKIES DESDE EL
                                                                                                        NAVEGADOR</strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                                <div>
                                                                                    <table border="0" cellpadding="1"
                                                                                        cellspacing="1"
                                                                                        class="pdf_datatable"
                                                                                        style="width:100%;">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 30px; text-align: left;">
                                                                                                    <strong>Eliminar las
                                                                                                        cookies del
                                                                                                        dispositivo
                                                                                                    </strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 70%; text-align: left;">
                                                                                                    Las cookies que ya
                                                                                                    están en un
                                                                                                    dispositivo se
                                                                                                    pueden eliminar
                                                                                                    borrando el
                                                                                                    historial del
                                                                                                    navegador, con lo
                                                                                                    que se suprimen las
                                                                                                    cookies de todos los
                                                                                                    sitios web
                                                                                                    visitados.<br />
                                                                                                    Sin embargo, también
                                                                                                    se puede perder
                                                                                                    parte de la
                                                                                                    información guardada
                                                                                                    (por ejemplo, los
                                                                                                    datos de inicio de
                                                                                                    sesión o las
                                                                                                    preferencias de
                                                                                                    sitio web).</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 30%; text-align: left;">
                                                                                                    <strong>Gestionar
                                                                                                        las cookies
                                                                                                        específicas del
                                                                                                        sitio </strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 70%; text-align: left;">
                                                                                                    Para tener un
                                                                                                    control más preciso
                                                                                                    de las cookies
                                                                                                    específicas de cada
                                                                                                    sitio, los usuarios
                                                                                                    pueden ajustar su
                                                                                                    configuración de
                                                                                                    privacidad y cookies
                                                                                                    en el navegador.
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 30%; text-align: left;">
                                                                                                    <strong>Bloquear las
                                                                                                        cookies
                                                                                                    </strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 70%; text-align: left;">
                                                                                                    Aunque la mayoría de
                                                                                                    los navegadores
                                                                                                    modernos se pueden
                                                                                                    configurar para
                                                                                                    evitar que se
                                                                                                    instalen cookies en
                                                                                                    los dispositivos,
                                                                                                    eso puede obligar al
                                                                                                    ajuste manual de
                                                                                                    determinadas
                                                                                                    preferencias cada
                                                                                                    vez que se visite un
                                                                                                    sitio o página.
                                                                                                    Además, algunos
                                                                                                    servicios y
                                                                                                    características
                                                                                                    pueden no funcionar
                                                                                                    correctamente (por
                                                                                                    ejemplo, los inicios
                                                                                                    de sesión con
                                                                                                    perfil).</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>


                                                                                <div style="text-align: left;"></div>


                                                                                <div>
                                                                                    <table border="0" cellpadding="1"
                                                                                        cellspacing="1"
                                                                                        class="pdf_datatable"
                                                                                        style="width:100%;">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="text-align: center; width: 100%; height: 25px; background-color: rgb(221, 221, 221);">
                                                                                                    <strong>CÓMO
                                                                                                        ELIMINAR LAS
                                                                                                        COOKIES DE LOS
                                                                                                        NAVEGADORES MÁS
                                                                                                        COMUNES</strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                                <div>
                                                                                    <table border="0" cellpadding="1"
                                                                                        cellspacing="1"
                                                                                        class="pdf_datatable"
                                                                                        style="width:100%;">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 15%; text-align: left;">
                                                                                                    <strong>Chrome</strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 85%; text-align: left;">
                                                                                                    <a href="http://support.google.com/chrome/answer/95647?hl=es"
                                                                                                        target="_blank">http://support.google.com/chrome/answer/95647?hl=es</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 15%; text-align: left;">
                                                                                                    <strong>Edge</strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 85%; text-align: left;">
                                                                                                    <a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                                                                                                        target="_blank">https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 15%; text-align: left;">
                                                                                                    <strong>Explorer</strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 85%; text-align: left;">
                                                                                                    <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                                                                                                        target="_blank">https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 15%; text-align: left;">
                                                                                                    <strong>Firefox
                                                                                                    </strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 85%; text-align: left;">
                                                                                                    <a
                                                                                                        href="https://www.mozilla.org/es-ES/privacy/websites/#cookies">https://www.mozilla.org/es-ES/privacy/websites/#cookies</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 15%; text-align: left;">
                                                                                                    <strong>Safari</strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 85%; text-align: left;">
                                                                                                    <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                                                                                                        target="_blank">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="width: 15%; text-align: left;">
                                                                                                    <strong>Opera</strong>
                                                                                                </td>
                                                                                                <td
                                                                                                    style="width: 85%; text-align: left;">
                                                                                                    <a
                                                                                                        href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style="text-align: left;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>