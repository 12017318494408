import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal-data-advice',
  templateUrl: './personal-data-advice.component.html',
  styleUrls: ['./personal-data-advice.component.scss']
})
export class PersonalDataAdviceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
