import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { PostsManager } from 'src/app/managers/posts.manager'
import { PostsModel } from 'src/app/models/posts/posts-model'

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  public postData: PostsModel
  public blogURL: String

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _postsManager: PostsManager
  ) { }

  ngOnInit(): void {

    this.blogURL = this._router.url

    this._activatedRoute.queryParams.subscribe(params => {
      let id = params['id']
      this._postsManager.getPostById(id).then(result => {
        if (result) {
          this.postData = result as PostsModel
        }
      })
    });
  }
}
