<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Listado de maestros</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Listado de maestros</li>
            </ul>
        </div>
    </div>
</div>

<ng-container *ngIf="seersIsAvailable">
    <div class="seers-list-area pt-100 pb-70">
        <div class="container">
            <ng-container *ngFor="let seer of seers">
                <div class="row">
                    <div class="seers-list-item">
                        <img [src]="storageAccountImagesPath + seer.photoPath" alt="Imágen maestro" class="seer-image">
                        <div class="seer-list-data-container">
                            <div class="seer-name">
                                {{seer.name}}
                            </div>
                            <div class="seer-description">
                                Más sobre {{seer.firstName}}:
                                {{seer.introduction}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>