import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators'
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent'
import { Subscription } from 'rxjs'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import {Title} from "@angular/platform-browser";
import { FirebaseManager } from './managers/firebase/firebase.manager';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    title: String = "TotOnline"
    private popupOpenSubscription: Subscription
    private popupCloseSubscription: Subscription
    private initializeSubscription: Subscription
    private statusChangeSubscription: Subscription
    private revokeChoiceSubscription: Subscription
    private noCookieLawSubscription: Subscription

    constructor(
        private _router: Router,
        private ccService: NgcCookieConsentService,
        private _gtmService: GoogleTagManagerService,
        private _titleService: Title,
        private _firebaseManager: FirebaseManager
    ) { }

    ngOnDestroy(): void {
        this.popupOpenSubscription.unsubscribe()
        this.popupCloseSubscription.unsubscribe()
        this.initializeSubscription.unsubscribe()
        this.statusChangeSubscription.unsubscribe()
        this.revokeChoiceSubscription.unsubscribe()
        this.noCookieLawSubscription.unsubscribe()
    }

    ngOnInit() {
        //enableProdMode() 
        this.recallJsFuntions()
        this._firebaseManager.initializeFirebaseServices()

        // subscribe to cookieconsent observables to react to main events
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.initializeSubscription = this.ccService.initialize$.subscribe(
            (event: NgcInitializeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this._router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                let pageTitle = item.url.substring(1)

                let subtitle = " | TOT Online"
                if(pageTitle == ""){
                    subtitle = "TOT Online"
                }
                
                this._titleService.setTitle(pageTitle.concat(subtitle))
                const gtmTag = {
                    event: 'page_view',
                    pageName: item.url
                };

                this._gtmService.pushTag(gtmTag);
            }
        });
    }

    async recallJsFuntions() {
        this._router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow')
                }
            });
        this.routerSubscription = this._router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(async event => {

                $.getScript('../assets/js/custom.js')
                $.getScript('../assets/js/jquery.min.js')

                $('.loader').fadeOut('slow')

                this.location = this._router.url;
                if (!(event instanceof NavigationEnd)) {
                    return
                }

                window.scrollTo(0, 0)

            });
    }

   
}