import { Component, OnInit } from '@angular/core';
import { PostsManager } from 'src/app/managers/posts.manager';
import { PostsModel } from 'src/app/models/posts/posts-model';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  public posts: PostsModel[]
  public havePosts: boolean = false

  constructor(
    private _postsManager: PostsManager,
    private _loadingService: LoadingService
  ) { }

  ngOnInit(): void {

    this.getAllPosts()

  }

  private getAllPosts() {
    this._loadingService.show()
		this._postsManager.getAllPosts().then(result => {
			if (result) {
				this.posts = result as PostsModel[]

        this._loadingService.hide()
        
				if (this.posts.length > 0) {
					this.havePosts = true
				}
			}
		})
	}

}
