import { Injectable } from "@angular/core";
import Swal from 'sweetalert2'

@Injectable({
    providedIn: 'root',
})
export class LoadingService {

    private loadingSwal: any

    show(message: string = "Cargando...") {
        this.loadingSwal = Swal.fire({
            title: message,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            }
        })
    }

    hide() {
        this.loadingSwal.close()
    }
}