<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Política de privacidad</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Política de privacidad</li>
            </ul>
        </div>
    </div>
</div>

<section class="privacy-policy-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">

            <h2 style="text-align:center;">TOT Online: Política de PRIVACIDAD</h2>
            <div>
                <div style="text-align: center;"><span ></span><br />
                    <span ><strong>POLÍTICA DE PRIVACIDAD</strong></span>
                </div>

                <div><span ></span></div>

                <div><span ></span></div>

                <div style="text-align: justify;"><span ><strong>1. INFORMACIÓN AL
                            USUARIO</strong></span><span ><br />
                        <br />
                        <br />
                        <strong><span style="color:#000080;">&iquest;Quién es el responsable del tratamiento de tus
                                datos personales?</span></strong></span></div>

                <p style="text-align: justify;"><span ><strong>DAVID LINARES GALÁN</strong> es el
                        RESPONSABLE del tratamiento de los datos personales del USUARIO y le informa de que estos datos
                        serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril
                        (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD).<br />
                        <br />
                        <br />
                        <span style="color:#000080;"><strong>&iquest;Para qué tratamos tus datos
                                personales?</strong></span></span></p>

                <p style="text-align: justify;"><span >Para mantener una relación comercial con
                        el usuario. Las operaciones previstas para realizar el tratamiento son:</span></p>

                <ul>
                    <li style="text-align: justify;"><span >Remisión de comunicaciones
                            comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier otro medio
                            electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales.
                            Estas comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus
                            productos y servicios, o de sus colaboradores o proveedores, con los que este haya alcanzado
                            algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos
                            personales. </span></li>
                    <li style="text-align: justify;"><span >Realizar estudios de mercado y
                            análisis estadísticos. </span></li>
                    <li style="text-align: justify;"><span >Tramitar encargos, solicitudes, dar
                            respuesta a las consultas o cualquier tipo de petición que sea realizada por el USUARIO a
                            través de cualquiera de las formas de contacto que se ponen a su disposición en la página
                            web del <span >RESPONSABLE.</span></span></li>
                    <li style="text-align: justify;"><span >Remitir el boletín informativo
                            online, sobre novedades, ofertas y promociones en nuestra actividad<strong>.</strong></span>
                    </li>
                </ul>

                <p style="text-align: justify;"><span ><span style="color:#000080;"></span><br />
                        <br />
                        <strong><span style="color:#000080;">&iquest;Por qué motivo podemos tratar tus datos
                                personales?</span></strong><br />
                        <br />
                        Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:</span>
                </p>

                <ul>
                    <li style="text-align: justify;"><span >Con el consentimiento del USUARIO:
                            remisión de comunicaciones comerciales y del boletín informativo. </span></li>
                    <li style="text-align: justify;"><span >Por interés legítimo del <span
                                >RESPONSABLE</span>: realizar estudios de mercado, análisis
                            estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del <span
                                >USUARIO</span>.</span></li>
                </ul>

                <div style="text-align: justify;"><span ></span><br />
                </div>

                <div style="text-align: justify;"><span ><span
                            style="color:#000080;"><strong>&iquest;Durante cuánto tiempo guardaremos tus datos
                                personales?</strong></span></span></div>

                <p style="text-align: justify;"><span >Se conservarán durante no más tiempo del
                        necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen
                        su custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad
                        adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos.
                    </span></p>

                <p style="text-align: justify;"><span ><strong><span
                                style="color:#000080;"><br />
                                <br />
                                &iquest;A quién facilitamos tus datos personales?</span></strong><br />
                        <br />
                        No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario
                        para el desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de
                        servicios relacionados con comunicaciones, con los cuales el <span
                            >RESPONSABLE</span> tiene suscritos los contratos de confidencialidad
                        y de encargado de tratamiento exigidos por la normativa vigente de privacidad.<br />
                        <br />
                        <br />
                        <span style="color:#000080;"><strong>&iquest;Cuáles son tus derechos?</strong></span></span></p>

                <p style="text-align: justify;"><span >Los derechos que asisten al USUARIO
                        son:</span></p>

                <ul>
                    <li style="text-align: justify;"><span >Derecho a retirar el consentimiento
                            en cualquier momento.</span></li>
                    <li style="text-align: justify;"><span >Derecho de acceso, rectificación,
                            portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.</span>
                    </li>
                    <li style="text-align: justify;"><span >Derecho a presentar una reclamación
                            ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la
                            normativa vigente.</span></li>
                </ul>

                <p style="text-align: justify;"><span ><strong>Datos de contacto para ejercer sus
                            derechos</strong>:</span></p>

                <p style="text-align: justify;"><span >DAVID LINARES GALÁN. C/ SANTIAGO, 84 -
                        28822 COSLADA (Madrid). E-mail: contacto@totonline.es</span><br />
                    <br />
                </p>

                <p style="text-align: justify;"><span ><br />
                        <strong>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL
                            USUARIO</strong></span></p>
            </div>

            <div style="text-align: justify;"><span >Los USUARIOS, mediante la marcación de las
                    casillas correspondientes y la entrada de datos en los campos, marcados con un asterisco (*) en el
                    formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma
                    libre e inequívoca, que sus datos son necesarios para atender su petición, por parte del prestador,
                    siendo voluntaria la inclusión de datos en los campos restantes. El USUARIO garantiza que los datos
                    personales facilitados al <span >RESPONSABLE </span>son veraces y se hace
                    responsable de comunicar cualquier modificación de los mismos.</span><br />
            </div>

            <div style="text-align: justify;"><span ></span></div>

            <div style="text-align: justify;"><span ><span >El
                        RESPONSABLE</span> informa de que todos los datos solicitados a través del sitio web son
                    obligatorios, ya que son necesarios para la prestación de un servicio óptimo al USUARIO. En caso de
                    que no se faciliten todos los datos, no se garantiza que la información y servicios facilitados sean
                    completamente ajustados a sus necesidades.<br />
                </span></div>

            <div style="text-align: justify;"><span ><br />
                    <strong>3. MEDIDAS DE SEGURIDAD</strong></span></div>

            <div style="text-align: justify;"><span ></span></div>

            <div style="text-align: justify;"><span ><br />
                    Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el
                    RESPONSABLE está cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para el
                    tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios
                    descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y
                    transparente en relación con el interesado y adecuados, pertinentes y limitados a lo necesario en
                    relación con los fines para los que son tratados.</span></div>

            <div>
                <p style="text-align: justify;"><span >El <span
                            >RESPONSABLE </span>garantiza que ha implementado políticas técnicas
                        y organizativas apropiadas para aplicar las medidas de seguridad que establecen el GDPR y la
                        LOPDGDD con el fin de proteger los derechos y libertades de los USUARIOS y les ha comunicado la
                        información adecuada para que puedan ejercerlos.<br />
                        <br />
                        Para más información sobre las garantías de privacidad, puedes dirigirte al<b> </b><span
                            >RESPONSABLE </span>a través de DAVID LINARES GALÁN. C/ SANTIAGO, 84
                        - 28822 COSLADA (Madrid). E-mail: contacto@totonline.es</span></p>
            </div>

        </div>
    </div>
</section>