import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class PrivateSessionBackendService {
    public httpOptions: HttpHeaders
    private _privateSessionEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._privateSessionEndPoint = environment.APIEndPointBackend + environment.PrivateSession
    }
    
    /* CREATE PRIVATE SESSION */
    public createPrivateSession(privateSessionData): Observable<any> {

        return this._httpClient.post(this._privateSessionEndPoint, privateSessionData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when create privateSession: ", error)
                    return error
                })
            )
    }

    /* GET PRIVATE SESSIONS HOURS SCHEDULED BY DATE*/
    public getPrivateSessionHoursScheduledByDate(date): Observable<any> {

        return this._httpClient.get(this._privateSessionEndPoint + environment.GetPrivateSessionHoursScheduledByDate + date)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get privateSession hours scheduled by date: ", error)
                    return error
                })
            )
    }
}