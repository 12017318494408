import { Component, OnInit } from '@angular/core';
import { EmailManager } from 'src/app/managers/email.manager';
import { LoadingService } from 'src/app/services/loading/loading.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public inputName: string
  public inputEmail: string
  public inputMessage: string


  constructor(
    private _emailManager: EmailManager,
    private _loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
  }

  sendContactForm(){
     
    if (this.inputName == undefined || this.inputName == "") {
      Swal.fire({
        icon: 'error',
        title: 'El nombre, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }


    if (this.inputEmail == null || this.inputEmail == "") {
      Swal.fire({
        icon: 'error',
        title: 'El email, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }
    
    if (this.inputMessage == null || this.inputMessage == "") {
      Swal.fire({
        icon: 'error',
        title: 'El mensaje, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    this._loadingService.show("Enviando mensaje...")

    let dataToSendEmail = {
      text: this.inputMessage,
      name: this.inputName,
      email: this.inputEmail
    }

    this._emailManager.sendContactEmail(dataToSendEmail).then(result =>{
      if(result){
        this.inputMessage = ""
        this.inputName = ""
        this.inputEmail = ""
        
        this._loadingService.hide()
      }
    })
  }

}
