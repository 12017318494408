<div class="page-title-area">
  <div class="container">
    <div class="page-title-item">
      <h2>Registro de usuario</h2>
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><span></span></li>
        <li>Registro de usuario</li>
      </ul>
    </div>
  </div>
</div>

<section class="register-area register-area-two pt-100 pb-70">
  <div class="register-container">
    <div>
      <form id="contactForm">
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="text" [(ngModel)]="inputName" name="name" class="form-control" placeholder="Nombre*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="text" [(ngModel)]="inputSurname" name="surname" class="form-control" placeholder="Apellidos*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <label>Fecha de Nacimiento</label>
              <input type="date" class="form-control" [(ngModel)]="inputBirthday" name="birthday"
                placeholder="Fecha de Nacimiento*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="text" [(ngModel)]="inputNickname" name="nickname" class="form-control"
                placeholder="Apodo*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="text" [(ngModel)]="inputEmail" name="email" class="form-control" placeholder="Email*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="text" [(ngModel)]="inputRepeatEmail" name="repeat email" class="form-control" placeholder="Repetir Email*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <input type="password" name="password" [(ngModel)]="inputPassword" class="form-control"
                placeholder="Password*">
            </div>
          </div>

          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <label>Foto de perfil</label>
              <input type="file" (change)="handleUpload($event)" class="form-control" name="photo">
            </div>
          </div>
          <div class="toggles-container">
            <div class="toggle">
              <label for="aceptTermsOfUse">Acepto los <a routerLink='/terms-of-use'>términos de uso</a></label>
              <ng-switchery [(ngModel)]="aceptTermsOfUse" name="aceptTermsOfUse"></ng-switchery>
            </div>

            <div class="toggle">
              <label for="aceptSendNotifications">Acepto recibir notificaciones comerciales</label>
              <ng-switchery [(ngModel)]="aceptSendNotifications" name="aceptSendNotifications"></ng-switchery>
            </div>
          </div>

        </div>

      </form>

      <div class="buttons-container">
        <a class="template-btn btn-black text-align-center btn-send" (click)="doNativeRegister()">Enviar</a>
        <a class="template-btn btn-black text-align-center btn-cancel" (click)="goToHome()">Cancelar</a>
      </div>
    </div>
  </div>
</section>