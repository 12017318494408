import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class SessionCreditConsolidatedBackendService {
    public httpOptions: HttpHeaders
    private _sessionCreditConsolidatedEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._sessionCreditConsolidatedEndPoint = environment.APIEndPointBackend + environment.SessionCreditConsolidated
    }

    /* CREATE SESSION CREDIT CONSOLIDATED */
    public createSessionCreditConsolidated(sessionData): Observable<any> {

        return this._httpClient.post(this._sessionCreditConsolidatedEndPoint, sessionData)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when create session credit consolidated: ", error)
                    return error
                })
            )
    }

    /* GET DONATION BETWEEN DATES */
  public getCreditConsolidatedBetweenDates(dates): Observable<any> {
    return this._httpClient.post(this._sessionCreditConsolidatedEndPoint + environment.GetSessionCreditConsolidatedBetweenDates, dates)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error in Session Credit Consolidated between dates: ", error)
          return error
        })
      )
  }

}