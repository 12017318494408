import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators";
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class EmailBackendService {
    public httpOptions: HttpHeaders
    private _emailEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._emailEndPoint = environment.APIEndPointBackend + environment.Email
    }

    /* SEND EMAIL TO RECOVER PASSWORD */
    public sendEmailToRecoverPassword(email): Observable<any> {

        return this._httpClient.get(this._emailEndPoint + environment.SendEmailRecoverPassword + email)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when send email to revocer password: ", error)
                    return error
                })
            )
    }

    /* SEND CONTACT EMAIL */
    public sendContactEmail(dataToSendEmail): Observable<any> {

        return this._httpClient.post(this._emailEndPoint, dataToSendEmail)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when send contact email: ", error)
                    return error
                })
            )
    }

     /* SEND PRIVATE SESSION BOOKED EMAIL */
     public sendPrivateSessionBookedEmail(dataToSendPrivateSessionBookedEmail): Observable<any> {

        return this._httpClient.post(this._emailEndPoint + environment.SendEmailBookedPrivateSession, dataToSendPrivateSessionBookedEmail)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when send private session booked email: ", error)
                    return error
                })
            )
    }

}