import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { SeersBackendService } from '../../../services/seers-backend.service'

import { SeerModel } from '../../../models/seers/seers.model'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { Utils } from 'src/app/utilities/utils'

@Component({
    selector: 'app-seers-list',
    templateUrl: './seers-list.component.html',
    styleUrls: ['./seers-list.component.scss']
})
export class SeersListComponent implements OnInit {

    public seersIsAvailable: boolean
    public seers: SeerModel[]
    public storageAccountImagesPath: string
    private seersAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _seersBackendService: SeersBackendService,
        private _loadingService: LoadingService,
        private _utils: Utils
    ) { }

    ngOnInit(): void {
        this._loadingService.show()
        this.storageAccountImagesPath = this._utils.getAzureStorageAccountPathForImages()
        this.getAllSeers()
    }

    ngOnDestroy() {
        if (this.seersAPI != null) {
            this.seersAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getAllSeers(){
        this.seersAPI = this._seersBackendService.getAllSeers()
          .pipe(takeUntil(this.destroy$))
          .subscribe((seerModel: SeerModel[]) => {
              this.seers = seerModel
              this.seersIsAvailable = true
              this._loadingService.hide()
          },
          error => {
            console.log("Response not success: ", <any>error)
          });
      }

}
