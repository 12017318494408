import { Component, OnInit } from '@angular/core'
import { Router } from "@angular/router"

import { RoomModel } from '../../../models/rooms/room.model'

import { Utils } from '../../../utilities/utils'

import { CookieService } from 'ngx-cookie-service'

import Swal from 'sweetalert2'
import { LiveRoomModel } from 'src/app/models/live-room/live-room.model'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { RoomManager } from 'src/app/managers/room.manager'
import { LiveRoomManager } from 'src/app/managers/live-room.manager'
import { SeerManager } from 'src/app/managers/seer.manager'
import { SessionsManager } from 'src/app/managers/sessions.manager'
import { SessionsModel } from 'src/app/models/sessions/sessions.model'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

  public rooms: RoomModel[]
  public roomsAreAvailable: boolean
  public seerRooms: RoomModel[]
  public haveRooms: boolean = false
  public currentUser
  public typeUser
  public seerData
  public usersUploadAudio: number
  public showDate: boolean
  private storageAccountImagesPath: string
  private liveRoomUser: LiveRoomModel = null

  constructor(
    private _utils: Utils,
    private _cookieService: CookieService,
    private _loadingService: LoadingService,
    private _router: Router,
    private _roomManager: RoomManager,
    private _liveRoomManager: LiveRoomManager,
    private _seerManager: SeerManager,
    private _sessionManager: SessionsManager
  ) { }

  ngOnInit(): void {
    this._loadingService.show()

    this.storageAccountImagesPath = this._utils.getAzureStorageAccountPathForImages()
    
    if (this._utils.IsJsonString(this._cookieService.get('User'))) {
      this.currentUser = JSON.parse(this._cookieService.get('User'));
      this.typeUser = JSON.parse(this._cookieService.get("Type"))
    }

    this.getAllRooms()
  }

  getAllRooms() {
    this._roomManager.getAllRooms().then(result => {
      if (result) {
        this.seerRooms = result as RoomModel[]

        this.seerRooms.sort(({ usersWithAudioUpload: A }, { usersWithAudioUpload: B }) => Number(B) - Number(A))

        if (this.seerRooms.length > 0) {
          this.haveRooms = true

          this.seerRooms.forEach(currentRoom => {
            currentRoom.seerPhotoURL = this.storageAccountImagesPath.concat(currentRoom.seerPhotoPath)
            this._loadingService.hide()
          })
        }

        else {
          this._loadingService.hide()
        }
      }
    })
  }

  getUsersUploadAudio(sessionId) {
    this._sessionManager.getSessionById(sessionId).then(result => {
      if (result) {
        let session = result as SessionsModel

        return session.usersWithAudioUpload
      }
    })
  }

  getDataForUserInRoom(idRoom) {
    if (this.typeUser == "2") {
      this.logicToGoSession()

      return
    }

    this._liveRoomManager.getLiveRoomUserByIdRoom(this.currentUser.id, idRoom).then(result => {
      if (result) {
        this.liveRoomUser = result as LiveRoomModel
        this._loadingService.hide()

        this.logicToGoSession()
      }
      else {
        this.logicToGoSession()
      }
    })
  }

  goToSession(roomId, roomState, seerId) {

    if (this.typeUser == "2" && this.currentUser.id != seerId) {
      Swal.fire({
        icon: 'error',
        title: 'La sala a la que intentas acceder, pertenece a otro maestro. Disculpa las molestias.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    if (!roomState) {
      Swal.fire({
        icon: 'error',
        title: 'La sala se encuentra actualmente cerrada. Disculpa las molestias.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    if (this.typeUser != "2" && (this.currentUser == undefined || this.currentUser == null)) {
      Swal.fire({
        icon: 'error',
        title: 'Para acceder a la sala, debes estar identificado',
        showConfirmButton: false,
        timer: 4000
      })

      this._router.navigate(['login'])

      return
    }

    this._cookieService.set("Room", JSON.stringify(roomId), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax")

    this._loadingService.show()

    this.getDataForUserInRoom(roomId)
  }

  private logicToGoSession() {
    let totalQuantityDonate = 0
    if (this.liveRoomUser != null) {
      totalQuantityDonate = this.liveRoomUser.totalQuantityDonate
    }

    if (this.typeUser != "2" && (this.currentUser.nickname == null)) {
      Swal.fire({
        icon: 'error',
        title: 'Para acceder a la sala, debes disponer de nickname en tu perfil',
        showConfirmButton: false,
        timer: 4000
      })

      this._router.navigate(['user-dashboard'])

      return
    }

    if (this.typeUser != "2" && (this.currentUser.birthday == null)) {
      Swal.fire({
        icon: 'error',
        title: 'Para acceder a la sala, debes disponer de fecha de nacimiento en tu perfil',
        showConfirmButton: false,
        timer: 4000
      })

      this._router.navigate(['user-dashboard'])

      return
    }

    let userAge = this._utils.getAge(this.currentUser.birthday)

    if (userAge < 18) {
      Swal.fire({
        icon: 'error',
        title: 'Para acceder a la plataforma, debes tener al menos 18 años.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    if (this.typeUser != "2" && (totalQuantityDonate + this.currentUser.credit) < 5) {
      Swal.fire({
        icon: 'error',
        title: 'Para acceder a la sala, debes disponer de 5€ o más en tu cartera',
        showConfirmButton: false,
        timer: 4000
      })

      this._router.navigate(['user-dashboard'])

      return
    }

    window.location.href = environment.OnlineSessionUrl
  }

}
