import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { RoomModel } from '../models/rooms/room.model'
import { RoomsBackendService } from '../services/rooms-backend.service'

@Injectable()

export class RoomManager {

    private roomsAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _cookieService: CookieService,
        private _roomsBackendService: RoomsBackendService
    ) { }

    ngOnDestroy() {
        if (this.roomsAPI != null) {
            this.roomsAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getRoom(idRoom) {
        return new Promise((resolve) => {
            this.roomsAPI = this._roomsBackendService.getRoom(idRoom)
                .pipe(takeUntil(this.destroy$))
                .subscribe((room: any) => {
                    console.log("Get room retrieved success")

                    resolve(room)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });

        })
    }

    updateRoom(idRoom, roomData) {
        return new Promise((resolve) => {
            this.roomsAPI = this._roomsBackendService.updateRoom(idRoom, roomData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((room: any) => {
                    console.log("Update room success")
                    Swal.fire({
						icon: 'success',
						title: "Sala actualizada correctamente",
						showConfirmButton: false,
						timer: 4000
					})
                    
                    resolve(room)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });

        })
    }

    getAllRooms(email = "") {
        return new Promise((resolve) => {
            this.roomsAPI = this._roomsBackendService.getRooms(email)
                .pipe(takeUntil(this.destroy$))
                .subscribe((seerRooms: RoomModel[]) => {
                    console.log("All Rooms retrieved successfully")

                    resolve(seerRooms)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })

    }

    deleteRoomById(idRoom) {
        return new Promise((resolve) => {
            this.roomsAPI = this._roomsBackendService.deleteRoomById(idRoom)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sala eliminada correctamente',
                            showConfirmButton: false,
                            timer: 4000
                        })
                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });

        })
    }

    createRoom(roomModel){
        return new Promise((resolve) => {
            this.roomsAPI = this._roomsBackendService.createRoom(roomModel)
			.pipe(takeUntil(this.destroy$))
			.subscribe((roomModel: RoomModel) => {
				if (roomModel != null && (roomModel.id != undefined && roomModel.id != "")) {
					console.log("New Room is Created Successfully")

					Swal.fire({
						icon: 'success',
						title: "Nueva sala creada correctamente",
						showConfirmButton: false,
						timer: 4000
					})

					resolve(true)
				}
			},
				error => {
					console.log("Response not success: ", <any>error)
                    resolve(false)
				});
        })
    }
}