import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager'

@Injectable()
export class GTMUtils {
    
    constructor(private _gtmService: GoogleTagManagerService){}

    sendUserId(idUser, userType) {
        const dataToSend = {
            idUser: idUser,
            userType: userType
        }

        this._gtmService.pushTag(dataToSend)
    }
    
}