import { Component, OnInit } from '@angular/core'
import { Router } from "@angular/router"

import { CookieService } from 'ngx-cookie-service'

import { Utils } from '../../../utilities/utils'

import * as shajs from 'sha.js'

import Swal from 'sweetalert2'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { UserManager } from 'src/app/managers/user.manager'
import { CompressImageService } from 'src/app/services/images/compress-image.service'
import { take } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service'
import { UserModel } from 'src/app/models/users/user.model'


@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public inputName: string
  public inputSurname: string
  public inputBirthday: string
  public inputNickname: string
  public inputEmail: string
  public inputRepeatEmail: string
  public inputPassword: string
  public inputPhoto
  public currentUser
  public aceptTermsOfUse = false
  public aceptSendNotifications = true
  private datalayer = []
  private extension: string = null
  private uploadConfig: UploadConfig

  constructor(
    private _router: Router,
    private _cookieService: CookieService,
    private _utils: Utils,
    private _loadingService: LoadingService,
    private _userManager: UserManager,
    private _compressImageService: CompressImageService,
    private _blobService: BlobService
  ) { }

  ngOnInit(): void {

    if (this._utils.IsJsonString(this._cookieService.get('User'))) {

      this.currentUser = JSON.parse(this._cookieService.get('User'));

      if (this.currentUser.email != null) {
        this._router.navigate(['/user-dashboard'])
      }
    }
  }

  doNativeRegister() {

    if (this.inputName == undefined || this.inputName == "") {
      Swal.fire({
        icon: 'error',
        title: 'El nombre del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }


    if (this.inputSurname == null || this.inputSurname == "") {
      Swal.fire({
        icon: 'error',
        title: 'El apellido del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputBirthday == null || this.inputBirthday == "") {
      Swal.fire({
        icon: 'error',
        title: 'La fecha de nacimiento del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    let userAge = this._utils.getAge(this.inputBirthday)

    if (userAge < 18) {
      Swal.fire({
        icon: 'error',
        title: 'Para registrarte, debes tener 18 años o más.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    if (this.inputNickname == null || this.inputNickname == "") {
      Swal.fire({
        icon: 'error',
        title: 'El Apodo del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputEmail == null || this.inputEmail == "") {
      Swal.fire({
        icon: 'error',
        title: 'El email del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputRepeatEmail == null || this.inputRepeatEmail == "") {
      Swal.fire({
        icon: 'error',
        title: 'Repetir el email del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputRepeatEmail.toLowerCase().trim() != this.inputEmail.toLowerCase().trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Los emails no coinciden',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (!this._utils.validateEmail(this.inputEmail) || !this._utils.validateEmail(this.inputRepeatEmail)) {
      Swal.fire({
        icon: 'error',
        title: 'El email no tiene un formato correcto. Por favor, revísalo',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputPassword == null || this.inputPassword == "") {
      Swal.fire({
        icon: 'error',
        title: 'La password del usuario, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (!this.aceptTermsOfUse) {
      Swal.fire({
        icon: 'error',
        title: 'Debes aceptar los terminos de uso de TOT Online',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    this._loadingService.show()

    let hashedPassword = shajs('sha256').update(this.inputPassword).digest('hex')

    let userToRegister = {
      name: this.inputName.concat(" ").concat(this.inputSurname).trim(),
      firstName: this.inputName.trim(),
      lastName: this.inputSurname.trim(),
      birthday: this.inputBirthday,
      nickname: this.inputNickname.trim(),
      email: this.inputEmail.trim(),
      password: hashedPassword,
      aceptTermsOfUse: this.aceptTermsOfUse,
      aceptSendNotifications: this.aceptSendNotifications,
    }

    this._userManager.registerNativeUser(userToRegister).then(response => {
      
      if (response) {
        let userData = response as UserModel

        if (this.extension == null && this.inputPhoto == null) {
          this._loadingService.hide()
          this._router.navigate(["/login"])
        }
        else {
          this._loadingService.show()
          this.uploadImageToAzure(userData.id)
        }

      }
    })
  }

  handleUpload(event) {

    const file = event.target.files[0]
    this.extension = event.target.files[0].name.split(".").pop()

    this._compressImageService.compress(file)
      .pipe(take(1))
      .subscribe(compressedImage => {
        this.inputPhoto = compressedImage
      })
  }

  goToHome() {
    this._router.navigate(["/"])
  }

  private uploadImageToAzure(userId) {

    const Config: UploadParams = {
      sas: environment.AzureSASTokenImage,
      storageAccount: environment.AzureStorageAccountName,
      containerName: environment.BlobContainerImage
    }

    const fileName = userId.concat(".").concat(this.extension)
    
    const baseUrl = this._blobService.generateBlobUrl(Config, fileName)
    
    this.uploadConfig = {
      baseUrl: baseUrl,
      sasToken: Config.sas,
      file: this.inputPhoto,
      complete: () => {
        let userData = {
          photoPath: fileName
        }
        this._userManager.updateUser(userId, userData).then(result => {
          if (result) {
            this._loadingService.hide()
            this._router.navigate(["/login"])
          }
        })
      },
      error: (err) => {
        console.log('Error:', err);
        Swal.fire({
          icon: 'error',
          title: 'Ocurrió algun error guardando la imagen. Inténtalo de nuevo. De persistir, por favor, ponte en contacto con: contacto@totonline.es',
          showConfirmButton: false,
          timer: 4000
        })
      }
    }

    this._blobService.upload(this.uploadConfig);
  }
}
