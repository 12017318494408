<div class="page-title-area">
    <div class="container">
      <div class="page-title-item">
        <h2>Reestabler contraseña</h2>
        <ul>
          <li><a routerLink="/">Inicio</a></li>
          <li><span></span></li>
          <li>Reestablecer contraseña</li>
        </ul>
      </div>
    </div>
  </div>
  
  <section class="recover-password-area pt-100 pb-70">
    <div class="recover-password-container">
      <div>
        <form id="contactForm">
          <div class="row">
            <div class="col-sm-12 col-lg-12" *ngIf="token == undefined">
              <div class="form-group">
                <input type="text" [(ngModel)]="inputEmail" name="name" class="form-control" placeholder="Por favor, introduce tu email">
              </div>
            </div>
            <ng-container *ngIf="token != undefined">
              <div class="col-sm-12 col-lg-12">
                <div class="form-group">
                  <input type="password" [(ngModel)]="inputNewPassword" name="password" class="form-control" placeholder="Introduce la nueva contraseña">
                </div>
              </div>
              <div class="col-sm-12 col-lg-12">
                <div class="form-group">
                  <input type="password" [(ngModel)]="inputRepeatNewPassword" name="repeat-password" class="form-control" placeholder="Repite la nueva contraseña">
                </div>
              </div>
            </ng-container>
          </div>
        </form>
        <div class="buttons-container">
          <a class="template-btn btn-black text-align-center btn-send" (click)="doOperation()">Enviar</a>
          <a class="template-btn btn-black text-align-center btn-cancel" (click)="goToHome()">Cancelar</a>
        </div>
      </div>
    </div>  
  </section>  