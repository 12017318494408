<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area blog-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let post of posts">
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item">
                        <a href="/blog-details?id={{post.id}}">
                            <div class="blog-image" [ngStyle]="{'background-image': 'url(' + post.image + ')'}"
                                alt="Blog"></div>
                        </a>
                        <div class="blog-bottom">
                            <span>{{post.date | date: 'dd/MM/yyyy'}}</span>
                            <h3><a href="/blog-details?id={{post.id}}">{{post.title}}</a></h3>
                            <p>{{post.subtitle}}</p>
                            <a class="blog-btn" href="/blog-details?id={{post.id}}">Leer más</a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>