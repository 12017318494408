import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { YouTubeService } from '../services/gcp/youtube.service';

@Injectable()

export class YoutubeManager {

    private youTubeAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _youTubeService: YouTubeService
    ) { }

    ngOnDestroy() {
        if (this.youTubeAPI != null) {
            this.youTubeAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getVideosFromYouTubeChannel(channelId) {
        return new Promise((resolve) => {
            this.youTubeAPI = this._youTubeService.getVideosFromYouTubeChannel(channelId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    resolve(response)
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }
}