import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment'

@Injectable()

export class SeerSchedulePrivateSessionBackendService {
  public httpOptions: HttpHeaders
  private _seerSchedulePrivateSessionEndPoint: string

  constructor(
    public _httpClient: HttpClient
  ) {
    this._seerSchedulePrivateSessionEndPoint = environment.APIEndPointBackend + environment.SeerSchedulePrivateSession
  }

  /* CREATE SEER SCHEDULE PRIVATE SESSION */
  public createSeerSchedulePrivateSession(seerSchedulePrivateSessionData): Observable<any> {
    return this._httpClient.post(this._seerSchedulePrivateSessionEndPoint, seerSchedulePrivateSessionData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error in create seer schedule private session: ", error)
          return error
        })
      )
  }

  /* GET SEER SCHEDULE PRIVATE SESSION BY SEER ID OR NOT */
  public getSeerSchedulePrivateSessionBySeerId(seerId): Observable<any> {
    let request = this._seerSchedulePrivateSessionEndPoint

    if (seerId != "") {
      request = request + environment.GetAllSeerSchedulePrivateRooms + seerId
    }
    
    return this._httpClient.get(request)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error in get seer schedule private session by seerId: ", error)
          return error
        })
      )
  }

  /* DELETE PRIVATE SESSION BY ID */
  public deletePrivateSessionById(privateSessionId): Observable<any> {
    return this._httpClient.request("delete", this._seerSchedulePrivateSessionEndPoint, { body: { id: privateSessionId } })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when delete private session: ", error.error)
          return error
        })
      )
  }

   /* UPDATE PRIVATE SESSION */
   public updatePrivateSession(idPrivateSession, seerSchedulePrivateSessionData): Observable<any> {
        
    return this._httpClient.put(this._seerSchedulePrivateSessionEndPoint + idPrivateSession, seerSchedulePrivateSessionData)
        .pipe(
            map((data: any) => {
                return data;
            }),
            catchError(error => {
                console.log("Error when update post: ", error)
                return error
            })
        )
}

}