import { Injectable } from '@angular/core'
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as fs from 'file-saver'
import { UserManager } from 'src/app/managers/user.manager';
import { Utils } from 'src/app/utilities/utils';
import { LoadingService } from '../loading/loading.service';

@Injectable({
    providedIn: 'root',
})
export class ExcelService {

    constructor(
        private _utils: Utils,
        private _loadingService: LoadingService,
        private _userManager: UserManager
    ) { }

    public generateExcelForBilling(dateEnd, nameSession, data) {
        this._loadingService.show("Generando Excel...")

        const title = "Informe de facturación TOT"
        const headers = [
            "Fecha consolidación",
            "Valor consolidado",
            "Id de usuario",
            "Nombre de usuario"]

        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet(title)

        worksheet.mergeCells('A1', 'B3');
        let titleRow = worksheet.getCell('A1');
        titleRow.value = `Facturación sesión con día de cierre: ${this._utils.formatDate(dateEnd)} y para la sala: ${nameSession}`
        titleRow.font = {
            name: 'Calibri',
            size: 16,
            underline: 'single',
            bold: true,
            color: { argb: '0085A3' }
        }
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

        /*Add Image
        let myLogoImage = workbook.addImage({
            base64: excelData.imgBase64,
            extension: 'png',
        });ee
        worksheet.mergeCells('A1:B4');
        worksheet.addImage(myLogoImage, 'A1:B4');*/

        worksheet.addRow([]);

        let headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '4167B8' },
                bgColor: { argb: '' }
            }
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
                size: 12
            }
        })

        let rowToAdd = []
        data.forEach(d => {
            rowToAdd = [
                this._utils.formatDate(d.createdAt),
                d.valueConsolidated.toString().concat("€"),
                d.idUser,
                d.nameUser]
            worksheet.addRow(rowToAdd);
        })

        worksheet.columns.forEach(function (column, i) {
            var maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });

            column.width = maxLength < 10 ? 10 : maxLength;
        });

        worksheet.addRow([]);

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, title + '.xlsx');
            this._loadingService.hide()
        })

    }

    public generateExcelForRedeemedPromotions(dateEnd, nameSession, data) {
        this._loadingService.show("Generando Excel...")

        const title = "Informe promociones TOT"
        const headers = [
            "Id de promoción",
            "Id de usuario",
            "Id de sala",
            "Valor promoción"]

        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet(title)

        worksheet.mergeCells('A1', 'B3');
        let titleRow = worksheet.getCell('A1');
        titleRow.value = `Uso de códigos promocionales en la sala: ${nameSession} con fecha de cierre: ${this._utils.formatDate(dateEnd)}`
        titleRow.font = {
            name: 'Calibri',
            size: 16,
            underline: 'single',
            bold: true,
            color: { argb: '0085A3' }
        }
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

        /*Add Image
        let myLogoImage = workbook.addImage({
            base64: excelData.imgBase64,
            extension: 'png',
        });ee
        worksheet.mergeCells('A1:B4');
        worksheet.addImage(myLogoImage, 'A1:B4');*/

        worksheet.addRow([]);

        let headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '4167B8' },
                bgColor: { argb: '' }
            }
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
                size: 12
            }
        })

        let rowToAdd = []
        data.forEach(d => {
            rowToAdd = [
                d.idPromotion,
                d.idUser,
                d.idRoom,
                d.valuePromotion.toString().concat("€")]
            let row = worksheet.addRow(rowToAdd);
        }
        );

        worksheet.columns.forEach(function (column, i) {
            var maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });

            column.width = maxLength < 10 ? 10 : maxLength;
        });

        worksheet.addRow([]);

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, title + '.xlsx');
            this._loadingService.hide()
        })

    }

}