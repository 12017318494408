import { Component, OnInit } from '@angular/core';

import { SeerModel } from '../../../models/seers/seers.model'
import { PostsModel } from 'src/app/models/posts/posts-model'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { PostsManager } from 'src/app/managers/posts.manager'
import { NavbarComponent } from '../../common/navbar/navbar.component'
import { SeerManager } from 'src/app/managers/seer.manager'
import { Utils } from 'src/app/utilities/utils'

@Component({
  selector: 'app-home-three',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public isUserLogged: boolean
  public seers: SeerModel[]
  public posts: PostsModel[]
  public seersIsAvailable: boolean
  public havePosts: boolean
  public storageAccountImagesPath: string

  constructor(
    private _loadingService: LoadingService,
    private _postsManager: PostsManager,
    private _seerManager: SeerManager,
    private _utils: Utils
  ) { }

  ngOnInit(): void {
    this._loadingService.show()
    this.storageAccountImagesPath = this._utils.getAzureStorageAccountPathForImages()
    this.getData()
    this.isUserLogged = NavbarComponent.userIsLogged
  }

  getData() {
   
    this._seerManager.getAllSeers().then(result => {
      if (result) {
        this.seers = result as SeerModel[]
        this.seersIsAvailable = true
        this.getLatestPost()
      }
    })
  }

  private getLatestPost() {
    this._postsManager.getLatestPosts().then(result => {
      if (result) {
        this.posts = result as PostsModel[]

        if (this.posts.length > 0) {
          this.havePosts = true
        }

        this._loadingService.hide()
      }
    })
  }



}
