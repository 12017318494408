import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../environments/environment';

@Injectable()

export class RoomsBackendService {
  public httpOptions: HttpHeaders
  private _roomsEndPoint: string

  constructor(
    public _httpClient: HttpClient
  ) {
    this._roomsEndPoint = environment.APIEndPointBackend + environment.Rooms
  }

  /* CREATE ROOM */
  public createRoom(roomData): Observable<any> {

    return this._httpClient.post(this._roomsEndPoint, roomData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when create new room: ", error.error)
          return error
        })
      )
  }

  // GET ALL ROOMS 
  public getRooms(email = ""): Observable<any> {
    let request = this._roomsEndPoint

    if (email != "") {
      request = request + environment.GetRoomsByEmail + email
    }

    return this._httpClient.get(request)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when retrieve all rooms: ", error.error)
          return error
        })
      )
  }

  // DELETE ROOM BY ID
  public deleteRoomById(roomId): Observable<any> {

    return this._httpClient.request("delete", this._roomsEndPoint, { body: { id: roomId } })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when delete seer room: ", error.error)
          return error
        })
      )
  }

  // UPDATE ROOM 
  public updateRoom(idRoom, roomData): Observable<any> {

    return this._httpClient.put(this._roomsEndPoint + idRoom, roomData)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when updating room: ", error)
          return error
        })
      )
  }

  // GET DONATE ROOM 
  public getRoom(idRoom): Observable<any> {

    return this._httpClient.get(this._roomsEndPoint + idRoom)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(error => {
          console.log("Error when get max donate room: ", error)
          return error
        })
      )
  }

}