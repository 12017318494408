import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UserModel } from 'src/app/models/users/user.model'
import Swal from 'sweetalert2'

import * as shajs from 'sha.js';
import { CookieService } from 'ngx-cookie-service'
import { Utils } from 'src/app/utilities/utils'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { EmailManager } from 'src/app/managers/email.manager'
import { UserManager } from 'src/app/managers/user.manager'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public inputEmail: string
  public inputNewPassword: string
  public inputRepeatNewPassword: string
  public showInputsToChangePassword: boolean
  public token: string
  private idUser: string
  private emailUser: string

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _cookieService: CookieService,
    private _loadingService: LoadingService,
    private _utils: Utils,
    private _emailManager: EmailManager,
    private _userManager: UserManager
  ) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this.token = atob(params['token'])

      this.idUser = this.token.split("|")[0]
      this.emailUser = this.token.split("|")[1]
    });
  } 

  doOperation() {
    if (this.token == null || this.token == undefined) {
      this.operationSendEmail()
    }
    else {
      this.operationChangePassword()
    }
  }

  private operationSendEmail() {
    if (this.inputEmail == null || this.inputEmail == "") {
      Swal.fire({
        icon: 'error',
        title: 'El email, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })  

      return;
    }

    this._loadingService.show()

    this._emailManager.sendEmailToRecoverPassword(this.inputEmail.toLowerCase()).then(result => {
      if (result) {
        let currentUser: UserModel = result as UserModel

        this._loadingService.hide()

        Swal.fire({
          title: currentUser.firstName + ', se ha enviado un email a su correo con instrucciones para cambiar su contraseña.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Entendido',
        }).then((result) => {
          if (result.isConfirmed) {
            this._utils.redirectTo(this._router, "home")
          }
        })
      }
    })
  }

  private operationChangePassword() {

    if (this.inputNewPassword == null || this.inputNewPassword == "") {
      Swal.fire({
        icon: 'error',
        title: 'La contraseña, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputRepeatNewPassword == null || this.inputRepeatNewPassword == "") {
      Swal.fire({
        icon: 'error',
        title: 'Repetir la contraseña, es un campo obligatorio',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputNewPassword !== this.inputRepeatNewPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Las contraseñas no coinciden. Inténtalo de nuevo.',
        showConfirmButton: false,
        timer: 4000
      })

      return
    }

    let hashedPassword = shajs('sha256').update(this.inputNewPassword).digest('hex')
    let dataToUpdate = {
      password: hashedPassword
    }

    this._userManager.updateUser(this.idUser, dataToUpdate).then(result => {
      if (result) {
        let currentUser: UserModel = result as UserModel

        Swal.fire({
          icon: 'success',
          title: currentUser.firstName + ', tu contraseña ha sido actualizada correctamente',
          showConfirmButton: false,
          timer: 4000
        })

        this._cookieService.set('User', JSON.stringify(currentUser), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax");
        this._utils.redirectTo(this._router, "login")
      }
    })

  }

  goToHome() {
    this._router.navigate(["/"])
  }


}
