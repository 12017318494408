import { Component, OnInit } from '@angular/core'
import { Router } from "@angular/router"

import { CookieService } from 'ngx-cookie-service';

import { Utils } from '../../../utilities/utils'
import { GTMUtils } from '../../../utilities/gtm.utils'

import { UserModel } from '../../../models/users/user.model'
import { SeerModel } from '../../../models/seers/seers.model'

import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login'

import { UserManager } from 'src/app/managers/user.manager'

import * as shajs from 'sha.js'

import Swal from 'sweetalert2'
import { SeerManager } from 'src/app/managers/seer.manager'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public isLoggedin: boolean = false;
  public inputEmail: string
  public inputPassword: string
  public typeUser: string
  public currentUser

  constructor(
    private _formBuilder: FormBuilder,
    private _socialAuthService: SocialAuthService,
    private _router: Router,
    private _cookieService: CookieService,
    private _utils: Utils,
    private _gtmUtils: GTMUtils,
    private _userManager: UserManager,
    private _seerManager: SeerManager
  ) { }

  ngOnInit(): void {

    if (this._utils.IsJsonString(this._cookieService.get('User'))) {

      this.typeUser = JSON.parse(this._cookieService.get("Type"))

      this.currentUser = JSON.parse(this._cookieService.get('User'));

      if (this.currentUser.email != null) {
        this._router.navigate(['/user-dashboard'])
      }
    }

    this.loginForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this._socialAuthService.authState.subscribe((user) => {
      this.processSocialLogin(user)
    });
  }

  private processSocialLogin(user) {
    this.currentUser = user
    this.isLoggedin = (user != null);

    if (this.currentUser != null) {

      this._userManager.registerSocialUser(this.currentUser).then(result => {
        if (result) {
          let socialUserData = result as UserModel  
          
          this._cookieService.set('User', JSON.stringify(socialUserData), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax");
          this._cookieService.set('Type', JSON.stringify(socialUserData.rol), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax")

          this._gtmUtils.sendUserId(socialUserData.id, "user")

          this._utils.redirectTo(this._router, "user-dashboard")
        }
      })
    }
  }

  doNativeLogin() {

    if (this.inputEmail == null || this.inputEmail == "") {
      Swal.fire({
        icon: 'error',
        title: 'Para identificarte, debes introducir el email',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    if (this.inputPassword == null || this.inputPassword == "") {
      Swal.fire({
        icon: 'error',
        title: 'Para identificarte, debes introducir la password',
        showConfirmButton: false,
        timer: 4000
      })

      return;
    }

    let hashedPassword = shajs('sha256').update(this.inputPassword).digest('hex')

    this._userManager.loginNativeUser({ email: this.inputEmail.toLowerCase(), password: hashedPassword }).then(result => {
      if (result) {
        let userData = result as UserModel
        this._cookieService.set('User', JSON.stringify(userData), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax")

        this._cookieService.set('Type', JSON.stringify(userData.rol), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax")

        this._gtmUtils.sendUserId(userData.id, "user")

        this._router.navigate(['/user-dashboard']);
      }
      else {
        this._seerManager.loginNativeSeer({ email: this.inputEmail.toLowerCase(), password: hashedPassword }).then(result => {
          if (result) {
            let seerData = result as SeerModel
            this._cookieService.set('User', JSON.stringify(seerData), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax")
            this._cookieService.set('Type', JSON.stringify(seerData.rol), undefined, '/', environment.SiteDomainForPersistCookies, false, "Lax")

            this._gtmUtils.sendUserId(seerData.id, "seer")

            this._router.navigate(['/user-dashboard']);
          }
        })
      }
    })
  }

  doRegister() {
    this._router.navigate(["/register"])
  }

  loginWithGoogle(): void {
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  loginWithFacebook(): void {
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
