import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { map } from "rxjs/operators"
import { catchError } from 'rxjs/operators'
import { Observable } from "rxjs"
import { environment } from '../../../environments/environment'

@Injectable()

export class YouTubeService {
    public httpOptions: HttpHeaders
    private _youTubeAPIToSearchEndPoint: string

    constructor(
        public _httpClient: HttpClient
    ) {
        this._youTubeAPIToSearchEndPoint = environment.YouTubeAPIToSearchEndPoint
    }

    /* GET VIDEOS FROM YOUTUBE CHANNEL */
    public getVideosFromYouTubeChannel(channelId): Observable<any> {
        
        return this._httpClient.get(this._youTubeAPIToSearchEndPoint.concat(channelId).concat("&part=snippet,id&order=date&maxResults=1"))
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(error => {
                    console.log("Error when get Videos from YouTube channel: ", error)
                    return error
                })
            )
    }

}