<footer class="pt-100 pb-70">
    <div class="container footer">
        <div class="row">

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a class="footer-logo-img" routerLink="/"><img src="assets/img/tarot-logo.jpg" alt="Logo"></a>
                        
                        <ul>
                            <li><a href="https://www.facebook.com/TOTonline-106344058566599/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/totonline.es" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-link">
                        <h3>Documentos legales</h3>

                        <ul>
                            <li><a routerLink="/privacy-policy">Política de privacidad</a></li>
                            <li><a routerLink="/cookies-advice">Política de cookies</a></li>
                            <li><a routerLink="/legal-advice">Aviso legal</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-link">
                        <h3>Menú</h3>

                        <ul>
                            <li><a routerLink="/sessions">Sesiones</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Contacto</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-touch">
                        <h3>Contáctanos</h3>

                        <ul>
                            <li>
                                <i class='bx bxs-phone-call'></i>
                                <h4>Teléfono:</h4>
                                <a href="tel:+34648168213">+34 648 168 213</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <h4>Email:</h4>
                                <a href="mailto:contacto@totonline.es">contacto@totonline.es</a>
                            </li>
                            <li>
                                <i class='bx bxs-location-plus'></i>
                                <h4>Dirección:</h4>
                                <span>C/Santiago 84. 28822. Coslada. Madrid</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Copyright @2021 Diseñada & Desarrollada por <a href="https://unus.digital/"
                    target="_blank">UnusDigital</a></p>
        </div>
    </div>
</div>