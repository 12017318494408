import { DatePipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { bindCallback, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AzureManager } from '../managers/azure.manager';
import { UserModel } from '../models/users/user.model';

@Injectable()

export class Utils {

	constructor(
		private _azureManager: AzureManager
	) { }

	public IsJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}

		return true;
	}

	public redirectTo(router, uri: string) {
		router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			router.navigate([uri]))
	}

	public getAge(date) {
		var today = new Date()
		var birthDate = new Date(date)
		var age = today.getFullYear() - birthDate.getFullYear()
		var m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}
		return age
	}

	public parseDate(dateString: string): Date {
		if (dateString) {
			return new Date(dateString);
		}
		return null;
	}

	public formatDate(date: string) {
		let pipe = new DatePipe("en-US")

		return pipe.transform(date, 'short')
	}

	public validateEmail(email) {
		const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return regularExpression.test(String(email).toLowerCase())
	}

	public validatePhoneNumber(phoneNumber) {
		const regularExpression = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/
		return regularExpression.test(String(phoneNumber).toLowerCase())
	}

	public getIfUserIsSocial(userData: UserModel) {
		if (userData.provider.toLowerCase().trim() != "google") {
			return false
		}

		return true
	}

	public getAzureStorageAccountPathForImages() {
		return environment.AzureStorageAccountPath.concat(environment.BlobContainerImage).concat("/")
	}

	public getIfBrowserPermissionsAreGranted(permissionsParams) {
		return new Promise((resolve) => {
			navigator.mediaDevices.getUserMedia(permissionsParams)
				.then(function (stream) {
					if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
						//code for when none of the devices are available

						resolve(false)

					} else {
						// code for when both devices are available
						resolve(true)
					}
				})
				.catch(function (error) {
					// code for when there is an error
					resolve(false)
				});
		})
	}

}