import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './components/pages/login/login.component'
import { RegisterComponent } from './components/pages/register/register.component'
import { UserDashboardComponent } from './components/pages/user-dashboard/user-dashboard.component'
import { HomeComponent } from './components/pages/home/home.component'
import { FaqComponent } from './components/pages/faq/faq.component'
import { ErrorComponent } from './components/pages/error/error.component'
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component'
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component'
import { LegalAdviceComponent } from './components/pages/legal-advice/legal-advice.component'
import { PersonalDataAdviceComponent } from './components/pages/personal-data-advice/personal-data-advice.component'
import { HiringTermsAdviceComponent } from './components/pages/hiring-terms-advice/hiring-terms-advice.component'
import { CookiesAdviceComponent } from './components/pages/cookies-advice/cookies-advice.component'
import { AboutUsComponent } from './components/pages/about-us/about-us.component'
import { AboutMeComponent } from './components/pages/about-me/about-me.component'
import { SessionsComponent } from './components/pages/sessions/sessions.component'
import { BlogComponent } from './components/pages/blog/blog.component'
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component'
import { ContactComponent } from './components/pages/contact/contact.component'
import { SeersListComponent } from './components/pages/seers-list/seers-list.component'
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component'
import { TermsOfUseComponent } from './components/pages/terms-of-use/terms-of-use.component'
import { BookPrivateSessionComponent } from './components/pages/book-private-session/book-private-session.component'

const routes: Routes = [
    {path: '', component: HomeComponent}, 
    {path: 'home', component: HomeComponent},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'user-dashboard', component: UserDashboardComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'legal-advice', component: LegalAdviceComponent},
    {path: 'personal-data-advice', component: PersonalDataAdviceComponent},
    {path: 'hiring-terms-advice', component: HiringTermsAdviceComponent},
    {path: 'cookies-advice', component: CookiesAdviceComponent},
    {path: 'about-us', component: AboutUsComponent},
    {path: 'about-me', component: AboutMeComponent},
    {path: 'sessions', component: SessionsComponent},
    {path: 'blog', component: BlogComponent}, 
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'seers-list', component: SeersListComponent},
    {path: 'recover-password', component: RecoverPasswordComponent},
    {path: 'terms-of-use', component: TermsOfUseComponent},
    {path: 'book-private-session', component: BookPrivateSessionComponent},

    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes/*, { relativeLinkResolution: 'legacy' }*/)],
    exports: [RouterModule]
})
export class AppRoutingModule { }