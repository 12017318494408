import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { SessionCreditConsolidatedModel } from '../models/session-credit-consolidated/session-credit-consolidated.model'
import { SessionCreditConsolidatedBackendService } from '../services/session-credit-consolidated-backend.service'

@Injectable()

export class SessionCreditConsolidatedManager {

    private sessionCreditConsolidatedAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _sessionCreditConsolidatedBackendService: SessionCreditConsolidatedBackendService
    ) { }

    ngOnDestroy() {
        if (this.sessionCreditConsolidatedAPI != null) {
            this.sessionCreditConsolidatedAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    createSessionCreditConsolidated(sessionData) {
        return new Promise((resolve) => {
            this.sessionCreditConsolidatedAPI = this._sessionCreditConsolidatedBackendService.createSessionCreditConsolidated(sessionData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Session consolidated Created Successfully")
                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    });
        })
    }

    getCreditConsolidatedBetweenDates(sessionCreditConsolidatedDates) {
        return new Promise((resolve) => {
            this.sessionCreditConsolidatedAPI = this._sessionCreditConsolidatedBackendService.getCreditConsolidatedBetweenDates(sessionCreditConsolidatedDates)
					.pipe(takeUntil(this.destroy$))
					.subscribe((response: any) => {
						if (response.code == 200) {
							console.log("Session Credit Consolidated between dates retrieved Successfully")
							let sessionCreditConsolidatedData: SessionCreditConsolidatedModel[] = response.session_credit_consolidated_data

							resolve(sessionCreditConsolidatedData)
						}

					},
						error => {
							console.log("Response not success: ", <any>error)
                            resolve(false)
						});
        })
    }
}