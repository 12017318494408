<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/tarot-logo.jpg" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/tarot-logo.jpg" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item">
                            <a routerLink="/sessions" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Salas</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/book-private-session" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Reserva consulta privada</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Blog</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contacto</a>
                        </li>

                        <li class="nav-item show-responsive">
                            <a *ngIf="!retrieveIfUserIsLogged" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" routerlink="/login" href="/login">Iniciar
                                sesión</a>
                        </li>

                        <li class="nav-item show-responsive">
                            <a *ngIf="retrieveIfUserIsLogged" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" routerlink="/user-dashboard"
                                href="/user-dashboard">Mi cuenta</a>
                        </li>

                    </ul>

                    <div class="side-nav">
                        <a *ngIf="!retrieveIfUserIsLogged" _ngcontent-nrn-c16="" routerlink="/login"
                            [routerLinkActiveOptions]="{exact: true}" class="template-btn login-button"
                            ng-reflect-router-link="/login" href="/login">Iniciar sesión</a>

                        <div *ngIf="retrieveIfUserIsLogged" class="collapse navbar-collapse mean-menu"
                            id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a routerLink="/user-dashboard" class="nav-link dropdown-toggle">Mi cuenta <i
                                            class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item user-logged">
                                            Bienvenido:
                                            {{retrieveCurrentUser.firstName}}
                                            {{retrieveCurrentUser.lastName}}
                                        </li>
                                        <li class="nav-item"><a (click)="logOut()">Cerrar sesión</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>