import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from "rxjs/operators"
import { environment } from '../../../environments/environment'
import { OAuth2Service } from '../oauth2.service'

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private _oauth2Service: OAuth2Service,
	){}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		let token = this._oauth2Service.getToken()
		
		if(token && !req.url.includes(environment.BlobSuffix) && !req.url.includes("google")){
			const headers = this.addToken(req, token)

			return next.handle(headers).pipe(
				tap(evt => {
					if (evt instanceof HttpResponse) {
						console.log('---> status:', evt.status);
						console.log('---> filter:', req.params.get('filter'));
					}
				})
			);	
		}
		else {
            return next.handle(req);
        }
		
	}

	private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
    }
}