<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Consentimiento tratamiento de datos</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><span></span></li>
                <li>Consentimiento tratamiento de datos</li>
            </ul>
        </div>
    </div>
</div>

<section class="personal-data-advice-area pt-100">
    <div class="container">

        <h2 style="text-align:center;">Consentimiento para el tratamiento de datos</h2>
        <div style="text-align: justify;">
            <p style="text-align:center"><span style="font-size:14px"><br />
                    <strong>CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS PERSONALES</strong></span></p>

            <p style="text-align:left"></p>

            <p style="text-align: justify;"><span style="font-size:14px"><strong>DAVID LINARES GALÁN</strong> es el
                    Responsable del tratamiento de los datos personales del Usuario y le informa de que estos datos se
                    tratarán de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la
                    Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), por lo que se le facilita la siguiente información
                    del tratamiento:</span></p>

            <p style="text-align: justify;"><span style="font-size:14px"><strong>Fines y legitimación del
                        tratamiento</strong>: mantener una relación comercial (por interés legítimo del responsable,
                    art. 6.1.f GDPR) y envío de comunicaciones de productos o servicios (con el consentimiento del
                    interesado, art. 6.1.a GDPR).</span></p>

            <p style="text-align: justify;"><span style="font-size:14px"><strong>Criterios de conservación de los
                        datos</strong>: se conservarán durante no más tiempo del necesario para mantener el fin del
                    tratamiento o existan prescripciones legales que dictaminen su custodia y cuando ya no sea necesario
                    para ello, se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización de los
                    datos o la destrucción total de los mismos.<br />
                    <br />
                    <span style="font-size:14px"><strong>Comunicación de los datos</strong>: no se comunicarán los datos
                        a terceros, salvo obligación legal.</span></span></p>

            <p style="text-align: justify;"><span style="font-size:14px"><strong>Derechos que asisten al
                        Usuario</strong>: <br />
                    <span style="font-size:14px">- Derecho a retirar el consentimiento en cualquier momento. <br />
                        <span style="font-size:14px">- Derecho de acceso, rectificación, portabilidad y supresión de sus
                            datos, y de limitación u oposición a su tratamiento. <br />
                            <span style="font-size:14px">- Derecho a presentar una reclamación ante la Autoridad de
                                control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa
                                vigente.</span></span></span></span></p>

            <p style="text-align: justify;"><span style="font-size:14px"><strong>Datos de contacto para ejercer sus
                        derechos</strong>: </span><span style="font-size:14px"><br />
                    <span style="font-size:14px">DAVID LINARES GALÁN. C/ SANTIAGO, 84 - 28822 COSLADA (Madrid). E-mail:
                        contacto@totonline.es</span></span></p>
        </div>

    </div>
</section>