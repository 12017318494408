import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { SeerModel } from '../models/seers/seers.model'
import { AzureBackendService } from '../services/azure-backend.service'
import { DonationBackendService } from '../services/donation-backend.service'

@Injectable()

export class AzureManager {

    private azureAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _azureBackendservice: AzureBackendService
    ) { }

    ngOnDestroy() {
        if (this.azureAPI != null) {
            this.azureAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getSasToken(azureSasTokenData) {
        return new Promise((resolve) => {
            this.azureAPI = this._azureBackendservice.getSASToken(azureSasTokenData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Get Azure Sas Token data retrieved success")

                        resolve(response.sas_token.uri)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }
}