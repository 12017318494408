import { Component, OnInit, ViewChild } from '@angular/core'
import { SeerManager } from 'src/app/managers/seer.manager'
import { SeerModel } from 'src/app/models/seers/seers.model'

import { Utils } from 'src/app/utilities/utils'
import { PrivateSessionModel } from 'src/app/models/private-session/private-session.model'

import { loadCldr, L10n } from '@syncfusion/ej2-base'
import Swal from 'sweetalert2'
import * as moment from 'moment'

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal'
import { environment } from 'src/environments/environment'
import { LoadingService } from 'src/app/services/loading/loading.service'
import { CookieService } from 'ngx-cookie-service'
import { Router } from '@angular/router'
import { SeerSchedulePrivateSessionManager } from 'src/app/managers/seer-schedule-private-session.manager'
import { SeerSchedulePrivateSessionModel } from 'src/app/models/private-session/seer-schedule-private-session.model'
import { DurationCostModel } from 'src/app/models/private-session/duration-cost.model'
import { UserModel } from 'src/app/models/users/user.model'
import { PrivateSessionManager } from 'src/app/managers/private-session.manager'
import { SchedulePrivateSessionModel } from 'src/app/models/private-session/schedule-private-session.model'

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { PhonePrivateSessionModel } from 'src/app/models/private-session/phone-private-session.model'
import { EmailManager } from 'src/app/managers/email.manager'
import { MessagingManager } from 'src/app/managers/messaging.manager'

declare var require: any

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/es/ca-gregorian.json'),
  require('cldr-data/main/es/numbers.json'),
  require('cldr-data/main/es/timeZoneNames.json')
)

@Component({
  selector: 'app-book-private-session',
  templateUrl: './book-private-session.component.html',
  styleUrls: ['./book-private-session.component.scss']
})
export class BookPrivateSessionComponent implements OnInit {

  @ViewChild('calendarObj')
  public calendarObj: any;

  public minDate: Date = new Date("09/01/2022")
  public maxDate: Date = new Date("12/30/2100")
  public dateValue: Date = new Date()

  public seers: SeerModel[] = []
  public seerSchedulePrivateSession: SeerSchedulePrivateSessionModel
  public seerSchedulePrivateSessionSchedule: SchedulePrivateSessionModel[]
  public privateSessionHoursBussy = []
  public scheduleByDay: SchedulePrivateSessionModel
  public bookDurationAndCost: DurationCostModel
  public selectedSeer: SeerModel = null
  public selectedHour = []
  public stepSelected = 0
  public dataToBookPrivateSession = []
  public allTimesToBookSession = []
  public storageAccountImagesPath: string
  public privateSessionData: PrivateSessionModel = new PrivateSessionModel()
  public labelForToggleAllowTraduction = "¿Permitir traducción?"
  public toggleAllowTraductionPrivateSession
  public userPhone: string = null
  public payPalConfig: IPayPalConfig
  public SearchCountryField = SearchCountryField
	public CountryISO = CountryISO
  public PhoneNumberFormat = PhoneNumberFormat
	public preferredCountries: CountryISO[] = [CountryISO.Spain, CountryISO.Ecuador, CountryISO.Chile, CountryISO.Argentina, CountryISO.Mexico, CountryISO.UnitedStates, CountryISO.Peru];
	public phoneForm = new FormGroup({
		userPhone: new FormControl(undefined, [Validators.required])
	})
  private typeUser: string
  private currentUser: UserModel
  private test: boolean = false

 

  constructor(
    private _seerManager: SeerManager,
    private _seerSchedulePrivateSessionManager: SeerSchedulePrivateSessionManager,
    private _privateSessionManager: PrivateSessionManager,
    private _emailManager: EmailManager,
    private _messagingManager: MessagingManager,
    private _utils: Utils,
    private _loadingService: LoadingService,
    private _cookieService: CookieService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    if (!this._utils.IsJsonString(this._cookieService.get('User')) || !this._utils.IsJsonString(this._cookieService.get('Type'))) {

      Swal.fire({
        icon: 'error',
        title: 'Para reservar una consulta privada, debes estar identificado',
        showConfirmButton: false,
        timer: 4000
      })

      this._router.navigate(['/login'])

      return
    }

    this.typeUser = JSON.parse(this._cookieService.get("Type"))
    this.currentUser = JSON.parse(this._cookieService.get('User')) as UserModel

    this.storageAccountImagesPath = this._utils.getAzureStorageAccountPathForImages()
    this.getAllSeers()
    this.getHoursOfDayInSlot()

    L10n.load({
      'es': {
        'calendar': {
          today: "Hoy"
        }
      }
    })
  }

  selectSeerForPrivateSession(seer) {
    this.selectedSeer = seer
    this.getPrivateSessionsBySeerId(this.selectedSeer.id)
  }

  selectHourForPrivateSession(hour, index) {
    this.selectedHour = []

    if (this.privateSessionData.bookDuration <= 30) {
      if (this.getIfSelectedHourIsAvailable(hour)) {
        this.selectedHour.push(hour)
      }
    }

    if (this.privateSessionData.bookDuration > 30) {
      if (this.getIfSelectedHourIsAvailable(hour) && this.getIfSelectedHourIsAvailable(this.allTimesToBookSession[index + 1])) {
        this.selectedHour.push(hour)
        this.selectedHour.push(this.allTimesToBookSession[index + 1])
      }
    }
  }

  makeCalendarActions(args): void {
    /*if (args.date.getDay() === 0 || args.date.getDay() === 6) {
      args.isDisabled = true
      args.element.classList.add('e-highlightweekend');
    }*/
  }

  getDayIndex(args): void {

  }

  goToPreviousStep() {
    this.stepSelected--

    if (this.stepSelected == 0) {
      this.selectedSeer = null
    }
  }

  goToNextStep() {

    if (this.stepSelected == 0) {
      if (this.selectedSeer == null) {
        Swal.fire({
          icon: 'error',
          title: 'Para pasar al siguiente paso, debes seleccionar el maestro con el que quieras reservar la cita privada',
          showConfirmButton: false,
          timer: 4000
        })

        return
      }

      this.privateSessionData.seerId = this.selectedSeer.id
      this.privateSessionData.seerName = this.selectedSeer.name
      this.privateSessionData.seerEmail = this.selectedSeer.email
    }

    else if (this.stepSelected == 1) {
      if (this.bookDurationAndCost == null) {
        Swal.fire({
          icon: 'error',
          title: 'Para pasar al siguiente paso, debes seleccionar la duración de la cita privada',
          showConfirmButton: false,
          timer: 4000
        })

        return
      }

      this.privateSessionData.bookDuration = this.bookDurationAndCost.duration
      this.privateSessionData.allowTraduction = this.toggleAllowTraductionPrivateSession
      this.privateSessionData.bookCost = this.bookDurationAndCost.cost

      if(this.privateSessionData.allowTraduction){
         this.privateSessionData.bookCost += 20
      }

    }

    else if (this.stepSelected == 2) {
      let formatedSelectedDate = this.calendarObj.globalize.formatDate(this.calendarObj.value, {
        format: 'dd/MM/yyyy',
        type: 'date',
        skeleton: 'dMy',
      })

      this.privateSessionData.bookDate = formatedSelectedDate

      if (!this.getHoursAvailableForDay(this.calendarObj.value)) {
        return
      }

      this.getPrivateSessionHoursScheduledByDate(formatedSelectedDate.toString())

      this.selectedHour = []
    }

    else if (this.stepSelected == 3) {

      if (this.selectedHour == null) {
        Swal.fire({
          icon: 'error',
          title: 'Para pasar al siguiente paso, debes seleccionar la hora en la que quieras reservar la cita privada',
          showConfirmButton: false,
          timer: 4000
        })

        return
      }

      this.privateSessionData.bookHour = this.selectedHour.toString()

    }

    else if (this.stepSelected == 4) {

      if (this.phoneForm.controls["userPhone"].value == null) {
        Swal.fire({
          icon: 'error',
          title: 'Para pasar al siguiente paso, debes introducir tu número de teléfono',
          showConfirmButton: false,
          timer: 4000
        })

        return
      }

      if(this.phoneForm.controls['userPhone'].invalid){
        Swal.fire({
          icon: 'error',
          title: 'El formato de número de teléfono es incorrecto. Por favor, introducelo de nuevo correctamente',
          showConfirmButton: false,
          timer: 4000
        })

        return
      }
      
      //this.sendPhoneVerificationSMS(this.phoneForm.controls["userPhone"].value["e164Number"])

      let phone = []
      phone.push({
				number: this.phoneForm.controls["userPhone"].value["number"],
				internationalNumber: this.phoneForm.controls["userPhone"].value["internationalNumber"],
				nationalNumber: this.phoneForm.controls["userPhone"].value["nationalNumber"],
        e164Number: this.phoneForm.controls["userPhone"].value["e164Number"],
        countryCode: this.phoneForm.controls["userPhone"].value["countryCode"],
        dialCode: this.phoneForm.controls["userPhone"].value["dialCode"]
			})

      this.privateSessionData.phone = phone
    }

    console.log("Value: ", this.privateSessionData)

    this.stepSelected++
    
    if (this.stepSelected == 5) {
      this.initPayPal()
    }


  }

  private initPayPal() {

    let amount = this.bookDurationAndCost.cost.toString()

    if (this.test && environment.APIEndPointBackend.includes("localhost")) {
      this._loadingService.show()

      let dataTransaction = {
        "id": "94268485C9386952N",
        "intent": "CAPTURE",
        "status": "COMPLETED",
        "purchase_units": [
          {
            "amount": {
              "currency_code": "EUR",
              "value": "10.00",
              "breakdown": {
                "item_total": {
                  "currency_code": "EUR",
                  "value": "10.00"
                },
                "shipping": {
                  "currency_code": "EUR",
                  "value": "0.00"
                },
                "handling": {
                  "currency_code": "EUR",
                  "value": "0.00"
                },
                "insurance": {
                  "currency_code": "EUR",
                  "value": "0.00"
                },
                "shipping_discount": {
                  "currency_code": "EUR",
                  "value": "0.00"
                }
              }
            },
            "payee": {
              "email_address": "contacto@totonline.es",
              "merchant_id": "X83ULAWA4ME3N"
            },
            "description": "Sala privada TOT Online 20 minutos",
            "soft_descriptor": "PAYPAL *TOTONLINE",
            "items": [
              {
                "name": "Sala privada TOT Online 20 minutos",
                "unit_amount": {
                  "currency_code": "EUR",
                  "value": "10.00"
                },
                "tax": {
                  "currency_code": "EUR",
                  "value": "0.00"
                },
                "quantity": "1"
              }
            ],
            "shipping": {
              "name": {
                "full_name": "David Linares"
              },
              "address": {
                "address_line_1": "Calle Santiago, Casa baja",
                "admin_area_2": "Coslada",
                "admin_area_1": "MADRID",
                "postal_code": "28231",
                "country_code": "ES"
              }
            },
            "payments": {
              "captures": [
                {
                  "id": "87V80567K8631591U",
                  "status": "COMPLETED",
                  "amount": {
                    "currency_code": "EUR",
                    "value": "10.00"
                  },
                  "final_capture": true,
                  "seller_protection": {
                    "status": "NOT_ELIGIBLE"
                  },
                  "create_time": "2021-11-18T13:08:09Z",
                  "update_time": "2021-11-18T13:08:09Z"
                }
              ]
            }
          }
        ],
        "payer": {
          "name": {
            "given_name": "David",
            "surname": "Linares"
          },
          "email_address": "gonzalo.lasarte@unus.digital",
          "payer_id": "R2SCLFRZME6NL",
          "address": {
            "country_code": "ES"
          }
        },
        "create_time": "2021-11-18T13:04:54Z",
        "update_time": "2021-11-18T13:08:09Z",
        "links": [
          {
            "href": "https://api.paypal.com/v2/checkout/orders/94268485C9386952N",
            "rel": "self",
            "method": "GET"
          }
        ]
      }
      this.createprivateSession(dataTransaction)

      return
    }

    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.PayPalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: amount,
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: amount
                }
              }
            },
            items: [
              {
                name: 'Reserva sala privada TOT Online '.concat(amount).concat("€"),
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'EUR',
                  value: amount,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (dataTransaction) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', dataTransaction)

        this._loadingService.show()

        this.createprivateSession(dataTransaction)
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions)

        Swal.fire({
          icon: 'warning',
          title: 'Operación cancelada.',
          showConfirmButton: false,
          timer: 4000
        })
      },
      onError: err => {
        console.log('OnError', err)

        Swal.fire({
          icon: 'error',
          title: 'Ocurrió algún error al procesar el pago. Inténtelo de nuevo más tarde.',
          showConfirmButton: false,
          timer: 4000
        })
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    }
  }

  private getAllSeers() {
    this._seerManager.getAllSeers().then(result => {
      if (result) {
        let allSeers: SeerModel[] = result as SeerModel[]
        allSeers.forEach((seer: SeerModel) =>{
          if(seer.allowPrivateSession){
            this.seers.push(seer)
          }
        })
      }
    })
  }

  private getHoursOfDayInSlot() {
    let x = {
      slotInterval: 30,
      openTime: '00:00',
      closeTime: '00:00'
    };

    let startTime = moment(x.openTime, "HH:mm")

    let endTime = moment(x.closeTime, "HH:mm").add(1, 'days')

    while (startTime < endTime) {
      this.allTimesToBookSession.push(startTime.format("HH:mm"))
      startTime.add(x.slotInterval, 'minutes')
    }
  }

  private getPrivateSessionsBySeerId(seerId) {
    this._seerSchedulePrivateSessionManager.getSeerSchedulePrivateSessionBySeerId(seerId).then(result => {
      if (result) {
        this.seerSchedulePrivateSession = result as SeerSchedulePrivateSessionModel
        this.seerSchedulePrivateSessionSchedule = this.seerSchedulePrivateSession.schedule
      }
    })
  }

  private disabledDaysIfNotAvailable(calendar) {
    console.log("calendar: ", calendar)
    if (calendar.date.getDay() === 0 || calendar.date.getDay() === 6) {
      calendar.isDisabled = true
      calendar.element.classList.add('e-highlightweekend');
    }
  }

  private getHoursAvailableForDay(dateSelected): boolean {
    this.scheduleByDay = this.seerSchedulePrivateSessionSchedule.find(x => x.dayIndex === dateSelected.getDay())
    if (this.scheduleByDay == null || this.scheduleByDay == undefined) {
      Swal.fire({
        icon: 'error',
        title: 'No hay horas disponibles para el día seleccionado.',
        showConfirmButton: false,
        timer: 4000
      })

      return false
    }
    return true
  }

  private getPrivateSessionHoursScheduledByDate(dateSelected) {
    this._loadingService.show()

    this.privateSessionHoursBussy = []

    this._privateSessionManager.getPrivateSessionHoursScheduledByDate(encodeURIComponent(dateSelected)).then((privateSession: PrivateSessionModel[]) => {
      if (privateSession) {
        privateSession.forEach((privateSessionItem: PrivateSessionModel) => {
          if (privateSessionItem.bookHour.split(",").length > 1) {
            this.privateSessionHoursBussy.push(privateSessionItem.bookHour.split(",")[0])
            this.privateSessionHoursBussy.push(privateSessionItem.bookHour.split(",")[1])
          }
          else {
            this.privateSessionHoursBussy.push(privateSessionItem.bookHour)
          }
        })

        console.log("valor: ", this.privateSessionHoursBussy)

        this._loadingService.hide()
      }
    })
  }

  private createprivateSession(dataTransaction) {
    this.privateSessionData.idUser = this.currentUser.id
    this.privateSessionData.email = this.currentUser.email
    this.privateSessionData.nickname = this.currentUser.nickname
    this.privateSessionData.dataTransaction = dataTransaction

    this._privateSessionManager.createPrivateSession(this.privateSessionData).then(result => {
      if (result) {
        this.goToNextStep()
        
        let dataToSendPrivateSessionBookedEmail = {
          email: this.privateSessionData.email,
          name: this.currentUser.name,
          nickname: this.currentUser.nickname,
          bookDate: this.privateSessionData.bookDate,
          bookHour: this.privateSessionData.bookHour,
          bookDuration: this.privateSessionData.bookDuration,
          seerName: this.privateSessionData.seerName,
          seerEmail: this.privateSessionData.seerEmail,
          phone: this.privateSessionData.phone[0].internationalNumber,
        }

        this._emailManager.sendPrivateSessionBookedEmail(dataToSendPrivateSessionBookedEmail).then(resultSendPrivateSessionBookedEmail =>{
          if(resultSendPrivateSessionBookedEmail){
            this.privateSessionData = new PrivateSessionModel()
            this._loadingService.hide()
          }
        })
      }
    })
  }

  private getIfSelectedHourIsAvailable(hour): boolean {
    if (this.privateSessionHoursBussy.indexOf(hour) !== -1 || this.scheduleByDay.hours.indexOf(hour) === -1) {
      Swal.fire({
        icon: 'error',
        title: 'La hora que estás seleccionando, no está disponible. Por favor, selecciona otra hora.',
        showConfirmButton: false,
        timer: 4000
      })

      return false
    }

    return true
  }

  private sendPhoneVerificationSMS(number){
    let dataToSendPhoneVerificationSMS = {
      idUser: this.currentUser.id,
      number: number
    }

    this._messagingManager.sendPhoneVerificationSMS(dataToSendPhoneVerificationSMS).then(result=>{

    })
  }
}
