import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { Utils } from '../../../utilities/utils'

import { Router } from "@angular/router";

import { SocialAuthService } from 'angularx-social-login';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  static typeUser: String
  static currentUser
  static userIsLogged: boolean = false

  constructor(
    private _cookieService: CookieService,
    private _utils: Utils,
    private _socialAuthService: SocialAuthService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
   if(this._utils.IsJsonString(this._cookieService.get('User'))){
      
      NavbarComponent.typeUser = this._cookieService.get("Type")

      NavbarComponent.currentUser = JSON.parse(this._cookieService.get('User'));
      
      NavbarComponent.userIsLogged = true
    }
  }

  get retrieveTypeUser() {
    return NavbarComponent.typeUser;
  }

  get retrieveCurrentUser() {
    return NavbarComponent.currentUser;
  }

  get retrieveIfUserIsLogged() {
    return NavbarComponent.userIsLogged;
  }

  logOut(): void {
		this._cookieService.delete('User', '/', environment.SiteDomainForPersistCookies, false, "Lax")

		this._socialAuthService.signOut();

		NavbarComponent.typeUser = null
		NavbarComponent.currentUser = null
		NavbarComponent.userIsLogged = false

		this._utils.redirectTo(this._router, "home")
	}

}
