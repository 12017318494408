import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { PrivateSessionBackendService } from '../services/private-session-backend.service'

@Injectable()

export class PrivateSessionManager {

    private privateSessionAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _privateSessionBackendService: PrivateSessionBackendService
    ) { }

    ngOnDestroy() {
        if (this.privateSessionAPI != null) {
            this.privateSessionAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    createPrivateSession(privateSessionData) {
        return new Promise((resolve) => {
            this.privateSessionAPI = this._privateSessionBackendService.createPrivateSession(privateSessionData)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Private session created success")

                        Swal.fire({
                            icon: 'success',
                            title: "Sala Privada creada correctamente",
                            showConfirmButton: false,
                            timer: 4000
                        })

                        resolve(true)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }


    getPrivateSessionHoursScheduledByDate(date) {
        return new Promise((resolve) => {
            this.privateSessionAPI = this._privateSessionBackendService.getPrivateSessionHoursScheduledByDate(date)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Private session by date retrieved success")

                        resolve(response.private_session_data)
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }


}