import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { EmailBackendService } from '../services/email-backend.service'

@Injectable()

export class EmailManager {

    private emailAPI: Subscription
    private destroy$: Subject<boolean> = new Subject<boolean>()

    constructor(
        private _emailBackendService: EmailBackendService
    ) { }

    ngOnDestroy() {
        if (this.emailAPI != null) {
            this.emailAPI.unsubscribe()
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    sendEmailToRecoverPassword(email) {
        return new Promise((resolve) => {
            this.emailAPI = this._emailBackendService.sendEmailToRecoverPassword(email)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Email sended correctly")

                        resolve(response.user_data)
                    }
                    else if (response.code == 201) {
                        console.log("The user email not exists")

                        Swal.fire({
                            icon: 'error',
                            title: "El email no corresponde a ningún usuario de la plataforma.",
                            showConfirmButton: false,
                            timer: 4000
                        })
                    }
                    else if(response.code == 202){
                        Swal.fire({
                            icon: 'error',
                            title: "Un usuario social no puede cambiar su contraseña desde este punto. Se debe modificar en Google o Facebook.",
                            showConfirmButton: false,
                            timer: 4000
                        })
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    sendContactEmail(dataToSendEmail) {
        return new Promise((resolve) => {
            this.emailAPI = this._emailBackendService.sendContactEmail(dataToSendEmail)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Email sended correctly")

                        Swal.fire({
                            icon: 'success',
                            title: "Email enviado correctamente. Gracias por contactarnos.",
                            showConfirmButton: false,
                            timer: 4000
                        })
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    sendPrivateSessionBookedEmail(dataToSendPrivateSessionBookedEmail) {
        return new Promise((resolve) => {
            this.emailAPI = this._emailBackendService.sendPrivateSessionBookedEmail(dataToSendPrivateSessionBookedEmail)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: any) => {
                    if (response.code == 200) {
                        console.log("Email sended correctly")
                    }
                },
                    error => {
                        console.log("Response not success: ", <any>error)
                        resolve(false)
                    })
        })
    }

    
}